export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Πίνακας Ελέγχου',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'Αποστολές',
          root: true,
          icon: 'flaticon2-paper-plane',
          page: '/shipment-management',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'Οικονoμικά Αποστολών',
          root: true,
          icon: 'fas fa-euro-sign',
          page: '/shipment-management/shipments/financial',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'Εξωτερικές Αποστολές',
          root: true,
          icon: 'flaticon-paper-plane-1',
          page: '/shipment-management/external_shipments/',
          //translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        {
          title: 'Αρχεία',
          root: true,
          icon: 'flaticon2-folder',
          page: '/ui/files',
          bullet: 'dot',
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
