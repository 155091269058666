<mat-card class="pcard">
  <h1 class="tt mbs">Ρυθμίσεις Χρεώσεων</h1>
  <div class="row">
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          [textMask]="{ mask: curr_mask }"
          placeholder="Χρέωση Επιστροφής ( &euro; 00.00 )"
          class="right-align"
          [(ngModel)]="chargeSettingsSubject.value.return_ammount"
          name="return_ammount"
        />
        <mat-hint align="start"
          >Παρακαλώ ειστάγετε το
          <strong>ποσό</strong>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
      <mat-slide-toggle [(ngModel)]="chargeSettingsSubject.value.allow_prereturns"
        >Επιτέπεται η προ-απόδοση αντικαταβολών</mat-slide-toggle
      >
    </div>
  </div>
  <div *ngIf="chargeSettingsSubject.value.allow_prereturns" class="row mt-4">
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          [textMask]="{ mask: curr_mask }"
          placeholder="Χρέωση Προ-Απόδοσης Αντικαταβολών ( &euro; 00.00 )"
          class="right-align"
          [(ngModel)]="chargeSettingsSubject.value.prereturn_charge"
          name="prereturn_charge"
        />
        <mat-hint align="start"
          >Παρακαλώ ειστάγετε το
          <strong>ποσό</strong>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          [textMask]="{ mask: curr_mask }"
          placeholder="Νέγιστο ποσό Προ-Απόδοσης ( &euro; 00.00 )"
          class="right-align"
          [(ngModel)]="chargeSettingsSubject.value.prereturn_max"
          name="prereturn_max"
        />
        <mat-hint align="start"
          >Παρακαλώ ειστάγετε το
          <strong>ποσό</strong>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          [textMask]="{ mask: per_mask }"
          placeholder="Ποσοστό Προ-Απόδοσης ( 00.00% )"
          class="right-align"
          [(ngModel)]="chargeSettingsSubject.value.prereturn_perc"
          name="prereturn_perc"
        />
        <mat-hint align="start"
          >Παρακαλώ ειστάγετε το
          <strong>ποσοστό</strong>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</mat-card>
<mat-card class="pcard">
  <h1 class="tt">Προσθήκη Χρεώσεων</h1>
  <kt-alert
    *ngIf="serverError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <div class="incon">
    <form [formGroup]="chargeForm" class="kt-form kt-form--group-seperator-dashed">
      <div class="kt-form__section kt-form__section--first">
        <div *ngIf="new_charges.length === 0" class="form-group kt-form__group row">
          <p>
            *Αυτές είναι οι χρεώσεις που θα ισχύουν για κάθε αποστολή που &nbsp;
            <strong>δεν</strong> &nbsp; θα ανήκει στις ζώνες που θα ορίσετε στην συνέχεια
          </p>
        </div>
        <div *ngIf="new_charges.length > 0" class="form-group kt-form__group row">
          <div class="col-lg-2 kt-margin-bottom-20-mobile plr tar">Από {{ min }} Kg</div>
          <div class="col-lg-8 kt-margin-bottom-20-mobile plr">
            <mat-slider
              [min]="min"
              [max]="max"
              [step]="1"
              formControlName="weight_end"
              [thumbLabel]="true"
              class="wslider"
            >
            </mat-slider>
          </div>
          <div class="col-lg-2 kt-margin-bottom-20-mobile plr">
            εώς {{ chargeForm.get("weight_end").value }} Kg
          </div>
        </div>
        <div class="form-group kt-form__group row">
          <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                [textMask]="{ mask: curr_mask }"
                placeholder="Στατική Χρέωση ( &euro; 00.00 )"
                class="right-align"
                formControlName="static_charge"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε το
                <strong>ποσό</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                [textMask]="{ mask: curr_mask }"
                placeholder="Χρέωση ανά kg ( &euro; 00.00 )"
                class="right-align"
                formControlName="per_kg_charge"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε το
                <strong>ποσό</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                [textMask]="{ mask: curr_mask }"
                placeholder="Χρέωση Αντικαταβολής ( &euro; 00.00 )"
                class="right-align"
                formControlName="cod_charge"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε το
                <strong>ποσό</strong>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group kt-form__group row">
          <div class="col kt-margin-bottom-20-mobile plr text-right">
            <a
              href="javascript:;"
              color="warn"
              class="btn kt-margin-r-10"
              (click)="resetCharges()"
              mat-raised-button
              matTooltip="Καθαρισμός Χρεώσεων"
            >
              <i class="fas fa-sync"></i>
              <span>Απαλοιφή</span>
            </a>
            <a
              href="javascript:;"
              color="primary"
              class="btn kt-margin-r-10"
              (click)="addCharge()"
              mat-raised-button
              matTooltip="Προσθήκη Ζώνης Χρέωσης"
            >
              <i class="fas fa-plus"></i>
              <span>Προσθήκη</span>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="new_charges.length > 0">
    <kt-price-range [charges]="new_charges"></kt-price-range>
  </div>
  <div *ngIf="new_charges.length > 0" class="row">
    <div class="col kt-margin-bottom-20-mobile plr text-right">
      <a
        href="javascript:;"
        color="primary"
        class="btn kt-margin-r-10"
        (click)="saveCharges()"
        mat-raised-button
        matTooltip="Αποθήκευση Ζωνών Χρέωσης"
      >
        <i class="fas fa-save"></i>
        <span>Αποθήκευση</span>
      </a>
    </div>
  </div>
</mat-card>
<div class="container">
  <div class="page-header">
    <h1 id="timeline">Ιστορικό</h1>
  </div>
  <ul class="timeline">
    <li *ngFor="let ch of charges$ | async | keyvalue: nullSort; first as isFirst">
      <div *ngIf="isFirst" class="timeline-badge success"><i class="fa fas fa-check"></i></div>
      <div *ngIf="!isFirst" class="timeline-badge warning">
        <i class="fa fas fa-file-invoice-dollar"></i>
      </div>
      <div class="timeline-panel">
        <div class="timeline-heading">
          <span *ngIf="isFirst" class="timeline-title">Τρέχουσες Χρεώσεις</span>
          <span *ngIf="!isFirst" class="timeline-title">Ιστορικό χρεώσεων</span>
          <span class="timeline-date text-muted"
            >&nbsp;&nbsp;<i class="far fa-clock"></i> &nbsp;{{
              ch.key | date: "dd MMM yyyy hh:mm"
            }}</span
          >
        </div>
        <div class="timeline-body">
          <kt-price-range [charges]="ch.value"></kt-price-range>
        </div>
      </div>
    </li>
  </ul>
</div>
