<kt-portlet>
  <kt-portlet-header [title]="'Γενικές Ρυθμίσεις'" [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTools>
      <a
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="saveSubject.next()"
        mat-raised-button
        matTooltip="Αποθήκευση"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση</span>
      </a>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-info-circle"></i>
          Γενικές Πληροφορίες
        </ng-template>
        <ng-template matTabContent>
          <kt-info [saveEvent]="saveSubject.asObservable()"></kt-info>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-balance-scale-right"></i>
          Νομικά
        </ng-template>
        <ng-template matTabContent>
          <kt-legal [saveEvent]="saveSubject.asObservable()"></kt-legal>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </kt-portlet-body>
</kt-portlet>
