// Angular
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
// RxJS
import { BehaviorSubject, fromEvent } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
// Auth
import { Address, AuthService } from '../../../../../../core/auth';
// State
import { AppState } from '../../../../../../core/reducers';
// Layout
import { LayoutUtilsService } from '../../../../../../core/_base/crud';

@Component({
  selector: 'kt-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyComponent implements OnInit {
  @Input() companySubject: BehaviorSubject<any>;
  hasFormErrors = false;
  companyForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    if (!this.companySubject.value) {
      this.companySubject.next({
        companyName: '',
        companyVat: '',
        companyFiscalService: '',
        companyAddress: '',
        companyCity: '',
        companyPostCode: '',
      });
    }

    this.createForm();
    this.companyForm.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => this.updateCompany()),
      )
      .subscribe();
  }

  createForm() {
    this.companyForm = this.fb.group({
      companyName: [this.companySubject.value.companyName],
      companyVat: [this.companySubject.value.companyVat],
      companyFiscalService: [this.companySubject.value.companyFiscalService],
      companyAddress: [this.companySubject.value.companyAddress],
      companyCity: [this.companySubject.value.companyCity],
      companyPostCode: [this.companySubject.value.companyPostCode],
    });
  }

  updateCompany() {
    this.hasFormErrors = false;
    const controls = this.companyForm.controls;
    /** check form */
    if (this.companyForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      this.hasFormErrors = true;

      return;
    }

    this.companySubject.next({
      companyName: controls.companyName.value,
      companyVat: controls.companyVat.value,
      companyFiscalService: controls.companyFiscalService.value,
      companyAddress: controls.companyAddress.value,
      companyCity: controls.companyCity.value,
      companyPostCode: controls.companyPostCode.value,
    });
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
