// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ShipmentsState } from '../_reducers/shipment.reducers';
import { each } from 'lodash';
import { Shipment } from '../_models/shipment.model';

export const selectShipmentsState =
  createFeatureSelector<ShipmentsState>('shipments');

export const selectShipmentById = (shipmentId: number) =>
  createSelector(
    selectShipmentsState,
    (shipmentsState) => shipmentsState.entities[shipmentId],
  );

export const selectShipmentsPageLoading = createSelector(
  selectShipmentsState,
  (shipmentsState) => {
    return shipmentsState.listLoading;
  },
);

export const selectShipmentsActionLoading = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.actionsloading,
);

export const selectLastCreatedShipmentId = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.lastCreatedShipmentId,
);

export const selectShipmentsPageLastQuery = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.lastQuery,
);

export const selectListFilters = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.listFilters,
);

export const selectUserFilters = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.userFilters,
);

export const selectFinancialFilters = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.financialFilters,
);

export const selectUserFinancialFilters = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.userFinancialFilters,
);

export const selectShipmentsInStore = createSelector(
  selectShipmentsState,
  (shipmentsState) => {
    const items: Shipment[] = [];
    /*each(shipmentsState.entities, element => {
            items.push(element);
        });*/
    each(shipmentsState.ids, (id: number) => {
      items.push(shipmentsState.entities[id]);
    });
    /*each(shipmentsState.ids, element => {
            items.push(shipmentsState.entities[element]);
        });*/
    const httpExtension = new HttpExtenstionsModel();
    const result: Shipment[] = httpExtension.sortArray(
      items,
      shipmentsState.lastQuery.sortField,
      shipmentsState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, shipmentsState.totalCount, '');
  },
);

export const selectShipmentsShowInitWaitingMessage = createSelector(
  selectShipmentsState,
  (shipmentsState) => shipmentsState.showInitWaitingMessage,
);

export const selectHasShipmentsInStore = createSelector(
  selectShipmentsState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  },
);
