// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AreaGroupState } from '../_reducers/area_group.reducers';
import { each } from 'lodash';
import { AreaGroup } from '../_models/area_group.model';

export const selectAreaGroupState =
  createFeatureSelector<AreaGroupState>('areaGroups');

export const selectAreaGroupById = (id: number) =>
  createSelector(
    selectAreaGroupState,
    (areaGroupState) => areaGroupState.entities[id],
  );

export const selectAreaGroupPageLoading = createSelector(
  selectAreaGroupState,
  (areaGroupState) => {
    return areaGroupState.listLoading;
  },
);

export const selectAreaGroupActionLoading = createSelector(
  selectAreaGroupState,
  (areaGroupState) => areaGroupState.actionsloading,
);

export const selectAreaGroupPageLastQuery = createSelector(
  selectAreaGroupState,
  (areaGroupState) => areaGroupState.lastQuery,
);

export const selectLastCreatedAreaGroupId = createSelector(
  selectAreaGroupState,
  (areaGroupState) => areaGroupState.lastCreatedAreaGroupId,
);

export const selectAreaGroupsInStore = createSelector(
  selectAreaGroupState,
  (areaGroupState) => {
    const items: AreaGroup[] = [];
    each(areaGroupState.ids, (id: number) => {
      items.push(areaGroupState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: AreaGroup[] = httpExtension.sortArray(
      items,
      areaGroupState.lastQuery.sortField,
      areaGroupState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, areaGroupState.totalCount, '');
  },
);

export const selectAreaGroupShowInitWaitingMessage = createSelector(
  selectAreaGroupState,
  (areaGroupState) => areaGroupState.showInitWaitingMessage,
);

export const selectHasAreaGroupsInStore = createSelector(
  selectAreaGroupState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
