// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  GConfigActions,
  GConfigActionTypes,
} from '../_actions/gconfig.actions';
// Models
import { GConfig } from '../_models/gconfig.model';

export type GConfigState = EntityState<GConfig>;

export const adapter: EntityAdapter<GConfig> = createEntityAdapter<GConfig>({
  selectId: (gconfig) => gconfig.identifier,
});

export const initialGConfigState: GConfigState = adapter.getInitialState({});

export function gconfigReducer(
  state = initialGConfigState,
  action: GConfigActions,
): GConfigState {
  switch (action.type) {
    case GConfigActionTypes.GConfigUpsert:
      return adapter.upsertOne(action.payload.gconfig, state);
    case GConfigActionTypes.GConfigUpsertMany:
      return adapter.upsertMany(action.payload.gconfig, state);
    case GConfigActionTypes.GConfigLoaded: {
      return adapter.setAll(action.payload.gconfig, {
        ...initialGConfigState,
      });
    }
    default:
      return state;
  }
}

export const getGConfigState = createFeatureSelector<GConfigState>('gconfig');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
