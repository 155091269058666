// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
// Layout
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
import {
  AuthService,
  currentUser,
  selectUserById,
  selectUsersInStore,
  User,
} from '../../../../../core/auth';
import { orderBy, sortBy } from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Services and Models
import {
  ActionFail,
  ActionSuccess,
  Area,
  BlacklistActionTypes,
  BlacklistCheck,
  CheckResult,
  ExtraCharge,
  ReturnCreate,
  selectLastCreatedShipmentId,
  selectShipmentById,
  selectShipmentsActionLoading,
  Shipment,
  ShipmentActionTypes,
  ShipmentCreate,
  ShipmentsActionToggleLoading,
  ShipmentUpdate,
} from '../../../../../core/ronex';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function weightValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    //const value = control.value.replace(' Kg','').replace(',','');
    const k = '' + control.value;
    const value = parseFloat(k.replace(' Kg', '').replace(',', ''));

    if (!control.value) {
      return null;
    }

    return value > 0 ? null : { weightOK: false };
  };
}

@Component({
  selector: 'kt-shipments-edit',
  templateUrl: './shipments-edit.component.html',
  styleUrls: ['./shipments-edit.component.scss'],
})
export class ShipmentsEditComponent implements OnInit, OnDestroy {
  // Public properties
  shipment: Shipment;
  shipmentId$: Observable<number>;
  oldShipment: Shipment;
  selectedTab = 0;
  loading$: Observable<boolean>;
  shipmentForm: UntypedFormGroup;
  hasFormErrors = false;
  serverError: Subject<any>;
  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });
  kg_mask = createNumberMask({
    suffix: ' Kg',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });
  cm_mask = createNumberMask({
    suffix: ' cm',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });
  adminView = false;
  filteredOptions: Observable<User[]>;
  shipmentUser: User;

  retr = false;

  activeCourier: string;
  areaOptions: Array<Area> = [];

  extraChargesSubject: BehaviorSubject<Array<ExtraCharge>> =
    new BehaviorSubject<ExtraCharge[]>([]);
  adding = false;

  blacklisted: Observable<boolean>;
  area_messages: Array<string> = [];
  ignore_groups = false;
  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param userFB: FormBuilder
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store<AppState>
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shipmentFB: UntypedFormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService,
    private actions$: Actions,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('return')) {
      this.retr = true;
    } else {
      this.retr = false;
    }

    this.loading$ = this.store.pipe(select(selectShipmentsActionLoading));
    this.serverError = new Subject();

    if (this.router.url.startsWith('/shipment-management/admin')) {
      this.adminView = true;
    }

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        const gss = this.store
          .pipe(select(selectShipmentById(id)))
          .subscribe((res) => {
            if (res) {
              this.shipment = res;
              this.oldShipment = Object.assign({}, this.shipment);
              this.extraChargesSubject.next(this.shipment.extra_charges);
              this.initShipment();
              this.adding = false;
            }
          });
        this.subscriptions.push(gss);
      } else {
        this.shipment = new Shipment();
        this.shipment.clear();
        this.oldShipment = Object.assign({}, this.shipment);
        this.initShipment();
        if (!this.retr) {
          this.shipmentForm.get('is_cod').setValue(true);
          this.shipmentForm.get('voucher_a6').setValue(true);
        }
        this.adding = true;
      }
      if (this.adding) {
        if (!this.adminView) {
          this.store
            .pipe(
              select(currentUser),
              takeWhile((res) => !res, true),
              tap((res) => {
                if (res) {
                  this.activeCourier = res.courier;
                  this.ignore_groups = res.ignore_area_groups;
                }
              }),
            )
            .subscribe();
        }
      } else {
        if (!this.adminView) {
          this.store
            .pipe(
              select(currentUser),
              takeWhile((res) => !res, true),
              tap((res) => {
                if (res) {
                  this.activeCourier = res.courier;
                  this.ignore_groups = res.ignore_area_groups;
                  this.updateAreaOptions(true);
                }
              }),
            )
            .subscribe();
        } else {
          this.store
            .pipe(
              select(selectUserById(this.shipment.user)),
              takeWhile((res) => !res, true),
              tap((res) => {
                if (res) {
                  this.activeCourier = res.courier;
                  this.ignore_groups = res.ignore_area_groups;
                  this.updateAreaOptions(true);
                }
              }),
            )
            .subscribe();
        }
      }
      /*
            if(!this.adminView) {
                let gus = this.store.pipe(
                    select(currentUser),
                    tap( res => this.activeCourier = res.courier )
                ).subscribe();

                this.subscriptions.push(gus);
            }
            console.log(this.shipment);
            */
    });
    this.subscriptions.push(routeSubscription);

    const ss = this.actions$
      .pipe(
        ofType<ActionSuccess>(ShipmentActionTypes.ActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<ActionFail>(ShipmentActionTypes.ActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    this.blacklisted = this.actions$.pipe(
      ofType<CheckResult>(BlacklistActionTypes.CheckResult),
      map(({ payload }) => payload.result),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  userChange(usr: User) {
    if (this.activeCourier) {
      if (
        usr.courier !== this.activeCourier ||
        usr.courier !==
          this.shipmentForm.controls.recipient_selected_area.value.courier ||
        this.ignore_groups !== usr.ignore_area_groups
      ) {
        this.ignore_groups = usr.ignore_area_groups;
        this.activeCourier = usr.courier;
        this.shipmentForm.get('recipient_selected_area').setValue(undefined);
        this.shipmentForm.get('recipient_area').setValue(undefined);
        this.updateAreaOptions();
      }
    } else {
      this.activeCourier = usr.courier;
      this.ignore_groups = usr.ignore_area_groups;
      this.updateAreaOptions();
    }
  }

  tkChanged() {
    this.shipmentForm.get('recipient_selected_area').setValue(undefined);
    this.updateAreaOptions();
  }

  arSelChanged() {
    const sar: Area = this.shipmentForm.controls.recipient_selected_area.value;

    this.shipmentForm.get('recipient_area').setValue(undefined);
    if (sar.courier === this.activeCourier) {
      this.areaOptions = this.areaOptions.filter(
        (x) => x.courier === this.activeCourier,
      );
      this.cdr.detectChanges();
    } else {
      this.updateAreaOptions(true);
    }

    //check for actions in selected area
    let messages: Array<any> = [];
    let nr_messages: Array<any> = [];
    let reroutes: Array<any> = [];
    let found_reroute = false;
    sar.area_groups.forEach((group) => {
      let has_reroute = false;
      group.group_actions.forEach((a) => {
        if (a.action_type === 'REROUTE') {
          has_reroute = true;
          found_reroute = true;
        }
      });
      nr_messages = [
        ...nr_messages,
        ...group.group_actions
          .filter((a) => a.action_type === 'MESSAGE' && !has_reroute)
          .map((a) => a.message),
      ];
      messages = [
        ...messages,
        ...group.group_actions
          .filter((a) => a.action_type === 'MESSAGE' && has_reroute)
          .map((a) => a.message),
      ];
      reroutes = [
        ...reroutes,
        ...group.group_actions
          .filter((a) => a.action_type === 'REROUTE')
          .map((a) => a.enforce_courier),
      ];
    });

    if (reroutes.length > 1 && !this.ignore_groups) {
      this.serverError.next(
        'Υπάρχουν περισσότερες απο μια αναδρομολογήσεις για την συγκεκριμένη περιοχή! Παρακαλώ επικοινωνήστε με τον διαχειριστή για την επίλυση του προβλήματος.',
      );
      this.shipmentForm.get('recipient_selected_area').setValue(undefined);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }

    if (this.ignore_groups) {
      if (nr_messages.length > 0) {
        this.area_messages = [...nr_messages];
      }
    } else {
      if (found_reroute) {
        if (messages.length > 0) {
          this.area_messages = [...messages];
        }
      } else {
        if (nr_messages.length > 0) {
          this.area_messages = [...nr_messages];
        }
      }
    }

    if (this.area_messages.length > 0) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.cdr.detectChanges();
    }

    if (reroutes.length > 0 && !this.ignore_groups) {
      this.shipmentForm.get('recipient_selected_area').setValue(undefined);
      this.updateAreaOptions(false, reroutes[0]);
    }
  }

  updateAreaOptions(
    with_current = false,
    courier: string = this.activeCourier,
  ) {
    const ttk = this.shipmentForm.controls.recipient_postcode.value;
    if (ttk.length != 5) {
      this.areaOptions = [];
      this.cdr.detectChanges();
      return;
    }
    this.store.dispatch(new ShipmentsActionToggleLoading({ isLoading: true }));
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    this.http
      .post<any>(
        'api/shipments/areas/find_all/',
        {
          wg: true,
          filter: { courier: courier, tk: ttk },
        },
        { headers: httpHeaders },
      )
      .pipe(
        take(1),
        tap((res) => {
          this.store.dispatch(
            new ShipmentsActionToggleLoading({ isLoading: false }),
          );
          if (with_current) {
            const curr =
              this.shipmentForm.controls.recipient_selected_area.value;
            if (curr) {
              if (res.items.map((x) => x.id).includes(curr.id)) {
                this.areaOptions = res.items;
              } else {
                this.areaOptions = [curr, ...res.items];
              }
            } else {
              this.areaOptions = res.items;
            }
          } else {
            this.areaOptions = res.items;
          }
          this.areaOptions = sortBy(this.areaOptions, 'name');
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  areaCompare(option, value): boolean {
    if (!option || !value) {
      return undefined;
    }
    return option.id === value.id;
  }

  initShipment() {
    this.createForm();
    if (!this.shipment.id) {
      this.subheaderService.setTitle('Δημιουργία Αποστολής');
      if (this.adminView) {
        this.subheaderService.setBreadcrumbs([
          {
            title: 'Διαχείριση Αποστολών',
            page: `shipment-management/admin/shipments`,
          },
          { title: 'Αποστολές', page: `shipment-management/admin/shipments` },
          {
            title: 'Δημιουργία Αποστολής',
            page: `shipment-management/admin/shipments/add`,
          },
        ]);
      } else {
        this.subheaderService.setBreadcrumbs([
          { title: 'Διαχείριση Αποστολών', page: `shipment-management` },
          { title: 'Αποστολές', page: `shipment-management/shipments` },
          {
            title: 'Δημιουργία Αποστολής',
            page: `shipment-management/shipments/add`,
          },
        ]);
      }
      return;
    }
    this.subheaderService.setTitle('Επεξεργασία Αποστολής');
    if (this.adminView) {
      this.subheaderService.setBreadcrumbs([
        {
          title: 'Διαχείριση Αποστολών',
          page: `shipment-management/admin/shipments`,
        },
        { title: 'Αποστολές', page: `shipment-management/admin/shipments` },
        {
          title: 'Επεξεργασία Αποστολής',
          page: `shipment-management/admin/shipments/edit`,
          queryParams: { id: this.shipment.id },
        },
      ]);
    } else {
      this.subheaderService.setBreadcrumbs([
        { title: 'Διαχείριση Αποστολών', page: `shipment-management` },
        { title: 'Αποστολές', page: `shipment-management/shipments` },
        {
          title: 'Επεξεργασία Αποστολής',
          page: `shipment-management/shipments/edit`,
          queryParams: { id: this.shipment.id },
        },
      ]);
    }
  }

  createForm() {
    let fgd: { [k: string]: any };
    if (this.retr) {
      fgd = {
        vg_code: [this.shipment.vg_code, Validators.required],
        recipient_name: [this.shipment.recipient_name, Validators.required],
        recipient_address: [
          this.shipment.recipient_address,
          Validators.required,
        ],
        recipient_postcode: [
          this.shipment.recipient_postcode,
          Validators.required,
        ],
        recipient_area: [this.shipment.recipient_area],
        recipient_selected_area: [
          this.shipment.recipient_selected_area,
          Validators.required,
        ],
        recipient_phone: [
          this.shipment.recipient_phone,
          [Validators.required, Validators.pattern('^[0-9]*$')],
        ],
        recipient_phone2: [
          this.shipment.recipient_phone2,
          Validators.pattern('^[0-9]*$'),
        ],
        weight: [
          this.shipment.weight,
          [Validators.required, weightValidator()],
        ],
        items: [
          this.shipment.items,
          [
            Validators.min(1),
            Validators.max(30),
            Validators.required,
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        user_comments: [this.shipment.user_comments],
        sh_comments: [this.shipment.sh_comments],
        size_x: [this.shipment.size_x],
        size_y: [this.shipment.size_y],
        size_z: [this.shipment.size_z],
      };
    } else {
      fgd = {
        recipient_name: [this.shipment.recipient_name, Validators.required],
        recipient_address: [
          this.shipment.recipient_address,
          Validators.required,
        ],
        recipient_postcode: [
          this.shipment.recipient_postcode,
          Validators.required,
        ],
        recipient_area: [this.shipment.recipient_area],
        recipient_selected_area: [
          this.shipment.recipient_selected_area,
          Validators.required,
        ],
        recipient_phone: [
          this.shipment.recipient_phone,
          [Validators.required, Validators.pattern('^[0-9]*$')],
        ],
        recipient_phone2: [
          this.shipment.recipient_phone2,
          Validators.pattern('^[0-9]*$'),
        ],
        recipient_service: [this.shipment.recipient_service],
        weight: [
          this.shipment.weight,
          [Validators.required, weightValidator()],
        ],
        items: [
          this.shipment.items,
          [
            Validators.min(1),
            Validators.max(30),
            Validators.required,
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        cod_ammount: [this.shipment.cod_ammount],
        is_cod: [this.shipment.is_cod],
        voucher_a6: [this.shipment.voucher_a6],
        user_comments: [this.shipment.user_comments],
        sh_comments: [this.shipment.sh_comments],
        size_x: [this.shipment.size_x],
        size_y: [this.shipment.size_y],
        size_z: [this.shipment.size_z],
      };
    }

    if (this.adminView) {
      fgd.user = [this.shipmentUser, Validators.required];
    }

    this.shipmentForm = this.shipmentFB.group(fgd);

    if (!this.retr) {
      if (this.shipment.is_cod) {
        this.shipmentForm.get('cod_ammount').setValidators(null);
        this.shipmentForm
          .get('cod_ammount')
          .setValidators([Validators.required]);
      }

      const vsub = this.shipmentForm
        .get('is_cod')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.shipmentForm.get('cod_ammount').setValidators(null);
            this.shipmentForm
              .get('cod_ammount')
              .setValidators([Validators.required]);
          } else {
            this.shipmentForm.get('cod_ammount').setValidators(null);
            this.shipmentForm.get('cod_ammount').setValue(0);
          }
          this.shipmentForm.get('cod_ammount').updateValueAndValidity();
        });

      this.subscriptions.push(vsub);
    }

    if (this.adminView) {
      if (this.shipment.user) {
        this.auth
          .getUserById(this.shipment.user)
          .pipe(
            take(1),
            tap((res) => this.shipmentForm.get('user').setValue(res)),
          )
          .subscribe();
      }

      this.filteredOptions = this.shipmentForm.get('user').valueChanges.pipe(
        startWith(undefined),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((val) => this._filter(val || '')),
      );
    }

    const bls = this.shipmentForm
      .get('recipient_name')
      .valueChanges.pipe(
        merge(this.shipmentForm.get('recipient_address').valueChanges),
        merge(this.shipmentForm.get('recipient_postcode').valueChanges),
        merge(this.shipmentForm.get('recipient_phone').valueChanges),
        merge(this.shipmentForm.get('recipient_phone2').valueChanges),
        debounceTime(350),
      )
      .subscribe((res) => {
        const cd = {
          name: this.shipmentForm.get('recipient_name').value,
          address: this.shipmentForm.get('recipient_address').value,
          postcode: this.shipmentForm.get('recipient_postcode').value,
          phone: this.shipmentForm.get('recipient_phone').value,
          phone2: this.shipmentForm.get('recipient_phone2').value,
        };
        this.store.dispatch(new BlacklistCheck({ data: cd }));
      });
    this.subscriptions.push(bls);

    const cd = {
      name: this.shipmentForm.get('recipient_name').value,
      address: this.shipmentForm.get('recipient_address').value,
      postcode: this.shipmentForm.get('recipient_postcode').value,
      phone: this.shipmentForm.get('recipient_phone').value,
      phone2: this.shipmentForm.get('recipient_phone2').value,
    };
    this.store.dispatch(new BlacklistCheck({ data: cd }));
  }

  _filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        //take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  /* Old filter function looksup from server
     * Replaced with local lookups after full user load
     * Which is needed anyway for the local lookups on the grid

    _filter(val: any): Observable<User[]> {
        if( typeof(val) === 'object' ) {
            return of(null);
        } else {
            return this.auth.filterUsers( { qc : val } )
            .pipe(
                // tap( res => console.log(res)),
            );
        }
    }
     */

  displayFn(user?: User): string | undefined {
    return user ? `${user.lastname} ${user.firstname}` : undefined;
  }

  //private _filter( fl: string):

  goBackWithId() {
    let url = `/shipment-management/shipments`;
    if (this.adminView) {
      url = `/shipment-management/admin/shipments`;
    }
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  goToNew() {
    this.shipment = new Shipment();
    this.shipment.clear();
    this.oldShipment = Object.assign({}, this.shipment);
    this.initShipment();
    let url = `/shipment-management/shipments/add`;
    if (this.adminView) {
      url = `/shipment-management/admin/shipments/add`;
    }
    this.router.navigate([url], { relativeTo: this.activatedRoute });
    this.reset();
    this.shipmentForm.get('is_cod').setValue(true);
    this.shipmentForm.get('voucher_a6').setValue(true);
  }

  refreshShipment(isNew = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }
    if (this.adminView) {
      url = `/shipment-management/admin/shipments/edit/${id}`;
    } else {
      url = `/shipment-management/shipments/edit/${id}`;
    }
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  reset() {
    this.shipment = Object.assign({}, this.oldShipment);
    this.serverError.next(false);
    this.createForm();
    this.hasFormErrors = false;
    this.shipmentForm.markAsPristine();
    this.shipmentForm.markAsUntouched();
    this.shipmentForm.updateValueAndValidity();
  }

  onSumbit(withBack = false, addNew = false) {
    this.hasFormErrors = false;
    this.serverError.next(false);
    const controls = this.shipmentForm.controls;

    if (this.shipmentForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    const editedShipment = this.prepareShipment();

    if (editedShipment.id) {
      this.updateShipment(editedShipment, withBack, addNew);
    } else {
      if (!this.retr) {
        this.addShipment(editedShipment, withBack, addNew);
      } else {
        this.addReturn(editedShipment);
      }
    }
  }

  prepareShipment(): Shipment {
    const controls = this.shipmentForm.controls;
    const _shipment = new Shipment();
    if (!this.retr) {
      //_shipment.clear();
      _shipment.id = this.shipment.id;
      _shipment.recipient_name = controls.recipient_name.value;
      _shipment.recipient_address = controls.recipient_address.value;
      _shipment.recipient_area = controls.recipient_area.value;
      _shipment.recipient_selected_area =
        controls.recipient_selected_area.value;
      _shipment.recipient_postcode = controls.recipient_postcode.value;
      _shipment.recipient_phone = controls.recipient_phone.value;
      _shipment.recipient_phone2 = controls.recipient_phone2.value;
      _shipment.recipient_service = controls.recipient_service.value;
      _shipment.weight = controls.weight.value
        .replace(' Kg', '')
        .replace(',', '');
      _shipment.items = controls.items.value;
      _shipment.is_cod = controls.is_cod.value;
      _shipment.voucher_a6 = controls.voucher_a6.value;

      if (controls.size_x.value) {
        _shipment.size_x = controls.size_x.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_x = null;
      }

      if (controls.size_y.value) {
        _shipment.size_y = controls.size_y.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_y = null;
      }

      if (controls.size_z.value) {
        _shipment.size_z = controls.size_z.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_z = null;
      }

      if (
        controls.size_z.value &&
        controls.size_y.value &&
        controls.size_x.value
      ) {
        let calced_weight =
          (parseFloat(
            controls.size_x.value.replace(' cm', '').replace(',', ''),
          ) *
            parseFloat(
              controls.size_y.value.replace(' cm', '').replace(',', ''),
            ) *
            parseFloat(
              controls.size_z.value.replace(' cm', '').replace(',', ''),
            )) /
          5000;
        calced_weight = parseFloat(calced_weight.toFixed(2));
        if (calced_weight > parseFloat('' + _shipment.weight)) {
          _shipment.weight = calced_weight;
        }
      }

      if (!this.adding && this.adminView) {
        _shipment.extra_charges = this.extraChargesSubject.value;
      }

      if (_shipment.is_cod) {
        _shipment.cod_ammount = controls.cod_ammount.value
          .replace('€ ', '')
          .replace(',', '');
      } else {
        _shipment.cod_ammount = null;
      }
      _shipment.user_comments = controls.user_comments.value;
      _shipment.sh_comments = controls.sh_comments.value;
      if (this.adminView) {
        if (controls.user.value) {
          _shipment.user = controls.user.value.id;
        }
      }
    } else {
      // ???? Not needed ?? _shipment.id = this.shipment.id;
      _shipment.vg_code = controls.vg_code.value;
      _shipment.recipient_name = controls.recipient_name.value;
      _shipment.recipient_address = controls.recipient_address.value;
      _shipment.recipient_area = controls.recipient_area.value;
      _shipment.recipient_selected_area =
        controls.recipient_selected_area.value;
      _shipment.recipient_postcode = controls.recipient_postcode.value;
      _shipment.recipient_phone = controls.recipient_phone.value;
      _shipment.recipient_phone2 = controls.recipient_phone2.value;
      _shipment.weight = controls.weight.value
        .replace(' Kg', '')
        .replace(',', '');
      _shipment.items = controls.items.value;
      _shipment.is_cod = false;
      _shipment.cod_ammount = null;
      _shipment.user_comments = controls.user_comments.value;
      _shipment.sh_comments = controls.sh_comments.value;

      if (controls.size_x.value) {
        _shipment.size_x = controls.size_x.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_x = null;
      }

      if (controls.size_y.value) {
        _shipment.size_y = controls.size_y.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_y = null;
      }

      if (controls.size_z.value) {
        _shipment.size_z = controls.size_z.value
          .replace(' cm', '')
          .replace(',', '');
      } else {
        _shipment.size_z = null;
      }

      if (
        controls.size_z.value &&
        controls.size_y.value &&
        controls.size_x.value
      ) {
        let calced_weight =
          (parseFloat(
            controls.size_x.value.replace(' cm', '').replace(',', ''),
          ) *
            parseFloat(
              controls.size_y.value.replace(' cm', '').replace(',', ''),
            ) *
            parseFloat(
              controls.size_z.value.replace(' cm', '').replace(',', ''),
            )) /
          5000;
        calced_weight = parseFloat(calced_weight.toFixed(2));
        if (calced_weight > parseFloat('' + _shipment.weight)) {
          _shipment.weight = calced_weight;
        }
      }

      if (!this.adding && this.adminView) {
        _shipment.extra_charges = this.extraChargesSubject.value;
      }

      if (this.adminView) {
        if (controls.user.value) {
          _shipment.user = controls.user.value.id;
        }
      }
    }

    return _shipment;
  }

  addShipment(_shipment: Shipment, withBack = false, addNew = false) {
    this.store.dispatch(new ShipmentCreate({ shipment: _shipment }));
    const addSubscription = this.store
      .pipe(select(selectLastCreatedShipmentId))
      .subscribe((newId) => {
        if (newId) {
          if (withBack) {
            this.goBackWithId();
          } else if (addNew) {
            this.goToNew();
          } else {
            this.refreshShipment(true, newId);
          }
        }
      });
    this.subscriptions.push(addSubscription);
  }

  addReturn(_shipment: Shipment) {
    this.store.dispatch(new ReturnCreate({ shipment: _shipment }));
    this.router.navigate([`/shipment-management/admin/shipments`], {
      relativeTo: this.activatedRoute,
    });
  }

  updateShipment(_shipment: Shipment, withBack = false, addNew = false) {
    this.store.dispatch(new ShipmentUpdate({ shipment: _shipment }));

    if (withBack) {
      this.goBackWithId();
    } else if (addNew) {
      this.goToNew();
    } else {
      this.refreshShipment(false);
    }
  }

  getComponentTitle() {
    let result = 'Δημιουργία Αποστολής';
    if (!this.shipment || !this.shipment.id) {
      return result;
    }

    result = `Προς ${this.shipment.recipient_name}`;
    return result;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.serverError.next(false);
  }

  msgClose(msg) {
    this.area_messages = this.area_messages.filter((x) => x !== msg);
    this.cdr.detectChanges();
  }
}
