<div class="container">
  <div class="card">
    <div class="card-header">Ανάλυση Χρέωσης</div>
    <div class="card-body">
      <div class="">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Υπηρεσία</th>
              <th class="right">Κόστος</th>
              <th class="center">#</th>
              <th class="right tright">Υποσύνολο</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngIf="data.item.we" class="left strong">
                Πάγιο {{ data.item.ws }}Kg εώς {{ data.item.we }}kg
              </td>
              <td *ngIf="!data.item.we" class="left strong">Πάγιο {{ data.item.ws }}Kg και άνω</td>
              <td class="right">{{ data.item.basec | currency: "EUR" }}</td>
              <td class="right">1</td>
              <td class="right tright">{{ data.item.basec | currency: "EUR" }}</td>
            </tr>
            <tr *ngIf="data.item.ekgc > 0">
              <td class="left">Υπέρβαρο</td>
              <td class="right">{{ data.item.pkg | currency: "EUR" }}</td>
              <td class="right">{{ data.item.ekg }}</td>
              <td class="right tright">{{ data.item.ekgc | currency: "EUR" }}</td>
            </tr>
            <tr *ngIf="data.item.codc > 0">
              <td class="left">Αντικαταβολή</td>
              <td class="right">{{ data.item.codc | currency: "EUR" }}</td>
              <td class="right">1</td>
              <td class="right tright">{{ data.item.codc | currency: "EUR" }}</td>
            </tr>
            <tr *ngFor="let charge of data.item.extra_charges">
              <td class="left">{{ charge.reason }}</td>
              <td class="right">{{ charge.ammount | currency: "EUR" }}</td>
              <td class="right">1</td>
              <td class="right tright">{{ charge.ammount | currency: "EUR" }}</td>
            </tr>
            <tr *ngFor="let charge of data.item.acfd">
              <td class="left">{{ charge.reason }}</td>
              <td class="right">{{ charge.pc | currency: "EUR" }}</td>
              <td class="right">{{ charge.items }}</td>
              <td class="right tright">{{ charge.tc | currency: "EUR" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-lg-7 col-sm-5 ml-auto">
          <table class="table">
            <tbody>
              <tr>
                <td class="left">
                  <strong>Αξία</strong>
                </td>
                <td class="right tright">{{ data.item.pvat | currency: "EUR" }}</td>
              </tr>
              <tr>
                <td class="left">
                  <strong>ΦΠΑ {{ data.item.vat }}%</strong>
                </td>
                <td class="right tright">{{ data.item.tvat | currency: "EUR" }}</td>
              </tr>
              <tr>
                <td class="left">
                  <strong>Σύνολο</strong>
                </td>
                <td class="right tright">
                  <strong>{{ data.item.cost | currency: "EUR" }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
