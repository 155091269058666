// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Liquidation } from '../_models/liquidation.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum LiquidationActionTypes {
  LiquidationPageRequested = '[Liquidation List Page] Liquidation Page Requested',
  LiquidationPageLoaded = '[Liquidation API] Liquidation Page Loaded',
  LiquidationPageCancelled = '[Liquidation API] Liquidation Page Cancelled',
  LiquidationPageToggleLoading = '[Liquidation] Liquidation Page Toggle Loading',
  LiquidationActionToggleLoading = '[Liquidation] Liquidation Action Toggle Loading',
  LQActionSuccess = '[Liquidation API] Last action succeded',
  LQActionFail = '[Liquidation API] Last action failed',
  LQActionRefresh = '[Liquidation API] Emit to all interested parties to refresh all liquidations',
}

export class LiquidationPageRequested implements Action {
  readonly type = LiquidationActionTypes.LiquidationPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class LiquidationPageLoaded implements Action {
  readonly type = LiquidationActionTypes.LiquidationPageLoaded;
  constructor(
    public payload: {
      liquidations: Liquidation[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class LiquidationPageCancelled implements Action {
  readonly type = LiquidationActionTypes.LiquidationPageCancelled;
}

export class LiquidationPageToggleLoading implements Action {
  readonly type = LiquidationActionTypes.LiquidationPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class LiquidationActionToggleLoading implements Action {
  readonly type = LiquidationActionTypes.LiquidationActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class LQActionRefresh implements Action {
  readonly type = LiquidationActionTypes.LQActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class LQActionSuccess implements Action {
  readonly type = LiquidationActionTypes.LQActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class LQActionFail implements Action {
  readonly type = LiquidationActionTypes.LQActionFail;
  constructor(public payload: { result: any }) {}
}

export type LiquidationActions =
  | LiquidationPageRequested
  | LiquidationPageLoaded
  | LiquidationPageCancelled
  | LiquidationPageToggleLoading
  | LiquidationActionToggleLoading
  | LQActionRefresh
  | LQActionSuccess
  | LQActionFail;
