import { BaseModel } from '../../_base/crud';
import { Shipment } from './shipment.model';
import { User } from '../../auth/_models/user.model';

export class Liquidation extends BaseModel {
  id: number;
  name: string;
  date_created: Date;
  afile: string;
  cod_ammount: number;
  charge_ammount: number;
  user: User;
  cod_shipments: Array<Shipment>;
  charge_shipments: Array<Shipment>;
}
