import { BaseModel } from '../../_base/crud';
import { User } from '../../auth/_models/user.model';

export class ExternalShipment extends BaseModel {
  id: number;
  vg_code: string;
  date_created: Date;
  date_approved: Date;
  _status: string;
  user: User;
  is_return: boolean;

  clear(): void {
    this.id = undefined;
    this.vg_code = undefined;
    this.date_created = undefined;
    this.date_approved = undefined;
    this._status = undefined;
    this.user = undefined;
  }
}
