// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { AreaService } from '../_services/area.service';
// State
import { AppState } from '../../reducers';
import {
  AreaActionFail,
  AreaActionRefresh,
  AreaActionSuccess,
  AreaActionToggleLoading,
  AreaActionTypes,
  AreaCreate,
  AreaDelete,
  AreaPageLoaded,
  AreaPageRequested,
  AreaPageToggleLoading,
  AreaUpsert,
  PopulateForCourier,
} from '../_actions/area.actions';

@Injectable()
export class AreaEffects {
  showPageLoadingDistpatcher = new AreaPageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new AreaPageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new AreaActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new AreaActionToggleLoading({
    isLoading: false,
  });

  loadAreaPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaPageRequested>(AreaActionTypes.AreaPageRequested),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ess.findAreas(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new AreaPageLoaded({
          areas: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  deleteArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaDelete>(AreaActionTypes.AreaDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.deleteArea(payload.area).pipe(
          switchMap(() => [
            new AreaActionSuccess({ result: `Η περιοχή διαγράφηκε επιτυχώς` }),
            new AreaActionRefresh({ result: true }),
          ]),
          catchError((err) => [new AreaActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  createArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaCreate>(AreaActionTypes.AreaCreate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.createArea(payload.area).pipe(
          switchMap((res) => [
            new AreaUpsert({ area: res }),
            new AreaActionSuccess({ result: `Η περιοχή προστέθηκε επιτυχώς` }),
          ]),
          catchError((err) => [new AreaActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  populateForCourier$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PopulateForCourier>(AreaActionTypes.PopulateForCourier),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.populateForCourier(payload).pipe(
          switchMap((res) => [
            new AreaActionSuccess({
              result: `Η περιοχές ανανεώθηκαν επιτυχώς. ${res.updated} υπάρχουσες και ${res.added} νέες.`,
            }),
            new AreaActionRefresh({ result: true }),
          ]),
          catchError((err) => [new AreaActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  constructor(
    private actions$: Actions,
    private ess: AreaService,
    private store: Store<AppState>,
  ) {}
}
