// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  KgCheckReportActions,
  KgCheckReportActionTypes,
} from '../_actions/kg_check_report.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { KgCheckReport } from '../_models/kg_check_report.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface KgCheckReportState extends EntityState<KgCheckReport> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KgCheckReport> =
  createEntityAdapter<KgCheckReport>();

export const initialKgCheckReportState: KgCheckReportState =
  adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function kgCheckReportReducer(
  state = initialKgCheckReportState,
  action: KgCheckReportActions,
): KgCheckReportState {
  switch (action.type) {
    case KgCheckReportActionTypes.KgCheckReportPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case KgCheckReportActionTypes.KgCheckReportActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case KgCheckReportActionTypes.KgCheckReportPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case KgCheckReportActionTypes.KgCheckReportPageLoaded: {
      return adapter.addMany(action.payload.KgCheckReports, {
        ...initialKgCheckReportState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getKgCheckReportState =
  createFeatureSelector<KgCheckReportState>('KgCheckReports');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
