// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
import { LayoutUtilsService } from '../../../core/_base/crud';
// Object-Path
import * as objectPath from 'object-path';
// RxJS
import { Subject, Observable, of, Subscription } from 'rxjs';
import { take, map, tap, delay } from 'rxjs/operators';

import { selectGConfigById } from '../../../core/ronex';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// AppState
import { AppState } from '../../../core/reducers';

@Component({
  selector: 'kt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  fluid: boolean;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayouConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    // footer width fluid
    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
  }

  about() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'Πληροφορίες';
    dt['icon'] = 'fas fa-info-circle';
    this.store
      .pipe(
        select(selectGConfigById('general__about')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }

  contact() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'Επικοινωνία';
    dt['icon'] = 'fas fa-address-book';
    this.store
      .pipe(
        select(selectGConfigById('general__contact')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }
}
