// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  InvoiceCheckReportActions,
  InvoiceCheckReportActionTypes,
} from '../_actions/invoice_check_report.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { InvoiceCheckReport } from '../_models/invoice_check_report.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InvoiceCheckReportState
  extends EntityState<InvoiceCheckReport> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<InvoiceCheckReport> =
  createEntityAdapter<InvoiceCheckReport>();

export const initialInvoiceCheckReportState: InvoiceCheckReportState =
  adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function invoiceCheckReportReducer(
  state = initialInvoiceCheckReportState,
  action: InvoiceCheckReportActions,
): InvoiceCheckReportState {
  switch (action.type) {
    case InvoiceCheckReportActionTypes.InvoiceCheckReportPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case InvoiceCheckReportActionTypes.InvoiceCheckReportActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case InvoiceCheckReportActionTypes.InvoiceCheckReportPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case InvoiceCheckReportActionTypes.InvoiceCheckReportPageLoaded: {
      return adapter.addMany(action.payload.InvoiceCheckReports, {
        ...initialInvoiceCheckReportState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getInvoiceCheckReportState =
  createFeatureSelector<InvoiceCheckReportState>('InvoiceCheckReports');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
