// Angular
import { Injectable } from '@angular/core';
// RxJS
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
// CRUD
// Services
import { NotificationService } from '../_services/notification.service';
// State
import { AppState } from '../../reducers';

import {
  NotificationActionTypes,
  NotificationCreate,
  NotificationCreateIfNotExists,
  NotificationDelete,
  NotificationsClear,
  NotificationsLoaded,
  NotificationsRequested,
} from '../_actions/notification.actions';
import { NotificationExists } from '../_selectors/notification.selectors';

@Injectable()
export class NotificationEffects {
  fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NotificationsRequested>(
        NotificationActionTypes.NotificationsRequested,
      ),
      mergeMap(() => {
        return this.ns.getNotifications();
      }),
      map((res) => {
        //console.log(res);
        return new NotificationsLoaded({ notifications: res });
      }),
    ),
  );

  clearNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NotificationsClear>(NotificationActionTypes.NotificationsClear),
      switchMap(() =>
        this.ns.clearNotifications().pipe(
          switchMap((res) => [new NotificationsRequested()]),
          catchError((err) => {
            err.ref_s = true;
            return [new NotificationsRequested()];
          }),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  deleteNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NotificationDelete>(NotificationActionTypes.NotificationDelete),
      mergeMap(({ payload }) => {
        return this.ns.deleteNotification(payload.id);
      }),
      map((res) => {
        return new NotificationsRequested();
      }),
    ),
  );

  createNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NotificationCreate>(NotificationActionTypes.NotificationCreate),
      mergeMap(({ payload }) => {
        return this.ns.addNotification(payload.notification);
      }),
      map((res) => {
        //console.log(res);
        return new NotificationsRequested();
      }),
    ),
  );

  createNotificationIfNotExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType<NotificationCreateIfNotExists>(
        NotificationActionTypes.NotificationCreateIfNotExists,
      ),
      mergeMap((action) =>
        of(action).pipe(
          delay(2000),
          withLatestFrom(
            this.store.pipe(
              select(NotificationExists, { on: action.payload.notification }),
            ),
          ),
        ),
      ),
      mergeMap(([{ payload }, exists]) => {
        if (!exists) {
          return this.ns.addNotification(payload.notification);
        } else {
          return of(null);
        }
      }),
      map((res) => {
        return new NotificationsRequested();
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ns: NotificationService,
    private store: Store<AppState>,
  ) {}
}
