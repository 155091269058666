import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ShipmentService } from '../../../../core/ronex/_services/shipment.service';
import { Shipment } from '../../../../core/ronex/_models/shipment.model';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

import { ShipmentUpsert } from '../../../../core/ronex/_actions/shipment.actions';

@Component({
  selector: 'kt-pickup-view-bs',
  templateUrl: './pickup-view-bs.component.html',
  styleUrls: ['./pickup-view-bs.component.scss'],
})
export class PickupViewBsComponent implements OnInit {
  serverError: Subject<any>;
  tomorrow: Date;
  item: Shipment;
  tz: string;
  sdate: any;
  comments: string;
  loading = false;
  trans = {
    OPEN: 'ANOIXTH',
    EXECUTED: 'ΕΚΤΕΛΕΣΤΗΚΕ',
    FAILED: 'ΑΠΕΤΥΧΕ',
    CANCELLED: 'ΑΚΥΡΗ',
    COMPLETED: 'ΟΛΟΚΛΗΡΩΘΗΚΕ',
  };
  puo: any;
  puoErr = false;

  weekendsDatesFilter = (d: Date): boolean => {
    d = new Date(d);
    const day = d.getDay();
    /* Prevent Saturday and Sunday for select. */
    return day !== 0 && day !== 6;
  };

  constructor(
    public bsRef: MatBottomSheetRef<PickupViewBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private ss: ShipmentService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);
    this.item = this.data.item;

    const today = new Date();
    this.tomorrow = new Date(today);
    //this.tomorrow.setDate(this.tomorrow.getDate() + 1)

    this.refreshOrder(this.item.pickup_order);
  }

  refreshOrder(oid: string) {
    this.puoErr = false;
    if (oid) {
      this.loading = true;
      this.ss
        .getPickupOrder({ oid: oid })
        .pipe(
          take(1),
          tap((res) => {
            this.loading = false;
            this.puo = { ...res };
            this.puoErr = false;
          }),
          catchError((err) => {
            if (
              // eslint-disable-next-line no-prototype-builtins
              err.hasOwnProperty('error') &&
              // eslint-disable-next-line no-prototype-builtins
              err.error.hasOwnProperty('detail')
            ) {
              this.serverError.next(err.error.detail);
            } else {
              this.serverError.next(
                'Συνέβη ένα σφάλμα με την άντληση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
              );
            }
            this.puoErr = true;
            this.loading = false;
            this.puo = undefined;
            return of(null);
          }),
          finalize(() => this.cdr.detectChanges()),
        )
        .subscribe();
    }
  }

  createPickup() {
    if (!this.tz || !this.sdate) {
      this.serverError.next('Σιγουρευτείτε πως έχετε επιλέξει ημέρα και ώρα.');
    } else {
      const data = {
        sid: this.item.id,
        tz: this.tz,
        sdate: this.sdate.format(),
        comments: this.comments,
      };
      this.loading = true;
      this.ss
        .createPickupOrder(data)
        .pipe(
          take(1),
          tap((res) =>
            this.store.dispatch(new ShipmentUpsert({ shipment: res })),
          ),
          tap((res) => (this.item = res)),
          tap((res) => this.refreshOrder(res.pickup_order)),
          catchError((err) => {
            if (
              // eslint-disable-next-line no-prototype-builtins
              err.hasOwnProperty('error') &&
              // eslint-disable-next-line no-prototype-builtins
              err.error.hasOwnProperty('detail')
            ) {
              this.serverError.next(err.error.detail);
            } else {
              this.serverError.next(
                'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
              );
            }
            this.loading = false;
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  cancelPickup() {
    const data = {
      sid: this.item.id,
    };
    this.loading = true;
    this.ss
      .cancelPickupOrder(data)
      .pipe(
        take(1),
        tap((res) => (this.puo = undefined)),
        tap((res) =>
          this.store.dispatch(new ShipmentUpsert({ shipment: res })),
        ),
        tap((res) => (this.item = res)),
        tap((res) => this.refreshOrder(res.pickup_order)),
        catchError((err) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            err.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            err.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(err.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
          this.loading = false;
          return of(null);
        }),
        finalize(() => this.cdr.detectChanges()),
      )
      .subscribe();
  }

  reschedulePickup() {
    if (!this.tz || !this.sdate) {
      this.serverError.next('Σιγουρευτείτε πως έχετε επιλέξει ημέρα και ώρα.');
    } else {
      const data = {
        sid: this.item.id,
        tz: this.tz,
        sdate: this.sdate.format(),
        comments: this.comments,
      };
      this.loading = true;
      this.ss
        .reschedulePickupOrder(data)
        .pipe(
          take(1),
          tap((res) => this.refreshOrder(res.pickup_order)),
          catchError((err) => {
            if (
              // eslint-disable-next-line no-prototype-builtins
              err.hasOwnProperty('error') &&
              // eslint-disable-next-line no-prototype-builtins
              err.error.hasOwnProperty('detail')
            ) {
              this.serverError.next(err.error.detail);
            } else {
              this.serverError.next(
                'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
              );
            }
            this.loading = false;
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  close() {
    this.bsRef.dismiss();
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
