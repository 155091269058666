import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {
  map,
  catchError,
  delay,
  mergeMap,
  tap,
  finalize,
} from 'rxjs/operators';

@Component({
  selector: 'kt-pdf-view-bs',
  templateUrl: './pdf-view-bs.component.html',
  styleUrls: ['./pdf-view-bs.component.scss'],
})
export class PdfViewBsComponent implements OnInit {
  @ViewChild('pdv') private pdfComponent: PdfViewerComponent;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;

  isPrinting: BehaviorSubject<boolean>;

  constructor(
    public bsRef: MatBottomSheetRef<PdfViewBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.isPrinting = new BehaviorSubject<boolean>(false);
    this.isPrinting.next(false);
  }

  close() {
    this.bsRef.dismiss();
  }

  download() {
    //       window.open(this.data.item.voucher,'download');
    const a: HTMLAnchorElement = <HTMLAnchorElement>document.createElement('A');
    a.href = this.data.item.voucher;
    a.download = this.data.item.voucher.substr(
      this.data.item.voucher.lastIndexOf('/') + 1,
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  print() {
    this.isPrinting.next(true);
    const url = `/api/shipments/${this.data.item.id}/pdf_raw/`;
    this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }),
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          const isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isFirefox) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
          } else {
            const blob = new Blob([res], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.src = blobUrl;
            iframe.style.display = 'none';
            return iframe;
          }
        }),
        delay(4000),
        tap((ifr) => {
          if (ifr) {
            ifr.contentWindow.print();
          }
        }),
        finalize(() => this.isPrinting.next(false)),
      )
      .subscribe();
  }

  after_print() {
    this.isPrinting.next(false);
  }
}
