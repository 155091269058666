// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { ExternalShipment } from '../_models/external_shipment.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum ExternalShipmentActionTypes {
  ExternalShipmentPageRequested = '[ExternalShipment List Page] ExternalShipment Page Requested',
  ExternalShipmentPageLoaded = '[ExternalShipment API] ExternalShipment Page Loaded',
  ExternalShipmentPageCancelled = '[ExternalShipment API] ExternalShipment Page Cancelled',
  ExternalShipmentPageToggleLoading = '[ExternalShipment] ExternalShipment Page Toggle Loading',
  ExternalShipmentActionToggleLoading = '[ExternalShipment] ExternalShipment Action Toggle Loading',
  ESActionSuccess = '[ExternalShipment API] Last action succeded',
  ESActionFail = '[ExternalShipment API] Last action failed',
  ESActionRefresh = '[ExternalShipment API] Emit to all interested parties to refresh all external_shipments',
  ExternalShipmentDelete = '[ExternalShipments API] ExternalShipment Delete',
  ExternalShipmentMassDelete = '[ExternalShipments API] ExternalShipment Delete Many',
  ExternalShipmentRemove = '[ExternalShipments] Remove',
  ExternalShipmentMassRemove = '[ExternalShipments] Remove Many',
  ExternalShipmentUpsert = '[ExternalShipments] Update or Insert',
  ExternalShipmentCreate = '[ExternalShipments] Create',
  ExternalShipmentCheck = '[ExternalShipments API] Check Status with ELTA',
}

export class ExternalShipmentCheck implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentCheck;
}

export class ExternalShipmentCreate implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentCreate;
  constructor(public payload: { shipment: ExternalShipment }) {}
}

export class ExternalShipmentDelete implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentDelete;
  constructor(public payload: { shipment: ExternalShipment }) {}
}

export class ExternalShipmentMassDelete implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentMassDelete;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ExternalShipmentRemove implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentRemove;
  constructor(public payload: { shipment: ExternalShipment }) {}
}

export class ExternalShipmentMassRemove implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentMassRemove;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ExternalShipmentUpsert implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentUpsert;
  constructor(public payload: { shipment: ExternalShipment }) {}
}

export class ExternalShipmentPageRequested implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class ExternalShipmentPageLoaded implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentPageLoaded;
  constructor(
    public payload: {
      external_shipments: ExternalShipment[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class ExternalShipmentPageCancelled implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentPageCancelled;
}

export class ExternalShipmentPageToggleLoading implements Action {
  readonly type = ExternalShipmentActionTypes.ExternalShipmentPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class ExternalShipmentActionToggleLoading implements Action {
  readonly type =
    ExternalShipmentActionTypes.ExternalShipmentActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class ESActionRefresh implements Action {
  readonly type = ExternalShipmentActionTypes.ESActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class ESActionSuccess implements Action {
  readonly type = ExternalShipmentActionTypes.ESActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class ESActionFail implements Action {
  readonly type = ExternalShipmentActionTypes.ESActionFail;
  constructor(public payload: { result: any }) {}
}

export type ExternalShipmentActions =
  | ExternalShipmentPageRequested
  | ExternalShipmentPageLoaded
  | ExternalShipmentPageCancelled
  | ExternalShipmentPageToggleLoading
  | ExternalShipmentActionToggleLoading
  | ESActionRefresh
  | ESActionSuccess
  | ESActionFail
  | ExternalShipmentDelete
  | ExternalShipmentMassDelete
  | ExternalShipmentRemove
  | ExternalShipmentMassRemove
  | ExternalShipmentUpsert
  | ExternalShipmentCreate
  | ExternalShipmentCheck;
