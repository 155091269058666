// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  LiquidationActions,
  LiquidationActionTypes,
} from '../_actions/liquidation.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Liquidation } from '../_models/liquidation.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LiquidationState extends EntityState<Liquidation> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Liquidation> =
  createEntityAdapter<Liquidation>();

export const initialLiquidationState: LiquidationState =
  adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function liquidationReducer(
  state = initialLiquidationState,
  action: LiquidationActions,
): LiquidationState {
  switch (action.type) {
    case LiquidationActionTypes.LiquidationPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case LiquidationActionTypes.LiquidationActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case LiquidationActionTypes.LiquidationPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case LiquidationActionTypes.LiquidationPageLoaded: {
      return adapter.addMany(action.payload.liquidations, {
        ...initialLiquidationState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getLiquidationState =
  createFeatureSelector<LiquidationState>('liquidations');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
