<kt-portlet>
  <!-- PORTLET LOADING | Binded to TABLE Datasource -->
  <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
  <kt-portlet-header
    [title]="'Λίστα Χρηστών'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <button
        [routerLink]="['../users/add']"
        mat-raised-button
        color="primary"
        matTooltip="Δημιουργία νέου χρήστη"
      >
        Νέος Χρήστης
      </button>
      <!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
      <!-- mat-raised-button | Rectangular contained button w/ elevation  -->
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

  <!-- start::Body (attribute: ktPortletBody) -->
  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="kt-form kt-margin-b-30">
      <!-- start::FILTERS -->
      <div class="kt-form__filtration">
        <div class="row align-items-center">
          <div class="col-md-2 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Αναζήτηση Χρήστη" #searchInput placeholder="Αναζήτηση" />
              <mat-hint align="start"> <strong>Αναζήτηση</strong> σε όλα τα πεδία </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- end::FILTERS -->

      <!-- start::GROUP ACTIONS -->
      <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
      <!-- Group actions are shared for all LISTS -->
      <div
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: selection.selected.length > 0 }"
      >
        <!-- We show 'Group Actions' div if smth are selected -->
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Επιλεγμένες εγγραφές: </span> {{ selection.selected.length }}
              </label>
              <!-- selectedCountsTitle => function from codeBehind (users-list.component.ts file) -->
              <!-- selectedCountsTitle => just returns title of selected items count -->
              <!-- for example: Selected records count: 4 -->
            </div>
            <div class="kt-form__control kt-form__group--inline">
              <button
                *ngIf="false"
                (click)="deleteUsers()"
                color="warn"
                mat-raised-button
                matTooltip="Διαγραφή επιλεγμένων χρηστών"
                class="mat-button-mt-4"
              >
                <mat-icon>delete</mat-icon>
                Διαγραφή Επιλεγμένων
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end::GROUP ACTIONS -->
    </div>
    <!-- end::FILTERS & GROUP ACTIONS -->

    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
    <div class="mat-table__wrapper">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        matSortActive="id"
        matSortDirection="desc"
        matSortDisableClear
      >
        <!-- Checkbox Column -->

        <!-- Table with selection -->
        <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell"
            >Email</mat-header-cell
          >
          <mat-cell *matCellDef="let user" class="email-cell">
            <a href="user-management/users#" class="kt-link">{{ user.email }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Όνομα</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.firstname }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Επώνυμο</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.lastname }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <button
              (click)="editUser(user.id)"
              mat-icon-button
              color="primary"
              matTooltip="Επεξεργασία Χρήστη"
            >
              <mat-icon>create</mat-icon></button
            >&nbsp;
            <button
              mat-icon-button
              color="warn"
              matTooltip="Διαγραφή Χρήστη"
              type="button"
              (click)="deleteUser(user)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">Δεν βρέθηκαν εγγραφές</div>
      <!-- Message for empty data  -->
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Παρακαλώ περιμένετε...
      </div>
    </div>

    <!-- start: BOTTOM -->
    <div class="mat-table__bottom">
      <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <!-- MATERIAL PAGINATOR | Binded to dasources -->
      <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
      <mat-paginator
        [pageSize]="50"
        [pageSizeOptions]="[10, 50, 100]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
