// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Blacklist } from '../_models/blacklist.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum BlacklistActionTypes {
  BlacklistPageRequested = '[Blacklist List Page] Blacklist Page Requested',
  BlacklistPageLoaded = '[Blacklist API] Blacklist Page Loaded',
  BlacklistPageCancelled = '[Blacklist API] Blacklist Page Cancelled',
  BlacklistPageToggleLoading = '[Blacklist] Blacklist Page Toggle Loading',
  BlacklistActionToggleLoading = '[Blacklist] Blacklist Action Toggle Loading',
  BLActionSuccess = '[Blacklist API] Last action succeded',
  BLActionFail = '[Blacklist API] Last action failed',
  BLActionRefresh = '[Blacklist API] Emit to all interested parties to refresh all blacklists',
  BlacklistRemove = '[Blacklist API] Remove blacklist entry from server',
  BlacklistDelete = '[Blacklist ] Remove blacklist entry from local store',
  BlacklistCheck = '[Blacklist API] Check if entry exists in blacklist',
  CheckResult = '[Blacklist] Emit check result back to subscribers',
}

export class BlacklistPageRequested implements Action {
  readonly type = BlacklistActionTypes.BlacklistPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class BlacklistPageLoaded implements Action {
  readonly type = BlacklistActionTypes.BlacklistPageLoaded;
  constructor(
    public payload: {
      blacklists: Blacklist[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class BlacklistPageCancelled implements Action {
  readonly type = BlacklistActionTypes.BlacklistPageCancelled;
}

export class BlacklistPageToggleLoading implements Action {
  readonly type = BlacklistActionTypes.BlacklistPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class BlacklistActionToggleLoading implements Action {
  readonly type = BlacklistActionTypes.BlacklistActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class BLActionRefresh implements Action {
  readonly type = BlacklistActionTypes.BLActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class BLActionSuccess implements Action {
  readonly type = BlacklistActionTypes.BLActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class BLActionFail implements Action {
  readonly type = BlacklistActionTypes.BLActionFail;
  constructor(public payload: { result: any }) {}
}

export class BlacklistRemove implements Action {
  readonly type = BlacklistActionTypes.BlacklistRemove;
  constructor(public payload: { blacklist: Blacklist }) {}
}

export class BlacklistDelete implements Action {
  readonly type = BlacklistActionTypes.BlacklistDelete;
  constructor(public payload: { blacklist: Blacklist }) {}
}

export class BlacklistCheck implements Action {
  readonly type = BlacklistActionTypes.BlacklistCheck;
  constructor(public payload: { data: any }) {}
}

export class CheckResult implements Action {
  readonly type = BlacklistActionTypes.CheckResult;
  constructor(public payload: { result: boolean }) {}
}

export type BlacklistActions =
  | BlacklistPageRequested
  | BlacklistPageLoaded
  | BlacklistPageCancelled
  | BlacklistPageToggleLoading
  | BlacklistActionToggleLoading
  | BLActionRefresh
  | BLActionSuccess
  | BLActionFail
  | BlacklistRemove
  | BlacklistDelete
  | BlacklistCheck
  | CheckResult;
