// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Notification } from '../_models/notification.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum NotificationActionTypes {
  NotificationCreate = '[Notifications API] Notification Create',
  NotificationDelete = '[Notifications API] Notification Delete',
  NotificationCreated = '[Notifications] Notification Created',
  NotificationsRequested = '[Notifications API] Notifications Requested',
  NotificationsLoaded = '[Notifications] Notifications Loaded',
  NotificationCreateIfNotExists = '[Notifications API] Notification Create if NE',
  NotificationsClear = '[Notifications API] Clear Notificationns for user',
}

export class NotificationsClear implements Action {
  readonly type = NotificationActionTypes.NotificationsClear;
}

export class NotificationCreateIfNotExists implements Action {
  readonly type = NotificationActionTypes.NotificationCreateIfNotExists;
  constructor(public payload: { notification: Notification }) {}
}

export class NotificationCreate implements Action {
  readonly type = NotificationActionTypes.NotificationCreate;
  constructor(public payload: { notification: Notification }) {}
}

export class NotificationDelete implements Action {
  readonly type = NotificationActionTypes.NotificationDelete;
  constructor(public payload: { id: number }) {}
}

export class NotificationCreated implements Action {
  readonly type = NotificationActionTypes.NotificationCreated;
  constructor(public payload: { notification: Notification }) {}
}

export class NotificationsRequested implements Action {
  readonly type = NotificationActionTypes.NotificationsRequested;
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationActionTypes.NotificationsLoaded;
  constructor(public payload: { notifications: Notification[] }) {}
}

export type NotificationActions =
  | NotificationCreated
  | NotificationsRequested
  | NotificationsLoaded
  | NotificationCreate
  | NotificationDelete
  | NotificationCreateIfNotExists
  | NotificationsClear;
