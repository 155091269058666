// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, takeWhile, delay, filter } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isLoggedIn, isStaff, currentUser } from '../_selectors/auth.selectors';

@Injectable()
export class EsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store.pipe(
      select(currentUser),
      filter((res) => res),
      tap((res) => {
        if (res) {
          if (!res.has_es && !res.is_staff) {
            this.router.navigateByUrl('/dashboard');
          }
        }
      }),
      map((res) => {
        if (res) {
          return res.has_es || res.is_staff;
        }
      }),
    );
  }
}
