// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { ChargeActions, ChargeActionTypes } from '../_actions/charge.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Charge } from '../_models/charge.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChargeState extends EntityState<Charge> {
  totalCount: number;
}

export const adapter: EntityAdapter<Charge> = createEntityAdapter<Charge>();

export const initialChargeState: ChargeState = adapter.getInitialState({
  totalCount: 0,
});

export function chargeReducer(
  state = initialChargeState,
  action: ChargeActions,
): ChargeState {
  switch (action.type) {
    case ChargeActionTypes.AllChargesLoaded:
      return adapter.setAll(action.payload.charges, state);
    case ChargeActionTypes.ChargesUpsert:
      return adapter.upsertMany(action.payload.charges, state);

    default:
      return state;
  }
}

export const getChargeState = createFeatureSelector<ChargeState>('charges');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
