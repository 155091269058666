// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import {
  HttpUtilsService,
  TypesUtilsService,
  InterceptService,
  LayoutUtilsService,
} from '../../../core/_base/crud';
// Shared
import { ActionNotificationComponent } from '../../partials/content/crud';
// Components
import { ShipmentsComponent } from './shipments.component';
import { ShipmentsListComponent } from './shipments/shipments-list/shipments-list.component';
import {
  DeleteEntityDialogComponent,
  ApproveShipmentDialogComponent,
} from '../../partials/content/crud/index';
import { DatePipe } from '@angular/common';

// Material
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
//import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import {
  shipmentsReducer,
  ShipmentEffects,
  blacklistReducer,
  BlacklistEffects,
  external_shipmentReducer,
  ExternalShipmentEffects,
} from '../../../core/ronex';

import { ShipmentsEditComponent } from './shipments/shipments-edit/shipments-edit.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import {
  PdfViewBsComponent,
  ShipmentViewBsComponent,
  PickupViewBsComponent,
} from '../../partials/ronex';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { StaffGuard, EsGuard } from '../../../core/auth';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LookupUserPipe } from './shipments/_pipes/lookup-user.pipe';
import { ShipmentsFinancialComponent } from './shipments/shipments-financial/shipments-financial.component';
import { TotalsComponent } from './shipments/shipments-financial/_subs/totals/totals.component';
import { ExtraChargesComponent } from './shipments/_subs/extra-charges/extra-charges.component';
import { BlacklistListComponent } from './shipments/blacklist-list/blacklist-list.component';
import { ExternalShipmentsComponent } from './shipments/external-shipments/external-shipments.component';
import { CreateReturnComponent } from './shipments/create-return/create-return.component';

export const MY_FORMATS = {
  parse: {
    dateInput: ['L', 'LL'],
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const routes: Routes = [
  {
    path: '',
    component: ShipmentsComponent,
    children: [
      {
        path: '',
        redirectTo: 'shipments',
        pathMatch: 'full',
      },
      {
        path: 'shipments',
        component: ShipmentsListComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'shipments:id',
        component: ShipmentsListComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'shipments/add',
        component: ShipmentsEditComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'shipments/create-return',
        component: CreateReturnComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'shipments/edit',
        component: ShipmentsEditComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'shipments/edit/:id',
        component: ShipmentsEditComponent,
        //canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments',
        component: ShipmentsListComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/hipments:id',
        component: ShipmentsListComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments/add',
        component: ShipmentsEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments/create-return',
        component: CreateReturnComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments/edit',
        component: ShipmentsEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments/edit/:id',
        component: ShipmentsEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'admin/shipments/financial',
        component: ShipmentsFinancialComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'shipments/financial',
        component: ShipmentsFinancialComponent,
      },
      {
        path: 'admin/blacklist',
        component: BlacklistListComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'external_shipments',
        component: ExternalShipmentsComponent,
        canActivate: [EsGuard],
      },
      {
        path: 'admin/external_shipments',
        component: ExternalShipmentsComponent,
        canActivate: [StaffGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('shipments', shipmentsReducer),
    EffectsModule.forFeature([ShipmentEffects]),
    StoreModule.forFeature('blacklists', blacklistReducer),
    EffectsModule.forFeature([BlacklistEffects]),
    StoreModule.forFeature('external_shipments', external_shipmentReducer),
    EffectsModule.forFeature([ExternalShipmentEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatChipsModule,
    CurrencyMaskModule,
    TextMaskModule,
    MatBottomSheetModule,
    PdfJsViewerModule,
    PdfViewerModule,
    PerfectScrollbarModule,
    MatButtonToggleModule,
    MatDividerModule,
  ],
  providers: [
    DatePipe,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'el-GR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
  declarations: [
    ShipmentsComponent,
    ShipmentsListComponent,
    ShipmentsEditComponent,
    LookupUserPipe,
    ShipmentsFinancialComponent,
    TotalsComponent,
    ExtraChargesComponent,
    BlacklistListComponent,
    ExternalShipmentsComponent,
    CreateReturnComponent,
  ],
})
export class ShipmentsModule {}
