import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { ExternalShipment } from '../_models/external_shipment.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ExternalShipmentService {
  constructor(private http: HttpClient) {}

  getAllExternalShipments(): Observable<ExternalShipment[]> {
    return this.http.get<ExternalShipment[]>('api/shipments/external/');
  }

  findExternalShipments(
    queryParams: QueryParamsModel,
  ): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/shipments/external/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  checkExternalShipments(): Observable<any> {
    return this.http.get<any>('api/shipments/external/check/');
  }

  deleteShipment(shipment: ExternalShipment) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `api/shipments/external/${shipment.id}/`;
    return this.http.delete(url, { headers: httpHeaders });
  }

  deleteShipments(ids: Array<number>): Observable<Array<number>> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<any>(
        'api/shipments/external/mass_delete/',
        { ids: ids },
        { headers: httpHeaders },
      )
      .pipe(map((res) => res.ids));
  }

  createShipment(shipment: ExternalShipment): Observable<ExternalShipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<ExternalShipment>(
      'api/shipments/external/',
      shipment,
      { headers: httpHeaders },
    );
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
