// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { BlacklistState } from '../_reducers/blacklist.reducers';
import { each } from 'lodash';
import { Blacklist } from '../_models/blacklist.model';

export const selectBlacklistState =
  createFeatureSelector<BlacklistState>('blacklists');

export const selectBlacklistById = (id: number) =>
  createSelector(
    selectBlacklistState,
    (blacklistState) => blacklistState.entities[id],
  );

export const selectBlacklistPageLoading = createSelector(
  selectBlacklistState,
  (blacklistState) => {
    return blacklistState.listLoading;
  },
);

export const selectBlacklistActionLoading = createSelector(
  selectBlacklistState,
  (blacklistState) => blacklistState.actionsloading,
);

export const selectBlacklistPageLastQuery = createSelector(
  selectBlacklistState,
  (blacklistState) => blacklistState.lastQuery,
);

export const selectBlacklistsInStore = createSelector(
  selectBlacklistState,
  (blacklistState) => {
    const items: Blacklist[] = [];
    each(blacklistState.ids, (id: number) => {
      items.push(blacklistState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: Blacklist[] = httpExtension.sortArray(
      items,
      blacklistState.lastQuery.sortField,
      blacklistState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, blacklistState.totalCount, '');
  },
);

export const selectBlacklistShowInitWaitingMessage = createSelector(
  selectBlacklistState,
  (blacklistState) => blacklistState.showInitWaitingMessage,
);

export const selectHasBlacklistsInStore = createSelector(
  selectBlacklistState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
