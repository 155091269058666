// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
// RxJS
import { tap, takeWhile } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../reducers/';
import { currentUser } from '../../../auth';

@Injectable()
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   * @param store
   */
  constructor(
    private menuConfigService: MenuConfigService,
    private store: Store<AppState>,
  ) {
    this.loadMenu();
  }

  /**
   * Load menu list
   */
  loadMenu() {
    // get menu list
    let menuItems: any[] = objectPath.get(
      this.menuConfigService.getMenus(),
      'aside.items',
    );

    this.store
      .pipe(
        select(currentUser),
        tap((res) => {
          if (res) {
            if (res.is_staff) {
              this.loadAdminMenu(menuItems);
            } else {
              if (!res.has_es) {
                menuItems = menuItems.filter(
                  (k) => k.title !== 'Εξωτερικές Αποστολές',
                );
              }

              this.menuList$.next(menuItems);
            }
          }
        }),
        takeWhile((res) => !res),
      )
      .subscribe();
  }

  loadAdminMenu(mItems: any[]) {
    const a_items = [
      { section: 'ΔΙΑΧΕΙΡΙΣΗ' },
      {
        title: 'Διαχείριση Χρηστών',
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-user-outline-symbol',
        submenu: [
          {
            title: 'Χρήστες',
            page: '/user-management/users',
          },
          {
            title: 'Οικονομικά Χρηστών',
            page: '/user-management/users/financial',
          },
        ],
      },
      {
        title: 'Διαχείριση Αποστολών',
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-rocket',
        submenu: [
          {
            title: 'Αποστολές',
            page: '/shipment-management/admin/shipments',
          },
          {
            title: 'Οικονομική Διαχείριση',
            page: '/shipment-management/admin/shipments/financial',
          },
          {
            title: 'Εξωτερικές Αποστολές',
            page: '/shipment-management/admin/external_shipments',
          },
          {
            title: 'Blacklist',
            page: '/shipment-management/admin/blacklist',
          },
        ],
      },
      {
        title: 'Εκκαθαρίσεις',
        root: true,
        bullet: 'dot',
        icon: 'flaticon-doc',
        submenu: [
          {
            title: 'Αποδόσεις Αντικαταβολών',
            page: '/liquidations/returns',
          },
          {
            title: 'Αναλύσεις Χρεώσεων',
            page: '/liquidations/charges',
          },
          {
            title: 'Αποδείξεις Είσπραξης',
            page: '/liquidations/receipts',
          },
        ],
      },
      {
        title: 'Αναφορές',
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-list',
        submenu: [
          {
            title: 'Ελέγχου Βάρους',
            page: '/reports/kg-checks',
          },
          {
            title: 'Αρχείων Τιμολόγησης',
            page: '/reports/invoice-checks',
          },
        ],
      },
      {
        title: 'Περιοχές',
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-map',
        submenu: [
          {
            title: 'Περιοχές',
            page: '/courier_areas/areas',
          },
          {
            title: 'Ομάδες Περιοχών',
            page: '/courier_areas/groups',
          },
        ],
      },
      {
        title: 'Ρυθμίσεις',
        root: true,
        bullet: 'dot',
        icon: 'fas fa-cogs',
        submenu: [
          {
            title: 'Γενικές Ρυθμίσεις',
            page: '/settings/general',
          },
          {
            title: 'Ρυθμίσεις API',
            page: '/settings/api',
          },
        ],
      },
    ];

    this.menuList$.next(mItems.concat(a_items));
  }
}
