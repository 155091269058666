// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  ExternalShipmentActions,
  ExternalShipmentActionTypes,
} from '../_actions/external_shipment.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ExternalShipment } from '../_models/external_shipment.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExternalShipmentState extends EntityState<ExternalShipment> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ExternalShipment> =
  createEntityAdapter<ExternalShipment>();

export const initialExternalShipmentState: ExternalShipmentState =
  adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function external_shipmentReducer(
  state = initialExternalShipmentState,
  action: ExternalShipmentActions,
): ExternalShipmentState {
  switch (action.type) {
    case ExternalShipmentActionTypes.ExternalShipmentPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case ExternalShipmentActionTypes.ExternalShipmentActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case ExternalShipmentActionTypes.ExternalShipmentPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case ExternalShipmentActionTypes.ExternalShipmentUpsert:
      return adapter.upsertOne(action.payload.shipment, {
        ...state,
      });
    case ExternalShipmentActionTypes.ExternalShipmentRemove:
      return adapter.removeOne(action.payload.shipment.id, state);
    case ExternalShipmentActionTypes.ExternalShipmentMassRemove:
      return adapter.removeMany(action.payload.ids, state);

    case ExternalShipmentActionTypes.ExternalShipmentPageLoaded: {
      return adapter.addMany(action.payload.external_shipments, {
        ...initialExternalShipmentState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getExternalShipmentState =
  createFeatureSelector<ExternalShipmentState>('external_shipments');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
