import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { KgCheckReport } from '../_models/kg_check_report.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class KgCheckReportService {
  constructor(private http: HttpClient) {}

  getAllKgCheckReports(): Observable<KgCheckReport[]> {
    return this.http.get<KgCheckReport[]>('api/reports/kg_check_reports/');
  }

  findKgCheckReports(
    queryParams: QueryParamsModel,
  ): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/reports/kg_check_reports/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  persistent_nf(): Observable<Array<string>> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<Array<string>>(
      'api/reports/kg_check_reports/persistent_nf/',
      { headers: httpHeaders },
    );
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
