// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { UserwfdState } from '../_reducers/userwfd.reducers';
import { each } from 'lodash';
import { Userwfd } from '../_models/userwfd.model';

export const selectUserwfdState =
  createFeatureSelector<UserwfdState>('userwfds');

export const selectUserwfdById = (id: number) =>
  createSelector(
    selectUserwfdState,
    (userwfdState) => userwfdState.entities[id],
  );

export const selectUserwfdPageLoading = createSelector(
  selectUserwfdState,
  (userwfdState) => {
    return userwfdState.listLoading;
  },
);

export const selectUserwfdActionLoading = createSelector(
  selectUserwfdState,
  (userwfdState) => userwfdState.actionsloading,
);

export const selectUserwfdPageLastQuery = createSelector(
  selectUserwfdState,
  (userwfdState) => userwfdState.lastQuery,
);

export const selectUserwfdsInStore = createSelector(
  selectUserwfdState,
  (userwfdState) => {
    const items: Userwfd[] = [];
    each(userwfdState.ids, (id: number) => {
      items.push(userwfdState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: Userwfd[] = httpExtension.sortArray(
      items,
      userwfdState.lastQuery.sortField,
      userwfdState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, userwfdState.totalCount, '');
  },
);

export const selectUserwfdShowInitWaitingMessage = createSelector(
  selectUserwfdState,
  (userwfdState) => userwfdState.showInitWaitingMessage,
);

export const selectHasUserwfdsInStore = createSelector(
  selectUserwfdState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
