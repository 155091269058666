// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Userwfd } from '../_models/userwfd.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum UserwfdActionTypes {
  UserwfdPageRequested = '[Userwfd List Page] Userwfd Page Requested',
  UserwfdPageLoaded = '[Userwfd API] Userwfd Page Loaded',
  UserwfdPageCancelled = '[Userwfd API] Userwfd Page Cancelled',
  UserwfdPageToggleLoading = '[Userwfd] Userwfd Page Toggle Loading',
  UserwfdActionToggleLoading = '[Userwfd] Userwfd Action Toggle Loading',
}

export class UserwfdPageRequested implements Action {
  readonly type = UserwfdActionTypes.UserwfdPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class UserwfdPageLoaded implements Action {
  readonly type = UserwfdActionTypes.UserwfdPageLoaded;
  constructor(
    public payload: {
      userwfds: Userwfd[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class UserwfdPageCancelled implements Action {
  readonly type = UserwfdActionTypes.UserwfdPageCancelled;
}

export class UserwfdPageToggleLoading implements Action {
  readonly type = UserwfdActionTypes.UserwfdPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class UserwfdActionToggleLoading implements Action {
  readonly type = UserwfdActionTypes.UserwfdActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type UserwfdActions =
  | UserwfdPageRequested
  | UserwfdPageLoaded
  | UserwfdPageCancelled
  | UserwfdPageToggleLoading
  | UserwfdActionToggleLoading;
