// Angular
import { Injectable } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
// RxJS
import {
  mergeMap,
  switchMap,
  catchError,
  withLatestFrom,
  map,
  tap,
  delay,
  finalize,
} from 'rxjs/operators';
import { Observable, throwError, defer, of, forkJoin } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { UserfileService } from '../_services/userfile.service';
// State
import { AppState } from '../../../core/reducers';
import {
  UserfileActionTypes,
  UserfilesPageRequested,
  UserfilesPageLoaded,
  UserfilesActionToggleLoading,
  UserfilesPageToggleLoading,
  UserfileCreate,
  UserfileUpsert,
  FileUploadProgress,
  UploadSuccess,
  UploadFailure,
  UFActionRefresh,
  UFActionSuccess,
  UFActionFail,
  UserfileDelete,
  UserfileRemove,
  UserfileMassDelete,
  UserfileMassRemove,
} from '../_actions/userfile.actions';

@Injectable()
export class UserfileEffects {
  showPageLoadingDistpatcher = new UserfilesPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new UserfilesPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new UserfilesActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new UserfilesActionToggleLoading({
    isLoading: false,
  });

  deleteUserfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserfileDelete>(UserfileActionTypes.UserfileDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ufs.deleteUserfile(payload.userfile).pipe(
          switchMap(() => [
            new UserfileRemove({ userfile: payload.userfile }),
            new UFActionSuccess({
              result: `Το αρχείο ${payload.userfile.name} διαγράφηκε επιτυχώς`,
            }),
            new UFActionRefresh({ result: true }),
          ]),
          catchError((err) => [new UFActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  deleteUserfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserfileMassDelete>(UserfileActionTypes.UserfileMassDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ufs.deleteUserfiles(payload.ids).pipe(
          switchMap((res) => [
            new UserfileMassRemove({ ids: res }),
            new UFActionSuccess({ result: `Τα αρχεία διαγράφηκαν επιτυχώς` }),
            new UFActionRefresh({ result: true }),
          ]),
          catchError((err) => [new UFActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  createUserfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserfileCreate>(UserfileActionTypes.UserfileCreate),
        map((action) => action.payload),
        tap((payload) => {
          const formData = new FormData();
          formData.append('afile', payload.userfile, payload.userfile.name);
          formData.append('name', payload.userfile.name.replace(/\..+$/, ''));
          formData.append('user', `${payload.uid}`);

          this.ufs
            .createUserfile(formData)
            .pipe(
              map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    this.store.dispatch(
                      new FileUploadProgress({
                        userfile: payload.userfile,
                        progress: Math.round(
                          (event.loaded * 100) / event.total,
                        ),
                      }),
                    );
                    break;
                  case HttpEventType.Response:
                    return event;
                }
              }),
            )
            .subscribe(
              (event: any) => {
                if (typeof event === 'object') {
                  this.store.dispatch(
                    new UploadSuccess({ userfile: payload.userfile }),
                  );
                  this.store.dispatch(
                    new UserfileUpsert({ userfile: event.body }),
                  );
                  this.store.dispatch(new UFActionRefresh({ result: true }));
                }
              },
              (err) => {
                this.store.dispatch(
                  new UploadFailure({ userfile: payload.userfile }),
                );
              },
            );
        }),
      ),
    { dispatch: false },
  );

  loadUserfilesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserfilesPageRequested>(
        UserfileActionTypes.UserfilesPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ufs.findUserfiles(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new UserfilesPageLoaded({
          userfiles: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ufs: UserfileService,
    private store: Store<AppState>,
  ) {}
}
