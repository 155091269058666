import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
  take,
  mergeMap,
  takeLast,
  last,
  map,
  tap,
  delay,
} from 'rxjs/operators';
import { mapValues, groupBy, omit, sortBy } from 'lodash';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../../core/reducers';
import { selectUserById } from '../../../../../../../core/auth';

@Component({
  selector: 'kt-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss'],
})
export class TotalsComponent implements OnInit, OnChanges {
  @Input() shipments: Array<any>;
  @Input() selection: boolean;
  @Input() t_selection: Array<any>;

  total_cod: number;
  total_charge: number;
  total_cod_inv: number;
  total_charge_inv: number;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  ngOnChanges() {
    this.total_charge = 0;
    this.total_charge_inv = 0;
    this.total_cod = 0;
    this.total_cod_inv = 0;
    this.shipments.forEach((obj) => {
      if (this.selection) {
        if (obj._status === 'PAID_APPROVED' || obj._status === 'PAID') {
          this.total_cod += parseFloat(
            parseFloat('' + (obj.cod_ammount || 0)).toFixed(2),
          );
          this.total_cod -= parseFloat(
            parseFloat('' + (obj.prereturn_ammount || 0)).toFixed(2),
          );
        }
        if (obj._status === 'PAID_APPROVED') {
          this.total_cod_inv += parseFloat(
            parseFloat('' + (obj.cod_ammount || 0)).toFixed(2),
          );
          this.total_cod_inv -= parseFloat(
            parseFloat('' + (obj.prereturn_ammount || 0)).toFixed(2),
          );
        }

        this.store
          .pipe(
            select(selectUserById(obj.user)),
            take(1),
            tap((res) => {
              if (
                obj._status === 'PROCESSED' &&
                !obj.is_prereturn &&
                res.allow_prereturns &&
                obj.is_cod &&
                parseFloat('' + obj.cod_ammount) > 0 &&
                obj.is_delivered
              ) {
                const tmp_ammount =
                  (parseFloat(
                    parseFloat('' + (obj.cod_ammount || 0)).toFixed(2),
                  ) *
                    parseFloat('' + res.prereturn_perc)) /
                  100;
                this.total_cod +=
                  tmp_ammount > parseFloat('' + res.prereturn_max)
                    ? parseFloat('' + res.prereturn_max)
                    : tmp_ammount;
                this.total_cod_inv +=
                  tmp_ammount > parseFloat('' + res.prereturn_max)
                    ? parseFloat('' + res.prereturn_max)
                    : tmp_ammount;
                this.total_charge +=
                  parseFloat(parseFloat('' + res.prereturn_charge).toFixed(2)) *
                  (1 + parseFloat('' + res.vat_val) / 100);
                this.total_charge_inv +=
                  parseFloat(parseFloat('' + res.prereturn_charge).toFixed(2)) *
                  (1 + parseFloat('' + res.vat_val) / 100);
              }
            }),
          )
          .subscribe();

        if (
          obj._status === 'RETURNED' &&
          obj.is_prereturn &&
          parseFloat('' + obj.prereturn_ammount) > 0
        ) {
          this.total_cod -= parseFloat(
            parseFloat('' + obj.prereturn_ammount).toFixed(2),
          );
          this.total_cod_inv -= parseFloat(
            parseFloat('' + obj.prereturn_ammount).toFixed(2),
          );
        }
      } else {
        this.total_cod += parseFloat(
          parseFloat('' + (obj.cod_ammount || 0)).toFixed(2),
        );
      }
      if (this.selection) {
        if (!obj.is_prepaid) {
          this.total_charge += parseFloat(
            parseFloat('' + (obj.cost || 0)).toFixed(2),
          );
        }

        if (
          !obj.is_prepaid &&
          (obj._status === 'PAID_APPROVED' ||
            obj._status === 'PROCESSED' ||
            obj._status === 'RETURNED')
        ) {
          this.total_charge_inv += parseFloat(
            parseFloat('' + (obj.cost || 0)).toFixed(2),
          );
        }

        if (
          obj.is_prepaid &&
          parseFloat(obj.cost).toFixed(2) !==
            parseFloat(obj.prepaid_ammount).toFixed(2) &&
          obj.prepaid_ammount &&
          obj._status !== 'INVOICED' &&
          obj._status !== 'PROCESSED'
        ) {
          this.total_charge += parseFloat(
            parseFloat('' + (obj.cost || 0)).toFixed(2),
          );
          this.total_charge -= parseFloat(
            parseFloat('' + (obj.prepaid_ammount || 0)).toFixed(2),
          );

          if (
            obj._status === 'PAID_APPROVED' ||
            obj._status === 'PROCESSED' ||
            obj._status === 'RETURNED'
          ) {
            this.total_charge_inv += parseFloat(
              parseFloat('' + (obj.cost || 0)).toFixed(2),
            );
            this.total_charge_inv -= parseFloat(
              parseFloat('' + (obj.prepaid_ammount || 0)).toFixed(2),
            );
          }
        }
      } else {
        this.total_charge += parseFloat(
          parseFloat('' + (obj.cost || 0)).toFixed(2),
        );
      }
    });
  }
}
