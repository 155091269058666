// Anglar
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
} from './_base/layout';
import { StaffGuard } from './auth/_guards/staff.guard';
import { EsGuard } from './auth/_guards/es.guard';
import { RestrictedGuard } from './auth/_guards/restricted.guard';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
  notificationReducer,
  NotificationEffects,
  chargeReducer,
  ChargeEffects,
  cschargeReducer,
  CSChargeEffects,
} from './ronex/';

import { NotificationService } from './ronex/';
import { AreaService } from './ronex/';
import { AreaGroupService } from './ronex/';
import { ChargeService } from './ronex/';
import { CSChargeService } from './ronex/';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('notification', notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),
    StoreModule.forFeature('charge', chargeReducer),
    EffectsModule.forFeature([ChargeEffects]),
    StoreModule.forFeature('cscharge', cschargeReducer),
    EffectsModule.forFeature([CSChargeEffects]),
  ],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  providers: [
    StaffGuard,
    EsGuard,
    RestrictedGuard,
    NotificationService,
    ChargeService,
    CSChargeService,
    AreaService,
    AreaGroupService,
  ],
})
export class CoreModule {}
