<kt-portlet>
  <kt-portlet-header
    [title]="'Προς:  ' + data.item.recipient_name"
    [class]="'kt-portlet__head--lg'"
  >
    <ng-container ktPortletTools>
      <span class="sp">
        <mat-spinner color="primary" *ngIf="isPrinting | async" diameter="25"></mat-spinner
      ></span>
      <button class="mright mat-button-mt-4" mat-raised-button (click)="download()" color="accent">
        <mat-icon>cloud_download</mat-icon>
      </button>
      <button
        class="mright mat-button-mt-4 green-btn"
        mat-raised-button
        (click)="print()"
        [disabled]="isPrinting | async"
        color="primary"
      >
        <mat-icon>local_printshop</mat-icon>
      </button>
      <button
        class="mright mat-button-mt-4 red-btn"
        mat-raised-button
        (click)="close()"
        color="primary"
      >
        <mat-icon>keyboard_return</mat-icon>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-section__content">
          <div perfectScrollbar [ngStyle]="{ 'max-height': '65vh' }">
            <div id="pdf_js">
              <ng2-pdfjs-viewer
                #pdfViewerOnDemand
                [startPrint]="true"
                [openFile]="false"
                [download]="false"
              ></ng2-pdfjs-viewer>
            </div>
            <div id="print-frame" class="ppdf">
              <pdf-viewer
                #pdv
                [src]="data.item.voucher"
                [fit-to-page]="true"
                [autoresize]="true"
                [original-size]="true"
                style="min-height: 500px; min-width: 1px"
                [render-text]="true"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
