// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { CSChargeState } from '../_reducers/cscharge.reducers';
import { each } from 'lodash';
import { CSCharge } from '../_models/cscharge.model';
import { Shipment } from '../_models/shipment.model';

export const selectCSChargeState =
  createFeatureSelector<CSChargeState>('cscharge');

export const selectCSChargeById = (chargeId: number) =>
  createSelector(
    selectCSChargeState,
    (cschargeState) => cschargeState.entities[chargeId],
  );

export const selectCSChargesByUid = (uid: number) =>
  createSelector(selectCSChargeState, (cschargeState) =>
    Object.values(cschargeState.entities).filter(
      (charge) => charge.user === uid,
    ),
  );

export const selectCSChargesForShipment = (sh: Shipment) =>
  createSelector(selectCSChargeState, (cschargeState) => {
    //Filter charges by dates created relative to shipment date approved or now() if pending
    //
    let charges: Array<CSCharge> = Object.values(cschargeState.entities)
      .filter((charge) => charge.user === sh.user)
      .filter((charge) => {
        if (sh._status === 'PENDING') {
          if (charge.courier === sh.recipient_selected_area.courier) {
            return true;
          } else {
            return false;
          }
        } else {
          if (charge.courier === sh.courier) {
            return true;
          } else {
            return false;
          }
        }
      })
      .filter((charge) => {
        const cdc: Date = new Date(charge.date_created);
        let shdc: Date = null;
        if (sh.date_approved) {
          shdc = new Date(sh.date_approved);
        } else {
          shdc = new Date(Date.now());
        }

        return shdc.getTime() >= cdc.getTime();
      });

    if (!charges.length) {
      return null;
    }

    const mrd = new Date(
      Math.max.apply(
        null,
        charges.map((ch) => {
          return new Date(ch.date_created);
        }),
      ),
    );

    charges = charges.filter((ch) => {
      const d = new Date(ch.date_created);
      return d.getTime() == mrd.getTime();
    });

    //Filter charges based on shipment weight
    let mw = -1.0;
    charges.forEach((ch) => {
      if (parseFloat('' + sh.weight) > parseFloat('' + ch.weight_start)) {
        if (mw < parseFloat('' + ch.weight_start)) {
          mw = parseFloat('' + ch.weight_start);
        }
      }
    });
    charges = charges.filter((ch) => parseFloat('' + ch.weight_start) == mw);

    return charges[0];
  });
