// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { AreaGroup } from '../_models/area_group.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum AreaGroupActionTypes {
  AreaGroupPageRequested = '[Area Group List Page] Area Group Page Requested',
  AreaGroupPageLoaded = '[Area Group API] Area Group Page Loaded',
  AreaGroupPageCancelled = '[Area Group API] Area Group Page Cancelled',
  AreaGroupPageToggleLoading = '[Area Group] Area Group Page Toggle Loading',
  AreaGroupActionToggleLoading = '[Area Group] Area Group Action Toggle Loading',
  AreaGroupActionSuccess = '[Area Group API] Last action succeded',
  AreaGroupActionFail = '[Area Group API] Last action failed',
  AreaGroupActionRefresh = '[Area Group API] Emit to all interested parties to refresh all area groups',
  AreaGroupDelete = '[Area Group API] Area Group Delete',
  AreaGroupUpsert = '[Area Group] Update or Insert',
  AreaGroupCreate = '[Area Group] Create',
  AreaGroupUpdate = '[Area Group] Update',
}

export class AreaGroupCreate implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupCreate;
  constructor(public payload: { areaGroup: AreaGroup }) {}
}

export class AreaGroupUpdate implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupUpdate;
  constructor(public payload: { areaGroup: AreaGroup }) {}
}

export class AreaGroupDelete implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupDelete;
  constructor(public payload: { areaGroup: AreaGroup }) {}
}

export class AreaGroupUpsert implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupUpsert;
  constructor(public payload: { areaGroup: AreaGroup }) {}
}

export class AreaGroupPageRequested implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class AreaGroupPageLoaded implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupPageLoaded;
  constructor(
    public payload: {
      areaGroups: AreaGroup[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class AreaGroupPageCancelled implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupPageCancelled;
}

export class AreaGroupPageToggleLoading implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class AreaGroupActionToggleLoading implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class AreaGroupActionRefresh implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class AreaGroupActionSuccess implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class AreaGroupActionFail implements Action {
  readonly type = AreaGroupActionTypes.AreaGroupActionFail;
  constructor(public payload: { result: any }) {}
}

export type AreaGroupActions =
  | AreaGroupPageRequested
  | AreaGroupPageLoaded
  | AreaGroupPageCancelled
  | AreaGroupPageToggleLoading
  | AreaGroupActionToggleLoading
  | AreaGroupActionRefresh
  | AreaGroupActionSuccess
  | AreaGroupActionFail
  | AreaGroupDelete
  | AreaGroupUpsert
  | AreaGroupCreate
  | AreaGroupUpdate;
