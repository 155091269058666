import { BaseModel } from '../../_base/crud';
import { AreaGroupAction } from './area_group_action.model';

export class AreaGroup extends BaseModel {
  id: number;
  name: string;
  areas: Array<number>;
  courier: string;
  group_actions: Array<AreaGroupAction>;

  clear(): void {
    this.name = undefined;
    this.areas = [];
    this.courier = 'ELTA';
    this.group_actions = [];
  }
}
