import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  BlacklistActions,
  BlacklistActionTypes,
} from '../_actions/blacklist.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Blacklist } from '../_models/blacklist.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BlacklistState extends EntityState<Blacklist> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Blacklist> =
  createEntityAdapter<Blacklist>();

export const initialBlacklistState: BlacklistState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function blacklistReducer(
  state = initialBlacklistState,
  action: BlacklistActions,
): BlacklistState {
  switch (action.type) {
    case BlacklistActionTypes.BlacklistPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case BlacklistActionTypes.BlacklistActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case BlacklistActionTypes.BlacklistPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case BlacklistActionTypes.BlacklistRemove:
      return adapter.removeOne(action.payload.blacklist.id, state);

    case BlacklistActionTypes.BlacklistPageLoaded: {
      return adapter.addMany(action.payload.blacklists, {
        ...initialBlacklistState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getBlacklistState =
  createFeatureSelector<BlacklistState>('blacklists');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
