// Angular
import { Injectable } from '@angular/core';
// RxJS
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { LiquidationService } from '../_services/liquidation.service';
// State
import { AppState } from '../../../core/reducers';
import {
  LiquidationActionToggleLoading,
  LiquidationActionTypes,
  LiquidationPageLoaded,
  LiquidationPageRequested,
  LiquidationPageToggleLoading,
} from '../_actions/liquidation.actions';

@Injectable()
export class LiquidationEffects {
  showPageLoadingDistpatcher = new LiquidationPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new LiquidationPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new LiquidationActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new LiquidationActionToggleLoading({
    isLoading: false,
  });

  loadLiquidationPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LiquidationPageRequested>(
        LiquidationActionTypes.LiquidationPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ls.findLiquidations(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new LiquidationPageLoaded({
          liquidations: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ls: LiquidationService,
    private store: Store<AppState>,
  ) {}
}
