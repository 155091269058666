import { BaseModel } from '../../_base/crud';
import { AreaGroup } from './area_group.model';

export class Area extends BaseModel {
  id: number;
  tk: string;
  name: string;
  courier: string;
  nomos: string;
  poli: string;
  category: string;
  area_type: string;
  eksipiretei: string;
  delay_in_days: string;
  area_groups: Array<AreaGroup>;
}
