<kt-portlet>
  <kt-portlet-header [title]="'Αρχεία'" [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTools> </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <ng-container *ngIf="curUser | async as user">
      <kt-user-files-list [user]="user"></kt-user-files-list>
    </ng-container>
  </kt-portlet-body>
</kt-portlet>
