// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Charge } from '../_models/charge.model';
// Models
import { HttpErrorResponse } from '@angular/common/http';

export enum ChargeActionTypes {
  LoadAllCharges = '[Charges API] Load All Charges',
  RefreshAllCharges = '[Charges API] Refresh All Charges',
  AllChargesLoaded = '[Charges] All Charges Loaded',
  AllChargesLoadFailed = '[Charges API] All Charges Failed to Load',
  CActionSuccess = '[Charges] Last action success',
  CActionFail = '[Charges] Last action failed',
  ChargesUpsert = '[Charges] Upsert Charges',
  ChargesAdd = '[Charges API] Add Charges',
}

export class CActionSuccess implements Action {
  readonly type = ChargeActionTypes.CActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class CActionFail implements Action {
  readonly type = ChargeActionTypes.CActionFail;
  constructor(public payload: { result: any }) {}
}

export class ChargesAdd implements Action {
  readonly type = ChargeActionTypes.ChargesAdd;
  constructor(public payload: { charges: Array<Charge> }) {}
}

export class ChargesUpsert implements Action {
  readonly type = ChargeActionTypes.ChargesUpsert;
  constructor(public payload: { charges: Array<Charge> }) {}
}

export class LoadAllCharges implements Action {
  readonly type = ChargeActionTypes.LoadAllCharges;
}

export class RefreshAllCharges implements Action {
  readonly type = ChargeActionTypes.LoadAllCharges;
}

export class AllChargesLoaded implements Action {
  readonly type = ChargeActionTypes.AllChargesLoaded;
  constructor(public payload: { charges: Array<Charge> }) {}
}

export class AllChargesLoadFailed implements Action {
  readonly type = ChargeActionTypes.AllChargesLoadFailed;
  constructor(public payload: { result: any }) {}
}

export type ChargeActions =
  | LoadAllCharges
  | RefreshAllCharges
  | AllChargesLoaded
  | AllChargesLoadFailed
  | CActionSuccess
  | CActionFail
  | ChargesUpsert
  | ChargesAdd;
