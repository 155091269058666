// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, switchMap } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { GConfigService } from '../_services/gconfig.service';
// State
import { AppState } from '../../reducers';
import {
  GCActionFail,
  GCActionSuccess,
  GConfigActionTypes,
  GConfigBulkUpdate,
  GConfigLoaded,
  GConfigRequested,
  GConfigUpdate,
  GConfigUpsert,
  GConfigUpsertMany,
} from '../_actions/gconfig.actions';

@Injectable()
export class GConfigEffects {
  loadGConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GConfigRequested>(GConfigActionTypes.GConfigRequested),
      switchMap(() =>
        this.gcs.getGConfig().pipe(
          switchMap((res) => [
            new GConfigLoaded({ gconfig: res }),
            new GCActionSuccess({ result: 'General Config Loaded' }),
          ]),
          catchError((err) => [new GCActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  updateGConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GConfigUpdate>(GConfigActionTypes.GConfigUpdate),
      map((action) => action.payload),
      switchMap((payload) =>
        this.gcs.updateGConfig(payload.gconfig).pipe(
          switchMap((res) => [
            new GConfigUpsert({ gconfig: res }),
            new GCActionSuccess({
              result: `Οι ρυθμίσεις ενημερώθηκαν επιτυχώς`,
            }),
          ]),
          catchError((err) => [new GCActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  updateGConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GConfigBulkUpdate>(GConfigActionTypes.GConfigBulkUpdate),
      map((action) => action.payload),
      switchMap((payload) =>
        this.gcs.updateBulk(payload.values).pipe(
          switchMap((res) => [
            new GConfigUpsertMany({ gconfig: res }),
            new GCActionSuccess({
              result: `Οι ρυθμίσεις ενημερώθηκαν επιτυχώς`,
            }),
          ]),
          catchError((err) => [new GCActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  constructor(
    private actions$: Actions,
    private gcs: GConfigService,
    private store: Store<AppState>,
  ) {}
}
