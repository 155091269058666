import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';

export class User extends BaseModel {
  id: number;
  username: string;
  password: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  roles: number[];
  pic: string;
  fullname: string;
  firstname: string;
  lastname: string;
  occupation: string;
  phone: string;
  new_pass: string;
  confirm_new_pass: string;
  address: Address;
  is_active: boolean;
  is_staff: boolean;
  user_address: string;
  post_code: string;
  state: string;
  city: string;
  vat: string;
  adt: string;
  mobile: string;
  is_company: boolean;
  companyName: string;
  companyVat: string;
  companyFiscalService: string;
  companyAddress: string;
  companyPostCode: string;
  companyCity: string;
  bank_payments: boolean;
  iban: string;
  bank: string;
  can_edit_profile: boolean;
  vat_val: number;
  kap: string;
  return_ammount: number;
  has_es: boolean;
  print_comments: boolean;
  allow_prereturns: boolean;
  prereturn_charge: number;
  prereturn_max: number;
  prereturn_perc: number;
  courier: string;
  pickup_rights: number;
  ignore_area_groups: boolean;
  allow_add_by_xls: boolean;
  return_cod_charge: boolean;
  modify_voucher: boolean;

  clear(): void {
    this.id = undefined;
    this.username = '';
    this.password = '';
    this.email = '';
    this.roles = [];
    this.fullname = '';
    this.firstname = '';
    this.lastname = '';
    this.accessToken = 'access-token-' + Math.random();
    this.refreshToken = 'access-token-' + Math.random();
    this.pic = './assets/media/users/default.jpg';
    this.occupation = '';
    this.companyName = '';
    this.phone = '';
    this.address = new Address();
    this.address.clear();
    this.is_active = false;
    this.is_staff = false;
    this.user_address = '';
    this.city = '';
    this.post_code = '';
    this.state = '';
    this.vat = '';
    this.adt = '';
    this.mobile = '';
    this.is_company = false;
    this.companyName = '';
    this.companyVat = '';
    this.companyFiscalService = '';
    this.companyAddress = '';
    this.companyPostCode = '';
    this.companyCity = '';
    this.bank_payments = false;
    this.iban = '';
    this.bank = '';
    this.can_edit_profile = true;
    this.vat_val = 24;
    this.kap = '';
    this.return_ammount = undefined;
    this.has_es = false;
    this.print_comments = false;
    this.allow_prereturns = false;
    this.prereturn_charge = undefined;
    this.prereturn_max = undefined;
    this.prereturn_perc = undefined;
    this.courier = 'ELTA';
    this.ignore_area_groups = false;
    this.allow_add_by_xls = false;
    this.return_cod_charge = true;
    this.modify_voucher = true;
  }
}
