import { BaseModel } from '../../_base/crud';
import { Shipment } from './shipment.model';

export class Blacklist extends BaseModel {
  id: number;
  name: string;
  address: string;
  area: string;
  postcode: string;
  phone: string;
  phone2: string;
  shipments: Array<Shipment>;
}
