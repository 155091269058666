// Angular
import { Injectable } from '@angular/core';
// RxJS
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { AuthService } from '../_services';
// State
import { AppState } from '../../reducers';
import {
  AddUserError,
  AllUsersLoaded,
  AllUsersRequested,
  FetchUser,
  SaveSuccess,
  UserActionTypes,
  UserCreated,
  UserDeleted,
  UserLoaded,
  UserOnServerCreated,
  UsersActionToggleLoading,
  UsersPageLoaded,
  UsersPageRequested,
  UsersPageToggleLoading,
  UserUpdated,
} from '../_actions/user.actions';

@Injectable()
export class UserEffects {
  showPageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: false,
  });

  fetchUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchUser>(UserActionTypes.FetchUser),
        map((action) => action.payload),
        tap((payload) => {
          this.auth
            .getUserById(payload.id)
            .pipe(
              take(1),
              tap((res) => this.store.dispatch(new UserLoaded({ user: res }))),
              catchError((err) => of(null)),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  loadAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AllUsersRequested>(UserActionTypes.AllUsersRequested),
      //map( action => action.payload ),
      //tap( () => this.store.dispatch(this.showActionLoadingDistpatcher) ),
      switchMap(() =>
        this.auth.getAllUsers().pipe(
          switchMap((res) => [
            new AllUsersLoaded({ users: res }),
            //new ActionSuccess({ result : `Η αποστολή προς ${res.recipient_name} προστέθηκε επιτυχώς`})
          ]),
          //catchError( err => [ new ActionFail({ result: err }) ] )
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  loadUsersPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.auth.findUsers(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        //console.log( result.items );
        return new UsersPageLoaded({
          users: result.items,

          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserDeleted>(UserActionTypes.UserDeleted),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.deleteUser(payload.id);
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      }),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserUpdated>(UserActionTypes.UserUpdated),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.auth.updateUser(payload.user).pipe(
          switchMap((res) => [
            new UserLoaded({ user: res }),
            new SaveSuccess(res),
          ]),
          catchError((err) => [new AddUserError(err)]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        return this.auth.createUser(payload.user).pipe(
          catchError((err) => {
            this.store.dispatch(new AddUserError(err));
            return throwError(err);
            //return of(null);
          }),
          tap((res) => {
            this.store.dispatch(new UserCreated({ user: res }));
            this.store.dispatch(new SaveSuccess(res));
            //console.log('ssss');
          }),
        );
      }),
      map(() => {
        return this.hideActionLoadingDistpatcher;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {}
}
