<kt-alert
  *ngIf="serverError | async as err"
  type="warn"
  [showCloseButton]="true"
  [duration]="10000"
  (close)="onAlertClose($event)"
>
  {{ err }}
</kt-alert>
<div class="row">
  <div class="col">
    <mat-card [ngClass]="'fw'">
      <mat-card-title>GDPR</mat-card-title>
      <mat-card-subtitle>Πολιτική Προστασίας Δεδομένων Προσωπικού Χαρακτήρα</mat-card-subtitle>
      <mat-card-content>
        <ckeditor [editor]="Editor" [(ngModel)]="gdpr.value" [config]="{ toolbar: tb }"></ckeditor>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card [ngClass]="'fw'">
      <mat-card-title>Όροι Χρήσης</mat-card-title>
      <mat-card-subtitle>Οροι και προυποθέσεις για την χρήση της εφαρμογής</mat-card-subtitle>
      <mat-card-content>
        <ckeditor [editor]="Editor" [(ngModel)]="ox.value" [config]="{ toolbar: tb }"></ckeditor>
      </mat-card-content>
    </mat-card>
  </div>
</div>
