import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { AreaGroup } from '../_models/area_group.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AreaGroupService {
  constructor(private http: HttpClient) {}

  getAllAreaGroups(): Observable<AreaGroup[]> {
    return this.http.get<AreaGroup[]>('api/shipments/area_groups/');
  }

  findAreaGroups(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/shipments/area_groups/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  deleteAreaGroup(areaGroup: AreaGroup) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `api/shipments/area_groups/${areaGroup.id}/`;
    return this.http.delete(url, { headers: httpHeaders });
  }

  createAreaGroup(areaGroup: AreaGroup): Observable<AreaGroup> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<AreaGroup>('api/shipments/area_groups/', areaGroup, {
      headers: httpHeaders,
    });
  }

  updateAreaGroup(areaGroup: AreaGroup): Observable<AreaGroup> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments/area_groups'}/${areaGroup.id}/`;
    return this.http.patch<AreaGroup>(url, areaGroup, { headers: httpHeaders });
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
