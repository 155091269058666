import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
  take,
  catchError,
  mergeMap,
  takeLast,
  last,
  map,
  tap,
  delay,
} from 'rxjs/operators';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { ApikeysService } from '../../../../../../core/ronex/_services/apikeys.service';

@Component({
  selector: 'kt-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss'],
})
export class ApiKeysComponent implements OnInit {
  keyName: string = undefined;
  sError: Subject<any>;
  ApiKeys: Array<any> = [];
  isLoading = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private keys: ApikeysService,
  ) {}

  ngOnInit() {
    this.sError = new Subject();
    this.getKeys();
  }

  genKey() {
    if (!this.keyName || this.keyName === '') {
      this.sError.next('Το όνομα κλειδιού είναι απαραίτητο.');
    } else {
      this.isLoading = true;
      this.keys
        .addKey({ name: this.keyName })
        .pipe(
          take(1),
          tap((res) => this.getKeys()),
          tap((res) => (this.keyName = '')),
          catchError((err) => {
            this.sError.next('Συνέβη ένα σφάλμα. Παρακαλώ ξαναπροσπαθήστε');
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  getKeys() {
    this.isLoading = true;
    this.keys
      .getApiKeys()
      .pipe(
        tap((res) => (this.ApiKeys = res)),
        tap((res) => (this.isLoading = false)),
        tap((res) => this.ref.detectChanges()),
        catchError((err) => {
          this.sError.next('Συνέβη ένα σφάλμα. Παρακαλώ ξαναπροσπαθήστε');
          return of(null);
        }),
      )
      .subscribe();
  }

  removeKey(key) {
    this.isLoading = true;
    this.keys
      .removeKey(key)
      .pipe(
        take(1),
        tap((res) => this.getKeys()),
        catchError((err) => {
          this.sError.next('Συνέβη ένα σφάλμα. Παρακαλώ ξαναπροσπαθήστε');
          return of(null);
        }),
      )
      .subscribe();
  }

  onAlertClose($event) {
    this.sError.next(false);
  }
}
