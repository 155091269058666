<kt-portlet>
  <kt-portlet-header
    [title]="'Εξωτερικές Αποστολές'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <a
        (click)="refs()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Ανανέωση Εξωτερικών Αποστολών"
      >
        <i class="la la-refresh"></i>
        <span class="kt-hidden-mobile">Ανανέωση</span>
      </a>
      <a
        *ngIf="adminView"
        (click)="check()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Έλεγχος αποστολών"
      >
        <i class="fas fa-check"></i>
        <span class="kt-hidden-mobile">Έλεγχος</span>
      </a>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="row align-items-center">
      <div class="col-md-12 kt-margin-bottom-10-mobile">
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
      </div>
    </div>

    <div class="kt-form kt-margin-b-30">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Αναζήτηση Αποστολών </mat-panel-title>
          <mat-panel-description> Αναζήτηση εξωτερικών αποστολών. </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #vgcodeList aria-label="Κωδ. Αποστολής">
                  <mat-chip
                    class="fchip"
                    *ngFor="let code of fvg_codes"
                    selectable="false"
                    removable="true"
                    (removed)="vg_remove(code)"
                  >
                    {{ code }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Κωδ. Αποστολής..."
                    [matChipInputFor]="vgcodeList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    matChipInputAddOnBlur="true"
                    (matChipInputTokenEnd)="vg_add($event)"
                  />
                </mat-chip-list>
                <mat-hint align="start"> <strong>Αναζήτση</strong> με Κωδ. Αποστολής </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-4 kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="spend tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PENDING')"
                  value="PENDING"
                >
                  <i class="flaticon2-download mri aicon mri"></i>
                  Εκκρεμείς
                </mat-button-toggle>
                <mat-button-toggle
                  class="sappr tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PROCESSED')"
                  value="PROCESSED"
                >
                  <i class="flaticon2-black-back-closed-envelope-shape mri"></i>
                  Εγκεκριμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="snm tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('NOT_MINE')"
                  value="NOT_MINE"
                >
                  <i class="fa fa-question-circle mri"></i>
                  Αγνωστες
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFCe('change', $event)"
                  [(ngModel)]="dpFC"
                  [matDatepicker]="dpFCP"
                  placeholder="Δημιουργήθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFCP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTCe('change', $event)"
                  [(ngModel)]="dpTC"
                  [matDatepicker]="dpTCP"
                  placeholder="Δημιουργήθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTCP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFAe('change', $event)"
                  [(ngModel)]="dpFA"
                  [matDatepicker]="dpFAP"
                  placeholder="Εγκρίθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFAP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTAe('change', $event)"
                  [(ngModel)]="dpTA"
                  [matDatepicker]="dpTAP"
                  placeholder="Εγκρίθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTAP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="adminView" class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #usrInput
                    (keyup)="filterUsers($event)"
                    placeholder="Χρήστης..."
                    [formControl]="usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"> <strong>Αναζήτση</strong> με χρήστες </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <div class="container addc">
        <div class="row kt-margin-bottom-10-mobile mtop-20">
          <div class="col-3">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="Κωδικός Αποστολής"
                [(ngModel)]="newES.vg_code"
                name="vg_code"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε τοv
                <strong>Κωδικό Αποστολής</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div [hidden]="!adminView" class="col">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                [matAutocomplete]="_auto"
                #usrSel
                placeholder="Χρήστης"
                [(ngModel)]="newES.user"
              />
              <mat-autocomplete #_auto="matAutocomplete" [displayWith]="displayFn">
                <perfect-scrollbar>
                  <mat-option *ngFor="let option of _filteredOptions | async" [value]="option">
                    {{ option.id }}/{{ option.kap }} {{ option.lastname }} {{ option.firstname }} [
                    {{ option.email }} ]
                  </mat-option>
                </perfect-scrollbar>
              </mat-autocomplete>
              <mat-error
                >Η τιμή του πεδίου
                <strong>δεν είναι σωστή</strong>
              </mat-error>
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε τον
                <strong>Χρήστη</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div [hidden]="!adminView" class="col-2 fright ipos">
            <mat-slide-toggle [(ngModel)]="newES.is_return">Επιστροφή</mat-slide-toggle>
          </div>
          <div class="col-2">
            <button
              [disabled]="(dataSource.loading$ | async) || !newES.user"
              mat-raised-button
              class="sbutton fright"
              (click)="addNew()"
              color="primary"
              matTooltip="Προσθήκη Εξωτερικής Αποστολής"
            >
              Προσθήκη
            </button>
          </div>
        </div>
      </div>
      <div
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: selection.selected.length > 0 }"
      >
        <!-- We show 'Group Actions' div if smth are selected -->
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Επιλεγμένες εγγραφές: </span> {{ selection.selected.length }}
              </label>
            </div>
            <div class="kt-form__control kt-form__group--inline">
              <button
                (click)="deleteShipments()"
                color="warn"
                mat-raised-button
                matTooltip="Διαγραφή επιλεγμένων αποστολών"
                class="del-btn-l mat-button-mt-4"
              >
                <mat-icon>delete</mat-icon>
                Διαγραφή Επιλεγμένων
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__bottom">
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <mat-paginator
        [pageSize]="paginator.pageSize"
        [pageSizeOptions]="[10, 50, 100, 200, 500]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
    <div class="mat-table__wrapper" [class.adm-view]="adminView">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        matSortDisableClear
      >
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef>Χρήστης</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.user as usr">
              <a
                routerLink="/user-management/users/edit/{{ usr.id }}"
                *ngIf="!usr.is_company"
                matTooltip="
                                                                                  ID: {{
                  usr.id
                }}&#13;
                                                                                  Email: {{
                  usr.email
                }}&#13;
                                                                                  Τηλ: {{
                  usr.phone
                }}&#13;
                                                                                  Κιν: {{
                  usr.mobile
                }}&#13;
                                                                                  ΑΦΜ: {{
                  usr.vat
                }}&#13;
                                                                                  ΑΔΤ: {{ usr.adt }}
                                                                                  "
                [matTooltipClass]="'brk-tooltip'"
                >{{ usr.lastname }} {{ usr.firstname }}
              </a>
              <a
                routerLink="/user-management/users/edit/{{ usr.id }}"
                *ngIf="usr.is_company"
                matTooltip="
                                                                                  ID: {{
                  usr.id
                }}&#13;
                                                                                  Email: {{
                  usr.email
                }}&#13;
                                                                                  Τηλ: {{
                  usr.phone
                }}&#13;
                                                                                  Κιν: {{
                  usr.mobile
                }}&#13;
                                                                                  Εταιρία: {{
                  usr.companyName
                }}&#13;
                                                                                  ΑΦΜ Εταιρίας: {{
                  usr.companyVat
                }}&#13;
                                                                                  ΔΟΥ: {{
                  usr.companyFiscalService
                }}
                                                                                  "
                [matTooltipClass]="'brk-tooltip'"
                >{{ usr.lastname }} {{ usr.firstname }}
              </a>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Δημιουργήθηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_created | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_approved">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Εγκρίθηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_approved | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="_status">
          <mat-header-cell *matHeaderCellDef>Κατάσταση</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <div *ngIf="shipment._status === 'PENDING'" class="pending">
              <span class="status-icon"><i class="flaticon2-download"></i></span>
              <span class="status-text">Εκκρεμεί</span>
            </div>
            <div *ngIf="shipment._status === 'PROCESSED'" class="approved">
              <span class="status-icon"
                ><i class="flaticon2-black-back-closed-envelope-shape"></i
              ></span>
              <span class="status-text">Εγκρίθηκε</span>
            </div>
            <div *ngIf="shipment._status === 'NOT_MINE'" class="snm">
              <span class="status-icon"><i class="fa fa-question-circle"></i></span>
              <span class="status-text">Άγνωστη</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vg_code">
          <mat-header-cell *matHeaderCellDef>Κωδ. Αποστολής</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.vg_code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="is_return">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Επιστροφή</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.is_return" class="ready"><i class="fas fa-check"></i></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <button
              *ngIf="shipment._status === 'PENDING' || adminView"
              mat-icon-button
              color="warn"
              matTooltip="Διαγραφή Αποστολής"
              type="button"
              (click)="deleteShipment(shipment)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">Δεν βρέθηκαν εγγραφές</div>
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Παρακαλώ Περιμένετε
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
