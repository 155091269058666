<div *ngIf="!selection" class="container">
  <div class="row">
    <div class="col-md-4">
      <ul class="prices cod">
        <li class="header">Αντικαταβολές</li>
        <li class="amm">{{ total_cod | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-4">
      <ul class="prices cost">
        <li class="header">Χρεώσεις</li>
        <li class="amm">{{ total_charge | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-4">
      <ul class="prices diff">
        <li class="header">Προς Απόδοση</li>
        <li class="amm">{{ total_cod - total_charge | currency: "EUR" }}</li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="selection" class="container">
  <div class="row">
    <div class="col-md-2">
      <ul class="prices cod">
        <li class="header">Αντικαταβολές</li>
        <li class="amm">{{ total_cod | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-2">
      <ul class="prices cost">
        <li class="header">Χρεώσεις</li>
        <li class="amm">{{ total_charge | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-2">
      <ul class="prices diff">
        <li class="header">Προς Απόδοση</li>
        <li class="amm">{{ total_cod - total_charge | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-2">
      <ul class="prices cod">
        <li class="header">Αντικαταβολές</li>
        <li class="amm">{{ total_cod_inv | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-2">
      <ul class="prices cost">
        <li class="header">Χρεώσεις</li>
        <li class="amm">{{ total_charge_inv | currency: "EUR" }}</li>
      </ul>
    </div>
    <div class="col-md-2">
      <ul class="prices diff">
        <li class="header">Προς Απόδοση</li>
        <li class="amm">{{ total_cod_inv - total_charge_inv | currency: "EUR" }}</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 exp">
      *Τα αριστερά αθροίσματα συμπεριλαμβάνουν την κατάσταση Εισπράχτηκε στις αντικαταβολές και
      οποιαδήποτε κατάσταση στις χρεώσεις εκτός και αν είναι προπληρωμένη. Τα δεξιά αθροίσματα
      συμπεριλαμβάνουν μόνο καταστάσεις που μπορούν να αποτυπωθούν σε παραστατικά, δηλαδή μόνο Προς
      Απόδοση για τις αντικαταβολές και μόνο μη προπληρωμένες και σε κατάσταση Προς Απόδοση ή
      Εγρίθηκε για τις χρεώσεις.
    </div>
  </div>
</div>
