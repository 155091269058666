// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory

// Perfect Scroll bar
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
// SVG inline
//import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// Ronex
import { NotificationService } from './core/ronex';
import {
  ShipmentService,
  LiquidationService,
  KgCheckReportService,
  InvoiceCheckReportService,
  UserfileService,
  BlacklistService,
  ScriptService,
  ExternalShipmentService,
  UserwfdService,
} from './core/ronex';
// CRUD
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService,
} from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
import localeEl from '@angular/common/locales/el';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, isDevMode } from '@angular/core';
registerLocaleData(localeEl);

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

import {
  gconfigReducer,
  GConfigEffects,
  areaReducer,
  AreaEffects,
  areaGroupReducer,
  AreaGroupEffects,
} from './core/ronex/';

import { GConfigService } from './core/ronex/';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    //		InlineSVGModule.forRoot(),
    ThemeModule,
    StoreModule.forFeature('gconfig', gconfigReducer),
    EffectsModule.forFeature([GConfigEffects]),
    StoreModule.forFeature('areas', areaReducer),
    EffectsModule.forFeature([AreaGroupEffects]),
    StoreModule.forFeature('areaGroups', areaGroupReducer),
    EffectsModule.forFeature([AreaEffects]),
    NgbModule,
    StoreModule.forRoot({}, {}),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  exports: [],
  providers: [
    AuthService,
    ShipmentService,
    UserwfdService,
    ExternalShipmentService,
    BlacklistService,
    LiquidationService,
    KgCheckReportService,
    InvoiceCheckReportService,
    ScriptService,
    UserfileService,
    NotificationService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    { provide: LOCALE_ID, useValue: 'el-GR' },
    GConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
