import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Blacklist } from '../_models/blacklist.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Shipment } from '../_models/shipment.model';

@Injectable()
export class BlacklistService {
  constructor(private http: HttpClient) {}

  getAllBlacklists(): Observable<Blacklist[]> {
    return this.http.get<Blacklist[]>('api/shipments/blacklist/');
  }

  findBlacklists(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/shipments/blacklist/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  removeBlacklist(id: number): Observable<Shipment[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<Shipment[]>(`api/shipments/blacklist/${id}/remove`, {
      headers: httpHeaders,
    });
  }

  checkBlacklist(data: any): Observable<boolean> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<boolean>('api/shipments/blacklist/check/', data, {
      headers: httpHeaders,
    });
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
