<div class="row align-items-center">
  <!--div class="col-md-2 kt-margin-bottom-10-mobile">
	    <button ngfSelect multiple="1" type="file" [(files)]="files" [accept]="'*'" mat-raised-button color="primary" matTooltip="Προσθέστε Αρχεία">Εισαγωγή Αρχείων</button>
    </div-->
  <div class="col-md-12 kt-margin-bottom-10-mobile">
    <div
      class="my-drop-zone"
      ngfDrop
      selectable="1"
      multiple="1"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf, application/vnd.ms-excel"
      [(validDrag)]="validComboDrag"
      [(files)]="files"
      [(invalidDrag)]="invalidComboDrag"
      [ngClass]="{ 'valid-drag': validComboDrag, 'invalid-drag': invalidComboDrag }"
    >
      <div><i class="fas fa-upload uicon"></i></div>
      <div class="break"></div>
      Κάντε κλικ ή σύρτε τα αρχεία σας εδώ
    </div>
  </div>
  <div class="container cmb">
    <div class="row align-items-center">
      <ng-container *ngFor="let file of files; let odd = odd; let i = index">
        <div class="col-md-6 fcol">
          <mat-card class="fcard" [ngClass]="{ failedbg: (fbs | async)?.indexOf(file.name) > -1 }">
            <mat-progress-bar
              mode="determinate"
              *ngIf="progress[file.name]"
              [value]="progress[file.name] | async"
            ></mat-progress-bar>
            <div class="card-content">
              <i *ngIf="file.type.indexOf('pdf') > 0" class="far fa-file-pdf ficon pdficon"></i>
              <i
                *ngIf="file.type.indexOf('excel') > 0 || file.type.indexOf('spread') > 0"
                class="far fa-file-excel ficon excelicon"
              ></i>
              <div class="iline">
                <div>{{ file.name }}</div>
                <div>{{ file.size | fbytes }}</div>
              </div>
              <i class="rmv fas fa-trash" *ngIf="!progress[file.name]" (click)="remove(i)"></i>
              <div *ngIf="(fbs | async)?.indexOf(file.name) > -1" class="fltext">Απέτυχε</div>
            </div>
          </mat-card>
        </div>
        <div class="w-100" *ngIf="odd"></div>
      </ng-container>
    </div>
    <div *ngIf="files.length > 0" class="row justify-content-end">
      <div class="col-md-2 align-self-end">
        <button
          mat-raised-button
          class="sbutton"
          (click)="uploadAll()"
          color="primary"
          matTooltip="Αποστολή αρχείων στον server"
        >
          Αποστολή
        </button>
      </div>
    </div>
  </div>
</div>
