// Angular
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Subject, Observable, of, Subscription } from 'rxjs';
import { take, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../../core/reducers';

import { User, currentUser } from '../../../../../core/auth';
import { SubheaderService } from '../../../../../core/_base/layout';

@Component({
  selector: 'kt-user-files',
  templateUrl: './user-files.component.html',
  styleUrls: ['./user-files.component.scss'],
})
export class UserFilesComponent implements OnInit, OnDestroy {
  curUser: Observable<User>;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private subheaderService: SubheaderService,
  ) {}

  ngOnInit() {
    this.curUser = this.store.pipe(select(currentUser));
    this.subheaderService.setTitle('Διαχείριση Αρχείων');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
