// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ExternalShipmentState } from '../_reducers/external_shipment.reducers';
import { each } from 'lodash';
import { ExternalShipment } from '../_models/external_shipment.model';

export const selectExternalShipmentState =
  createFeatureSelector<ExternalShipmentState>('external_shipments');

export const selectExternalShipmentById = (id: number) =>
  createSelector(
    selectExternalShipmentState,
    (external_shipmentState) => external_shipmentState.entities[id],
  );

export const selectExternalShipmentPageLoading = createSelector(
  selectExternalShipmentState,
  (external_shipmentState) => {
    return external_shipmentState.listLoading;
  },
);

export const selectExternalShipmentActionLoading = createSelector(
  selectExternalShipmentState,
  (external_shipmentState) => external_shipmentState.actionsloading,
);

export const selectExternalShipmentPageLastQuery = createSelector(
  selectExternalShipmentState,
  (external_shipmentState) => external_shipmentState.lastQuery,
);

export const selectExternalShipmentsInStore = createSelector(
  selectExternalShipmentState,
  (external_shipmentState) => {
    const items: ExternalShipment[] = [];
    each(external_shipmentState.ids, (id: number) => {
      items.push(external_shipmentState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ExternalShipment[] = httpExtension.sortArray(
      items,
      external_shipmentState.lastQuery.sortField,
      external_shipmentState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, external_shipmentState.totalCount, '');
  },
);

export const selectExternalShipmentShowInitWaitingMessage = createSelector(
  selectExternalShipmentState,
  (external_shipmentState) => external_shipmentState.showInitWaitingMessage,
);

export const selectHasExternalShipmentsInStore = createSelector(
  selectExternalShipmentState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
