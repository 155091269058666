<kt-alert
  *ngIf="serverError | async as err"
  type="warn"
  [showCloseButton]="true"
  [duration]="10000"
  (close)="onAlertClose($event)"
>
  {{ err }}
</kt-alert>
<div class="row">
  <div class="col">
    <mat-card [ngClass]="'fw'">
      <mat-card-title>Μήνυμα Χαιρετισμού</mat-card-title>
      <mat-card-subtitle
        >Το μήνυμα χαιρετισμού που βλέπουν οι χρήστες στον Πίνακα Ελέγχου τους</mat-card-subtitle
      >
      <mat-card-content>
        <ckeditor [editor]="Editor" [(ngModel)]="im.value" [config]="{ toolbar: tb }"></ckeditor>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card [ngClass]="'fw'">
      <mat-card-title>Πληροφορίες για την RoNex</mat-card-title>
      <mat-card-subtitle
        >Γενικές πληροφορίες σχετικά με την RoNex και τον ιστότοπο</mat-card-subtitle
      >
      <mat-card-content>
        <ckeditor [editor]="Editor" [(ngModel)]="about.value" [config]="{ toolbar: tb }"></ckeditor>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card [ngClass]="'fw'">
      <mat-card-title>Πληροφορίες Επικοινωνίας</mat-card-title>
      <mat-card-subtitle>Πληροφορίες για τον τρόπο επικοινωνίας των πελατών.</mat-card-subtitle>
      <mat-card-content>
        <ckeditor
          [editor]="Editor"
          [(ngModel)]="contact.value"
          [config]="{ toolbar: tb }"
        ></ckeditor>
      </mat-card-content>
    </mat-card>
  </div>
</div>
