// Angular
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Subject, Observable, of, Subscription } from 'rxjs';
import { take, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../../core/reducers';

import { SubheaderService } from '../../../../core/_base/layout';

@Component({
  selector: 'kt-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.scss'],
})
export class ApiSettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  saveSubject: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private subheaderService: SubheaderService,
  ) {}

  ngOnInit() {
    this.subheaderService.setTitle('Ρυθμίσεις API');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
