// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Userfile } from '../_models/userfile.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum UserfileActionTypes {
  UserfilesPageRequested = '[Userfiles List Page] Userfiles Page Requested',
  UserfilesPageLoaded = '[Userfiles API] Userfiles Page Loaded',
  UserfilesPageCancelled = '[Userfiles API] Userfiles Page Cancelled',
  UserfilesPageToggleLoading = '[Userfiles] Userfiles Page Toggle Loading',
  UserfilesActionToggleLoading = '[Userfiles] Userfiles Action Toggle Loading',
  UserfileCreate = '[Userfiles API] Create Userfile',
  UserfileUpsert = '[Userfiles] Upsert Userfile',
  FileUploadProgress = '[Userfiles API] Upload Progress',
  UploadSuccess = '[Userfiles API] Upload Success',
  UploadFailure = '[Userfiles API] Upload Failure',
  UFActionSuccess = '[Userfiles] Last action success',
  UFActionFail = '[Userfiles] Last action failed',
  UFActionRefresh = '[Userfiles] Userfiles Page Refresh due to action',
  UserfileRemove = '[Userfiles] Remove',
  UserfileDelete = '[Userfiles API] Userfile Delete',
  UserfileMassDelete = '[Userfile API] Userfile Delete Many',
  UserfileMassRemove = '[Userfile] Remove Many',
}

export class UFActionRefresh implements Action {
  readonly type = UserfileActionTypes.UFActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class UFActionSuccess implements Action {
  readonly type = UserfileActionTypes.UFActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class UFActionFail implements Action {
  readonly type = UserfileActionTypes.UFActionFail;
  constructor(public payload: { result: any }) {}
}

export class UserfileDelete implements Action {
  readonly type = UserfileActionTypes.UserfileDelete;
  constructor(public payload: { userfile: Userfile }) {}
}

export class UserfileMassDelete implements Action {
  readonly type = UserfileActionTypes.UserfileMassDelete;
  constructor(public payload: { ids: Array<number> }) {}
}

export class UserfileMassRemove implements Action {
  readonly type = UserfileActionTypes.UserfileMassRemove;
  constructor(public payload: { ids: Array<number> }) {}
}

export class UserfileRemove implements Action {
  readonly type = UserfileActionTypes.UserfileRemove;
  constructor(public payload: { userfile: Userfile }) {}
}

export class UploadSuccess implements Action {
  readonly type = UserfileActionTypes.UploadSuccess;
  constructor(public payload: { userfile: File }) {}
}
export class UploadFailure implements Action {
  readonly type = UserfileActionTypes.UploadFailure;
  constructor(public payload: { userfile: File }) {}
}
export class FileUploadProgress implements Action {
  readonly type = UserfileActionTypes.FileUploadProgress;
  constructor(public payload: { userfile: File; progress: number }) {}
}

export class UserfileUpsert implements Action {
  readonly type = UserfileActionTypes.UserfileUpsert;
  constructor(public payload: { userfile: Userfile }) {}
}

export class UserfileCreate implements Action {
  readonly type = UserfileActionTypes.UserfileCreate;
  constructor(public payload: { userfile: File; uid: number }) {}
}

export class UserfilesPageRequested implements Action {
  readonly type = UserfileActionTypes.UserfilesPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class UserfilesPageLoaded implements Action {
  readonly type = UserfileActionTypes.UserfilesPageLoaded;
  constructor(
    public payload: {
      userfiles: Userfile[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class UserfilesPageCancelled implements Action {
  readonly type = UserfileActionTypes.UserfilesPageCancelled;
}

export class UserfilesPageToggleLoading implements Action {
  readonly type = UserfileActionTypes.UserfilesPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class UserfilesActionToggleLoading implements Action {
  readonly type = UserfileActionTypes.UserfilesActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}
export type UserfileActions =
  | UserfilesPageLoaded
  | UserfilesPageCancelled
  | UserfilesPageToggleLoading
  | UserfilesPageRequested
  | UserfilesActionToggleLoading
  | UserfileCreate
  | UserfileUpsert
  | UploadSuccess
  | UFActionFail
  | UFActionSuccess
  | UFActionRefresh
  | UserfileDelete
  | UserfileRemove
  | UserfileMassDelete
  | UserfileMassRemove
  | UploadFailure;
