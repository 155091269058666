// NGRX
import { createSelector } from '@ngrx/store';
// Lodash

// Selectors

export const selectAuthState = (state) => state.auth;

export const isLoggedIn = createSelector(
  selectAuthState,
  (auth) => auth.loggedIn,
);

export const isLoggedOut = createSelector(isLoggedIn, (loggedIn) => !loggedIn);

export const isStaff = createSelector(selectAuthState, (auth) => {
  if (auth.user) {
    return auth.user.is_staff;
  }
  return undefined;
});

export const canEditProfile = createSelector(selectAuthState, (auth) => {
  if (auth.user) {
    return auth.user.can_edit_profile;
  }
  return undefined;
});

export const currentAuthToken = createSelector(
  selectAuthState,
  (auth) => auth.authToken,
);

export const isUserLoaded = createSelector(
  selectAuthState,
  (auth) => auth.isUserLoaded,
);

export const currentUser = createSelector(selectAuthState, (auth) => auth.user);

export const currentUserRoleIds = createSelector(currentUser, (user) => {
  if (!user) {
    return [];
  }

  return user.roles;
});
