<ng-container>
  <div class="row align-items-center">
    <div class="col-md-12 kt-margin-bottom-10-mobile">
      <kt-alert
        *ngIf="serverError | async as err"
        type="warn"
        [showCloseButton]="true"
        [duration]="10000"
        (close)="onAlertClose($event)"
      >
        {{ err }}
      </kt-alert>
    </div>
  </div>
  <div class="kt-form kt-margin-b-30">
    <div class="kt-form__filtration">
      <div class="row align-items-center">
        <div class="col-md-2 kt-margin-bottom-10-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Αναζήτηση Αρχείου" #searchInput placeholder="Αναζήτηση" />
            <mat-hint align="start"> <strong>Αναζήτηση</strong> με όνομα. </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
      [ngClass]="{ show: selection.selected.length > 0 }"
    >
      <div class="col-xl-12">
        <div class="kt-form__group kt-form__group--inline">
          <div class="kt-form__label kt-form__label-no-wrap">
            <label class="kt--font-bold kt-font-danger-">
              <span>Επιλεγμένες εγγραφές: </span> {{ selection.selected.length }}
            </label>
          </div>
          <div class="kt-form__control kt-form__group--inline">
            <button
              *ngIf="adminView"
              (click)="deleteUserfiles()"
              color="warn"
              mat-raised-button
              matTooltip="Διαγραφή επιλεγμένων αρχείων"
              class="del-btn-l mat-button-mt-4"
            >
              <mat-icon>delete</mat-icon>
              Διαγραφή Επιλεγμένων
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-table__wrapper" [class.adm-view]="adminView">
    <mat-table
      class="lmat-elevation-z8"
      #table
      [dataSource]="dataSource"
      matSort
      #sort1="matSort"
      matSortActive="date_created"
      matSortDirection="desc"
      matSortDisableClear
    >
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-checkbox">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Όνομα Αρχείου</mat-header-cell>
        <mat-cell *matCellDef="let userfile"> {{ userfile.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Δημιουργήθηκε</mat-header-cell>
        <mat-cell *matCellDef="let userfile">{{ userfile.date_created | date }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
        <mat-cell *matCellDef="let userfile">
          <button
            mat-icon-button
            color="primary"
            matTooltip="Μεταφόρτωση Αρχείου"
            type="button"
            (click)="downloadUserfile(userfile)"
          >
            <mat-icon>cloud_download</mat-icon>
          </button>
          <button
            *ngIf="adminView"
            mat-icon-button
            color="warn"
            matTooltip="Διαγραφή Αρχείου"
            type="button"
            (click)="deleteUserfile(userfile)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="mat-table__message" *ngIf="!dataSource.hasItems">Δεν βρέθηκαν εγγραφές</div>
    <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
      Παρακαώ περιμένετε...
    </div>
  </div>

  <div class="mat-table__bottom">
    <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[3, 5, 10, 50, 100, 200]"
      [length]="dataSource.paginatorTotal$ | async"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</ng-container>
