// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { UserfileState } from '../_reducers/userfile.reducers';
import { each } from 'lodash';
import { Userfile } from '../_models/userfile.model';

export const selectUserfilesState =
  createFeatureSelector<UserfileState>('userfiles');

export const selectUserfileById = (userfileId: number) =>
  createSelector(
    selectUserfilesState,
    (userfilesState) => userfilesState.entities[userfileId],
  );

export const selectUserfilesPageLoading = createSelector(
  selectUserfilesState,
  (userfilesState) => {
    return userfilesState.listLoading;
  },
);

export const selectUserfilesActionLoading = createSelector(
  selectUserfilesState,
  (userfilesState) => userfilesState.actionsloading,
);

export const selectLastCreatedUserfileId = createSelector(
  selectUserfilesState,
  (userfilesState) => userfilesState.lastCreatedUserfileId,
);

export const selectUserfilesPageLastQuery = createSelector(
  selectUserfilesState,
  (userfilesState) => userfilesState.lastQuery,
);

export const selectUserfilesInStore = createSelector(
  selectUserfilesState,
  (userfilesState) => {
    const items: Userfile[] = [];
    each(userfilesState.ids, (id: number) => {
      items.push(userfilesState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: Userfile[] = httpExtension.sortArray(
      items,
      userfilesState.lastQuery.sortField,
      userfilesState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, userfilesState.totalCount, '');
  },
);

export const selectUserfilesShowInitWaitingMessage = createSelector(
  selectUserfilesState,
  (userfilesState) => userfilesState.showInitWaitingMessage,
);

export const selectHasUserfilesInStore = createSelector(
  selectUserfilesState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
