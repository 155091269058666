// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/pages/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/views/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('app/views/pages/usr-mngt/usr-mngt.module').then(
            (m) => m.UsrMngtModule,
          ),
      },
      {
        path: 'shipment-management',
        loadChildren: () =>
          import('app/views/pages/shipments/shipments.module').then(
            (m) => m.ShipmentsModule,
          ),
      },
      {
        path: 'liquidations',
        loadChildren: () =>
          import('app/views/pages/liquidations/liquidations.module').then(
            (m) => m.LiquidationsModule,
          ),
      },
      {
        path: 'courier_areas',
        loadChildren: () =>
          import('app/views/pages/areas/areas.module').then(
            (m) => m.AreasModule,
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('app/views/pages/reports/reports.module').then(
            (m) => m.ReportsModule,
          ),
      },
      {
        path: 'ui',
        loadChildren: () =>
          import('app/views/pages/user-interface/user-interface.module').then(
            (m) => m.UserInterfaceModule,
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/views/pages/admin-settings/admin-settings.module').then(
            (m) => m.AdminSettingsModule,
          ),
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
