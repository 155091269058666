<kt-portlet>
  <kt-portlet-header
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="loading$"
  >
    <ng-container ktPortletTools>
      <a
        *ngIf="!profile"
        [routerLink]="['../../']"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Back to the users list"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Πίσω</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-secondary kt-margin-r-10"
        (click)="reset()"
        [disabled]="selectedTab !== 0"
        mat-raised-button
        matTooltip="Απαλοιφή Αλλαγών"
      >
        <i class="la la-cog"></i>
        <span class="kt-hidden-mobile">Απαλοιφή</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="onSumbit(false)"
        mat-raised-button
        matTooltip="Αποθήκευση και συνέχεια"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση</span>
      </a>
      <button *ngIf="!profile" mat-icon-button [matMenuTriggerFor]="menu" color="primary">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item color="primary" (click)="onSumbit(true)">Αποθήκευςη & Έξοδος</button>
        <button mat-menu-item color="primary" (click)="onSumbit(false)">
          Αποθήκευση & Συνέχεια
        </button>
      </mat-menu>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <mat-tab-group [(selectedIndex)]="selectedTab">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fa fa-user"></i>
          Βασικές Πληροφορίες
        </ng-template>
        <ng-template matTabContent>
          <!--begin::Form-->
          <div *ngIf="user">
            <form [formGroup]="userForm" class="kt-form kt-form--group-seperator-dashed">
              <kt-alert
                *ngIf="hasFormErrors"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                Οι πληροφορίες που εισάγατε δεν είναι σωστές. Παρακαλώ ξαναπροσπαθήστε.
              </kt-alert>
              <kt-alert
                *ngIf="dupEmail | async as err"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                {{ err }}
              </kt-alert>
              <div class="kt-form__section kt-form__section--first">
                <h1 class="tt mt-4 mb-2">Γενικές Ρυθμίσεις</h1>
                <div class="form-group kt-form__group row">
                  <div
                    *ngIf="!profile && user.is_active != null && user.is_active !== undefined"
                    class="col kt-margin-bottom-20-mobile my-2"
                  >
                    <mat-slide-toggle formControlName="is_active"
                      >Ο χρήστης είναι ενεργός</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="is_company"
                      >Ο λογαριασμός ανοίκει σε εταιρία</mat-slide-toggle
                    >
                  </div>
                  <div class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="bank_payments"
                      >Πληρωμές σε τραπεζικό λογαριασμό</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="can_edit_profile"
                      >Έχει δικαίωμα επεξεργασίας προφίλ</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="has_es"
                      >Εξωτερικές Αποστολές</mat-slide-toggle
                    >
                  </div>
                  <div class="col kt-margin-bottom-20-mobile d-flex">
                    <mat-slide-toggle formControlName="print_comments"
                      >Εκτύπωση Σχολίων</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="ignore_area_groups"
                      >Αγνοεί την αναδρομολόγηση περιοχών</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="allow_add_by_xls"
                      >Προσθήκη αποστολών με XLS</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="return_cod_charge"
                      >Επιστροφή τέλους αντ/βολής για επιστροφές</mat-slide-toggle
                    >
                  </div>
                  <div *ngIf="!profile" class="col kt-margin-bottom-20-mobile">
                    <mat-slide-toggle formControlName="modify_voucher"
                      >Διαμεταφορέας στο Voucher</mat-slide-toggle
                    >
                  </div>
                </div>
                <div class="form-group kt-form__group row">
                  <div
                    *ngIf="!profile"
                    class="col kt-margin-bottom-20-mobile d-flex align-items-center"
                  >
                    <div class="mat-form-field-fluid pr-4">
                      <label>Εντολές Παραλαβής: </label>&nbsp;
                      <mat-button-toggle-group
                        class="my-1"
                        name="puO"
                        #puO="matButtonToggleGroup"
                        formControlName="pickup_rights"
                        multiple="false"
                        aria-label="Pickup Orders"
                      >
                        <mat-button-toggle [value]="0">Για καμία αποστολή</mat-button-toggle>
                        <mat-button-toggle [value]="1">Μόνο για επιστροφικές</mat-button-toggle>
                        <mat-button-toggle [value]="2">Για όλες τις αποστολές</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!profile">
                  <h1 class="tt mt-4 mb-2">Επιλογή Courier</h1>
                  <div class="form-group kt-form__group row p-5">
                    <div
                      class="img-button"
                      (click)="setCourier('ELTA')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'ELTA' }"
                    >
                      <img class="img-button-logo" src="/assets/img/elta-logo.jpg" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('TAXYDEMA')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'TAXYDEMA' }"
                    >
                      <img class="img-button-logo" src="/assets/img/taxydema.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('DIAKINISIS')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'DIAKINISIS' }"
                    >
                      <img class="img-button-logo" src="/assets/img/diakinisis.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('GENIKI')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'GENIKI' }"
                    >
                      <img class="img-button-logo" src="/assets/img/geniki.jpg" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('ACS1')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'ACS1' }"
                    >
                      <img class="img-button-logo" src="/assets/img/acs1.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('ACS2')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'ACS2' }"
                    >
                      <img class="img-button-logo" src="/assets/img/acs2.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('ACS3')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'ACS3' }"
                    >
                      <img class="img-button-logo" src="/assets/img/acs3.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('SPEEDEX')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'SPEEDEX' }"
                    >
                      <img class="img-button-logo" src="/assets/img/speedex-logo.png" />
                    </div>
                    <div
                      class="img-button"
                      (click)="setCourier('COURIER_CENTER')"
                      [ngClass]="{ selected: userForm.controls.courier.value === 'COURIER_CENTER' }"
                    >
                      <img class="img-button-logo" src="/assets/img/courier-center.jpg" />
                    </div>
                  </div>
                </ng-container>
                <h1 class="tt mt-4 mb-2">Στοιχεία Χρήστη</h1>
                <div class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Όνομα" formControlName="firstname" />
                      <mat-error
                        >Το όνομα είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εισάγετε το
                        <strong>Όνομα</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Επώνυμο" formControlName="lastname" />
                      <mat-error
                        >Το επώνυμο είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εισάγετε το
                        <strong>Επώνυμο</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input type="email" matInput placeholder="Email" formControlName="email" />
                      <mat-error
                        >Το Email είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εισάγετε το
                        <strong>Email</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Τηλέφωνο" formControlName="phone" />
                      <mat-hint align="start"
                        >Εισάγετε το
                        <strong>Τηλέφωνο</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Κινητό Τηλέφωνο" formControlName="mobile" />
                      <mat-hint align="start"
                        >Εισάγετε το <strong>Κινητό</strong> σας τηλέφωνο
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Α.Φ.Μ." formControlName="vat" />
                      <mat-hint align="start">Εισάγετε τον <strong>Α.Φ.Μ.</strong> σας </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Αριθμός Δελτίου Ταυτότητας"
                        formControlName="adt"
                      />
                      <mat-hint align="start"
                        >Παρακαλώ εισάγετε τον <strong>Α.Δ.</strong> της αστυνομικής σας ταυτότητας
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="userForm.controls.bank_payments.value"
                    class="col-lg-4 kt-margin-bottom-20-mobile"
                  >
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Τράπεζα" formControlName="bank" />
                      <mat-hint align="start"
                        >Εισάγετε το όνομα της <strong>Τράπεζας</strong> σας
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="userForm.controls.bank_payments.value"
                    class="col-lg-4 kt-margin-bottom-20-mobile"
                  >
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="IBAN" formControlName="iban" />
                      <mat-hint align="start"
                        >Εισάγετε το <strong>ΙΒΑΝ</strong> του λογαριαμού σας
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="!profile && !userForm.controls.bank_payments.value"
                    class="col-lg-4 kt-margin-bottom-20-mobile"
                  >
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: per_mask }"
                        placeholder="ΦΠΑ"
                        formControlName="vat_val"
                      />
                      <mat-error
                        >Η τιμή που δώσατε
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εισάγετε το <strong>ΦΠΑ</strong> του χρήστη
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="!profile && !userForm.controls.bank_payments.value"
                    class="col-lg-4 kt-margin-bottom-20-mobile"
                  >
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Κωδικός Αριθμός Πελάτη" formControlName="kap" />
                      <mat-hint align="start"
                        >Εισάγετε τον
                        <strong>Κωδικό Αριθμό Πελάτη</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div
                  *ngIf="!profile && userForm.controls.bank_payments.value"
                  class="form-group kt-form__group row"
                >
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: per_mask }"
                        placeholder="ΦΠΑ"
                        formControlName="vat_val"
                      />
                      <mat-error
                        >Η τιμή που δώσατε
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εισάγετε το <strong>ΦΠΑ</strong> του χρήστη
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Κωδικός Αριθμός Πελάτη" formControlName="kap" />
                      <mat-hint align="start"
                        >Εισάγετε τον
                        <strong>Κωδικό Αριθμό Πελάτη</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--end::Form-->
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="!user || !user.id">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fa fa-address-book"></i>
          Διεύθυνση
        </ng-template>
        <ng-template matTabContent>
          <kt-address [(addressSubject)]="addressSubject"></kt-address>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="!user || !user.id || !user.is_company">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-building"></i>
          Πληροφορίες Εταιρίας
        </ng-template>
        <ng-template matTabContent>
          <kt-company [(companySubject)]="companySubject"></kt-company>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="user" [disabled]="!user || !user.id">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fa fa-exchange-alt"></i>
          Αλλαγή Κωδικού
        </ng-template>
        <ng-template matTabContent>
          <kt-change-password [userId]="user.id"></kt-change-password>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="!profile" [disabled]="!user || !user.id">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-folder"></i>
          Αρχεία
        </ng-template>
        <ng-template matTabContent>
          <kt-usr-files *ngIf="user && user.id" [user]="user"></kt-usr-files>
          <kt-user-files-list *ngIf="user && user.id" [user]="user"></kt-user-files-list>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="!profile" [disabled]="!user || !user.id">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-euro-sign"></i>
          Χρεώσεις
        </ng-template>
        <ng-template matTabContent>
          <kt-charges
            *ngIf="user && user.id"
            [user]="user"
            [(chargeSettingsSubject)]="chargeSettingsSubject"
          ></kt-charges>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="!profile" [disabled]="!user || !user.id">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-euro-sign"></i>
          Χρεώσεις ανα Courier
        </ng-template>
        <ng-template matTabContent>
          <kt-cscharges *ngIf="user && user.id" [user]="user"></kt-cscharges>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="profile">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-key"></i>
          API Keys
        </ng-template>
        <ng-template matTabContent>
          <kt-api-keys></kt-api-keys>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </kt-portlet-body>
</kt-portlet>
