// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  NotificationActions,
  NotificationActionTypes,
} from '../_actions/notification.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Notification } from '../_models/notification.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NotificationState extends EntityState<Notification> {}

export const adapter: EntityAdapter<Notification> =
  createEntityAdapter<Notification>();

export const initialNotificationState: NotificationState =
  adapter.getInitialState({});

export function notificationReducer(
  state = initialNotificationState,
  action: NotificationActions,
): NotificationState {
  switch (action.type) {
    case NotificationActionTypes.NotificationCreated:
      return adapter.addOne(action.payload.notification, {
        ...state,
      });
    case NotificationActionTypes.NotificationsLoaded: {
      return adapter.setAll(action.payload.notifications, {
        ...initialNotificationState,
      });
    }
    default:
      return state;
  }
}

export const getNotificationState =
  createFeatureSelector<NotificationState>('notifications');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
