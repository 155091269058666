// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
// LODASH
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';

// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';

// Models and selectors
import {
  Blacklist,
  BlacklistActionTypes,
  BlacklistDataSource,
  BlacklistDelete,
  BlacklistPageRequested,
  BLActionFail,
  BLActionRefresh,
  BLActionSuccess,
  SetListFilters,
} from '../../../../../core/ronex';

import { HttpClient } from '@angular/common/http';
import { SubheaderService } from '../../../../../core/_base/layout';

@Component({
  selector: 'kt-blacklist-list',
  templateUrl: './blacklist-list.component.html',
  styleUrls: ['./blacklist-list.component.scss'],
})
export class BlacklistListComponent implements OnInit, OnDestroy {
  dataSource: BlacklistDataSource;
  displayedColumns = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInputKA', { static: true }) searchInputKA: ElementRef;
  @ViewChild('searchInputPAR', { static: true }) searchInputPAR: ElementRef;
  @ViewChild('searchInputADD', { static: true }) searchInputADD: ElementRef;
  @ViewChild('searchInputTK', { static: true }) searchInputTK: ElementRef;
  @ViewChild('searchInputPHONE', { static: true }) searchInputPHONE: ElementRef;

  lastQuery: QueryParamsModel;
  // Selection
  blacklistResult: Blacklist[] = [];

  serverError: Subject<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private actions$: Actions,
    private dp: DatePipe,
  ) {}

  ngOnInit() {
    this.displayedColumns = [
      'name',
      'address',
      'postcode',
      'phone',
      'phone2',
      'actions',
    ];

    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.actions$
      .pipe(
        ofType<BLActionSuccess>(BlacklistActionTypes.BLActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Delete,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<BLActionFail>(BlacklistActionTypes.BLActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    const arf = this.actions$
      .pipe(
        ofType<BLActionRefresh>(BlacklistActionTypes.BLActionRefresh),
        tap(({ payload }) => {
          this.loadBlacklistList();
        }),
      )
      .subscribe();
    this.subscriptions.push(arf);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadBlacklistList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = merge(
      fromEvent(this.searchInputKA.nativeElement, 'keyup'),
      fromEvent(this.searchInputPAR.nativeElement, 'keyup'),
      fromEvent(this.searchInputADD.nativeElement, 'keyup'),
      fromEvent(this.searchInputTK.nativeElement, 'keyup'),
      fromEvent(this.searchInputPHONE.nativeElement, 'keyup'),
    )
      .pipe(
        debounceTime(250),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadBlacklistList();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Blacklist');

    this.dataSource = new BlacklistDataSource(this.store);
    // Init DataSource
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.blacklistResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 50;
    this.loadBlacklistList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  refs(): void {
    this.paginator.pageIndex = 0;
    this.loadBlacklistList();
  }

  loadBlacklistList() {
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    this.store.dispatch(new BlacklistPageRequested({ page: queryParams }));
  }

  filterConfiguration(): any {
    const filter: any = {};

    filter.vg_code = this.searchInputKA.nativeElement.value;
    filter.name = this.searchInputPAR.nativeElement.value;
    filter.address = this.searchInputADD.nativeElement.value;
    filter.tk = this.searchInputTK.nativeElement.value;
    filter.phone = this.searchInputPHONE.nativeElement.value;

    return filter;
  }

  deleteBlacklist(_item: Blacklist) {
    const _title = 'Διαγραφή Εγγραφής';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε την εγγραφή';
    const _waitDesciption = 'Η εγγραφή διαγράφεται ...';
    const _deleteMessage = `Η εγγραφή έχει διαγραφεί`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new BlacklistDelete({ blacklist: _item }));
    });
  }

  viewShipments(bl: Blacklist) {
    const codes: Array<string> = [];
    bl.shipments.forEach((sh) => codes.push(sh.vg_code));
    this.store.dispatch(
      new SetListFilters({
        filters: {
          filter: {
            vg_codes: codes,
          },
          sortOrder: 'desc',
          sortField: 'date_created',
          pageNumber: 0,
          pageSize: 50,
          for_staff: true,
        },
      }),
    );
    this.router.navigateByUrl('/shipment-management/admin/shipments');
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
