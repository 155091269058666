import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { InvoiceCheckReport } from '../_models/invoice_check_report.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class InvoiceCheckReportService {
  constructor(private http: HttpClient) {}

  getAllInvoiceCheckReports(): Observable<InvoiceCheckReport[]> {
    return this.http.get<InvoiceCheckReport[]>(
      'api/reports/invoice_check_reports/',
    );
  }

  findInvoiceCheckReports(
    queryParams: QueryParamsModel,
  ): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/reports/invoice_check_reports/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
