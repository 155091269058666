<kt-portlet>
  <kt-portlet-header
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="loading$"
  >
    <ng-container ktPortletTools>
      <a
        *ngIf="adminView"
        routerLink="/shipment-management/admin/shipments"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Πίσω στις αποστολές"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Πίσω</span>
      </a>
      <a
        *ngIf="!adminView"
        routerLink="/shipment-management/shipments"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Πίσω στις αποστολές"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Πίσω</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-secondary kt-margin-r-10"
        (click)="reset()"
        [disabled]="selectedTab !== 0"
        mat-raised-button
        matTooltip="Απαλοιφή αλλαγών"
      >
        <i class="la la-cog"></i>
        <span class="kt-hidden-mobile">Απαλοιφή</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="onSumbit(false)"
        mat-raised-button
        matTooltip="Αποθήκευση και συνέχεια"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση</span>
      </a>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div *ngIf="shipment">
      <form [formGroup]="shipmentForm" class="kt-form kt-form--group-seperator-dashed">
        <kt-alert
          *ngIf="hasFormErrors"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
        </kt-alert>
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
        <div class="kt-form__section kt-form__section--first">
          <div class="form-group kt-form__group row">
            <div class="col-lg-3 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  class="right-align"
                  placeholder="Αριθμός αντικειμένων (1 εώς 15)"
                  formControlName="items"
                />
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε τον <strong>Αριθμό Τεμαχίων</strong> της Αποστολής
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-3 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [textMask]="{ mask: kg_mask }"
                  placeholder="Βάρος Αποστολής ( 00.00 Kg )"
                  class="right-align"
                  formControlName="weight"
                />
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε το
                  <strong>Βάρος</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col plr jtc pb0">
              <p>
                <strong>Ογκομετρικό Βάρος : </strong>Υπολογίζεται με τον κανόνα
                <strong>Μήκος x Πλάτος x Ύψος / 5000</strong>. Η μονάδα μέτρησης είναι τα εκατοστά /
                cm. <strong>Αν υπερβαίνει το φυσικός βάρος θα χρησιμοποιηθεί αντ' αυτού</strong>.
              </p>
            </div>
          </div>
          <div class="form-group kt-form__group row pt0">
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [textMask]="{ mask: cm_mask }"
                  placeholder="Μήκος Πακέτου ( 00.00 cm )"
                  class="right-align"
                  formControlName="size_x"
                />
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ εισάγετε το
                  <strong>Μήκος</strong>
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [textMask]="{ mask: cm_mask }"
                  placeholder="Ύψος Πακέτου ( 00.00 cm )"
                  class="right-align"
                  formControlName="size_y"
                />
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ εισάγετε το
                  <strong>'Υψος</strong>
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [textMask]="{ mask: cm_mask }"
                  placeholder="Πλάτος Πακέτου ( 00.00 cm )"
                  class="right-align"
                  formControlName="size_z"
                />
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ εισάγετε το
                  <strong>Πλάτος</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group kt-form__group row">
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Όνοματεπώνυμο Αποστολέα"
                  formControlName="sender_name"
                />
                <mat-error
                  >Το πεδίο αυτό είναι
                  <strong>απαραίτητο</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε το
                  <strong>Ονοματεπώνυμο</strong>
                  του αποστολέα.
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Διεύθυνση Αποστολέα"
                  formControlName="sender_address"
                />
                <mat-error
                  >Το πεδίο αυτό είναι
                  <strong>απαραίτητο</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε την <strong>Διεύθυνση</strong> του αποστολέα.
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Ταχυδρομικός Κώδικας"
                  formControlName="sender_postcode"
                />
                <mat-error
                  >Το πεδίο αυτό είναι
                  <strong>απαραίτητο</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε τον <strong>Τ.Κ.</strong> του αποστολέα.
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group kt-form__group row">
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Περιοχή Αποστολέα" formControlName="sender_area" />
                <mat-error
                  >Το πεδίο αυτό είναι
                  <strong>απαραίτητο</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε την
                  <strong>Πόλη ή Νομό</strong>
                  του αποστολέα.
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Τηλέφωνο Αποστολέα" formControlName="sender_phone" />
                <mat-error
                  >Το πεδίο αυτό είναι
                  <strong>απαραίτητο</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε το <strong>Τηλέφωνο</strong> του αποστολέα.
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="adminView" class="form-group kt-form__group row">
            <div class="col-lg-6 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [matAutocomplete]="auto"
                  placeholder="Χρήστης"
                  formControlName="user"
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-error
                  >Η τιμή του πεδίου
                  <strong>δεν είναι σωστή</strong>
                </mat-error>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε τον
                  <strong>Χρήστη</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group kt-form__group row">
            <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <textarea
                  matInput
                  placeholder="Παρατηρήσεις"
                  formControlName="user_comments"
                ></textarea>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε τις
                  <strong>Παρατηρήσεις σας</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group kt-form__group row">
            <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
              <mat-form-field class="mat-form-field-fluid">
                <textarea matInput placeholder="Σχόλια" formControlName="sh_comments"></textarea>
                <mat-hint align="start"
                  >Παρακαλώ ειστάγετε τα
                  <strong>Σχόλια σας</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>
</kt-portlet>
