<!--begin::Head-->
<div class="kt-login__head">
  <span class="kt-login__signup-label">Δέν έχετε ακόμα λογαριασμό;</span>&nbsp;&nbsp;
  <a href="javascript:;" routerLink="/auth/register" class="kt-link kt-login__signup-link"
    >Εγγραφείτε</a
  >
</div>
<!--end::Head-->

<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>Είσοδος</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <!--begin::Form-->
    <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            placeholder="Email"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>Το Email είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>Παρακαλώ εισάγετε μια σωστή διεύθυνση email</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong>Ελάχιστο μήκος 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong>Μέγιστο μήκος 320</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Κωδικός</mat-label>
          <input
            matInput
            type="password"
            placeholder="Κωδικός"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>Ο κωδικός είναι απαραίτητος</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>Ελάχιστο μήκος 6</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>Μέγιστο μήκος 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="kt-login__actions">
        <button
          (click)="submit()"
          [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
        >
          Είσοδος
        </button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->

    <!--begin::Divider-->
    <!--end::Options-->
  </div>
  <!--end::Signin-->
</div>
<!--end::Body-->
