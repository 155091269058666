<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>Εγγραφή</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <form class="kt-login__form kt-form rform" [formGroup]="registerForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Όνομα</mat-label>
          <input matInput type="text" placeholder="Όνομα" formControlName="firstname" />
          <mat-error *ngIf="isControlHasError('firstname', 'required')">
            <strong>Το πεδίο είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('firstname', 'minlength')">
            <strong>Ελάχιστο μήκος 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('firstname', 'maxlength')">
            <strong>Μέγιστο μήκος 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Επώνυμο</mat-label>
          <input matInput type="text" placeholder="Επώνυμο" formControlName="lastname" />
          <mat-error *ngIf="isControlHasError('lastname', 'required')">
            <strong>Το πεδίο είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('lastname', 'minlength')">
            <strong>Ελάχιστο μήκος 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('lastname', 'maxlength')">
            <strong>Μέγιστο μήκος 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            placeholder="Email"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>Το πεδίο είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>Παρακαλώ εισάγετε μια σωστή διεύθυνση email</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong>Ελάχιστο μήκος 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong>Μέγιστο μήκος 320</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Κωδικός</mat-label>
          <input
            matInput
            type="password"
            placeholder="Κωδικός"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>Το πεδίο είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>Ελάχιστο μήκος 6</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>Μέγιστο μήκος 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Επαλήθευση Κωδικού</mat-label>
          <input
            matInput
            type="password"
            placeholder="Επαλήθευση Κωδικού"
            formControlName="confirmPassword"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
            <strong>Το πεδίο είναι απαραίτητο</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'minlength')">
            <strong>Ελάχιστο μήκος 6</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'maxlength')">
            <strong>Μέγιστο μήκος 100</strong>
          </mat-error>
          <mat-error
            *ngIf="
              registerForm.get('confirmPassword').errors &&
              registerForm.get('confirmPassword').errors.ConfirmPassword
            "
          >
            <strong>Οι κωδικοί δεν ταιριάζουν.</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-checkbox name="is_company" formControlName="is_company">
          Ο λογαριασμός είναι εταιρικός.
        </mat-checkbox>
      </div>
      <div class="form-group">
        <mat-checkbox name="agree" formControlName="agree">
          Συμφωνώ με τους <a href="javascript:;" (click)="ox()">όρους χρήσης</a>
        </mat-checkbox>
        <mat-error *ngIf="isControlHasError('agree', 'required')">
          <strong>Πρέπει να αποδεχτείτε τους όρους χρήσης</strong>
        </mat-error>
      </div>
      <div class="form-group">
        <mat-checkbox name="gdpr" formControlName="gdpr">
          Συμφωνώ με την
          <a href="javascript:;" (click)="gdpr()">πολιτική προστασίας προσωπικών δεδομένων</a>
        </mat-checkbox>
        <mat-error *ngIf="isControlHasError('gdpr', 'required')">
          <strong>Πρέπει να αποδεχτείται την πολιτική μας περι προσωπικών δεδομένων</strong>
        </mat-error>
      </div>

      <!--begin::Action-->
      <div class="kt-login__actions">
        <button
          routerLink="/auth/login"
          [disabled]="loading"
          id="kt_login_signup_cancel"
          class="btn btn-secondary btn-elevate kt-login__btn-secondary"
        >
          Πίσω
        </button>
        <button
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
          [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
        >
          Εγγραφή
        </button>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
