<kt-portlet>
  <kt-portlet-header
    [title]="'Κωδ. Αποστολής: ' + item.vg_code"
    [class]="'kt-portlet__head--lg tthc'"
  >
    <ng-container ktPortletTools>
      <button
        class="mright mat-button-mt-4 red-btn"
        mat-raised-button
        (click)="close()"
        color="primary"
      >
        <mat-icon>keyboard_return</mat-icon>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <div class="container-fluid">
      <perfect-scrollbar class="scroller" [config]="{ suppressScrollX: true }">
        <div class="container maw-700">
          <div class="row">
            <div class="col">
              <kt-alert
                *ngIf="serverError | async as err"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                {{ err }}
              </kt-alert>
            </div>
          </div>
          <div class="row">
            <div class="col pinfo-col">
              <div *ngIf="loading" class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
              <div *ngIf="puoErr && !loading">
                <h3 class="perr-title">Συνέβη ένα σφάλμα</h3>
                <p class="perr">
                  <strong>Συνέβη ένα σφάλμα</strong> με την άντληση της
                  <strong>Εντολής Παραλαβής</strong> από τον απομακρυσμένο διακομιστή. Παρακαλούμε
                  περιμένετε μερικά δευτερόλεπτα και επιλέξτε ανανέωση για να ξαναπροσπαθήσετε.
                </p>
                <a
                  href="javascript:;"
                  (click)="refreshOrder(item.pickup_order)"
                  mat-raised-button
                  color="primary"
                >
                  <i class="la la-refresh"></i>
                  Ανανέωση
                </a>
              </div>
              <div *ngIf="!item.pickup_order && !loading" class="my-5 text-center">
                <h3>Η αποστολή δεν έχει ακόμα κάποια εντολή παραλαβής.</h3>
                <p>
                  Για να δημιουργήσετε μια εντολή παραλαβής συμπληρώστε την ημερομηνία και τις ώρες
                  παραλαβής παρακάτω και επιλέξτε Δημιουργία.
                </p>
              </div>
              <div *ngIf="!loading && puo" class="apinfo my-3">
                <div class="row">
                  <div class="col-xl-5">
                    <mat-list class="shcard">
                      <mat-list-item>
                        <h4 class="dtitle">Όνομα:</h4>
                        <span>{{ puo.name }}</span>
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Διεύθυνση:</h4>
                        <span>{{ puo.address }}</span>
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Πόλη:</h4>
                        <span>{{ puo.city }}</span>
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Ταχυδρομικός Κώδικας:</h4>
                        <span>{{ puo.post_code }}</span>
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Τηλέφωνο:</h4>
                        <span>{{ puo.phone }}</span>
                      </mat-list-item>
                      <mat-list-item *ngIf="puo.comments">
                        <h4 class="dtitle">Σχόλια:</h4>
                        <div>{{ puo.comments }}</div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <div class="col-xl-7">
                    <mat-list class="shcard">
                      <mat-list-item>
                        <h4 class="dtitle">Κωδικός Εντολής:</h4>
                        <span>{{ puo.pu_code }}</span>
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Ημερομηνία Παραλαβής:</h4>
                        <span
                          ><strong>{{ puo.date | date: "fullDate" }}</strong></span
                        >
                      </mat-list-item>
                      <mat-list-item>
                        <h4 class="dtitle">Ώρα Παραλαβής:</h4>
                        <span
                          >μεταξύ <strong>{{ puo.from }}</strong> και
                          <strong>{{ puo.to }}</strong></span
                        >
                      </mat-list-item>
                      <mat-list-item *ngIf="trans[puo.status] as st">
                        <h4 class="dtitle">Κατάσταση:</h4>
                        <span class="{{ puo.status }} puo-status">{{ st }}</span>
                      </mat-list-item>
                    </mat-list>
                    <div *ngIf="puo.status == 'OPEN'" class="cbtn">
                      <a
                        href="javascript:;"
                        (click)="cancelPickup()"
                        mat-raised-button
                        color="warn"
                      >
                        <i class="fas fa-trash"></i>
                        Ακύρωση
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!item.pickup_order" class="create_po">
            <div class="row my-3">
              <div class="col text-center">
                <h2 class="cpo_title">Δημιουργία Εντολής Παραλαβής</h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <mat-form-field class="mat-form=field-fluid">
                  <input
                    matInput
                    readonly
                    (click)="picker.open()"
                    [(ngModel)]="sdate"
                    [matDatepicker]="picker"
                    [min]="tomorrow"
                    [matDatepickerFilter]="weekendsDatesFilter"
                    placeholder="Επιλέξτε Ημέρα"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-8">
                <div class="mat-form-field-fluid">
                  <label>Επιλέξτε Ώρα: </label>&nbsp;&nbsp;
                  <mat-button-toggle-group
                    class="my-1"
                    name="tZone"
                    [(ngModel)]="tz"
                    #tZone="matButtonToggleGroup"
                    multiple="false"
                    aria-label="Time Zone"
                  >
                    <mat-button-toggle [value]="'1013'">10:00 με 13:00</mat-button-toggle>
                    <mat-button-toggle [value]="'1316'">13:00 με 16:00</mat-button-toggle>
                    <mat-button-toggle [value]="'1619'">16:00 με 19:00</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
                <mat-form-field class="mat-form-field-fluid">
                  <textarea matInput placeholder="Σχόλια" [(ngModel)]="comments"></textarea>
                  <mat-hint align="start"
                    >Παρακαλώ ειστάγετε τα
                    <strong>Σχόλια σας</strong>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-5">
              <div class="col d-flex align-items-center justify-content-center">
                <button
                  (click)="createPickup()"
                  mat-raised-button
                  color="warn"
                  matTooltip="Δημιουργία Εντολής Παραλαβής"
                >
                  Δημιουργία
                </button>
              </div>
            </div>
          </div>
          <div
            *ngIf="item.pickup_order && !loading && puo && puo.status != 'CANCELLED'"
            class="reschedule_po"
          >
            <div class="row my-3">
              <div class="col text-center">
                <h2 class="cpo_title">Τροποποίηση Εντολής Παραλαβής</h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <mat-form-field class="mat-form=field-fluid">
                  <input
                    matInput
                    readonly
                    (click)="picker.open()"
                    [(ngModel)]="sdate"
                    [matDatepicker]="picker"
                    [min]="tomorrow"
                    [matDatepickerFilter]="weekendsDatesFilter"
                    placeholder="Επιλέξτε Ημέρα"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-lg-8">
                <div class="mat-form-field-fluid">
                  <label>Επιλέξτε Ώρα: </label>&nbsp;&nbsp;
                  <mat-button-toggle-group
                    class="my-1"
                    name="tZone"
                    [(ngModel)]="tz"
                    #tZone="matButtonToggleGroup"
                    multiple="false"
                    aria-label="Time Zone"
                  >
                    <mat-button-toggle [value]="'1013'">10:00 με 13:00</mat-button-toggle>
                    <mat-button-toggle [value]="'1316'">13:00 με 16:00</mat-button-toggle>
                    <mat-button-toggle [value]="'1619'">16:00 με 19:00</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
                <mat-form-field class="mat-form-field-fluid">
                  <textarea matInput placeholder="Σχόλια" [(ngModel)]="comments"></textarea>
                  <mat-hint align="start"
                    >Παρακαλώ ειστάγετε τα
                    <strong>Σχόλια σας</strong>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-5">
              <div class="col d-flex align-items-center justify-content-center">
                <button
                  (click)="reschedulePickup()"
                  mat-raised-button
                  color="warn"
                  matTooltip="Τροποποίηση Εντολής Παραλαβής"
                >
                  Τροποποίηση
                </button>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </kt-portlet-body>
</kt-portlet>
