<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-footer__copyright">{{ today | date: "yyyy" }}&nbsp;&copy;&nbsp;RonEx</div>
    <div class="kt-footer__menu">
      <a (click)="about()" class="kt-footer__menu-link kt-link">Σχετικά με την RoNex</a>
      <a (click)="contact()" class="kt-footer__menu-link kt-link">Επικοινωνία</a>
    </div>
  </div>
</div>
<!-- end:: Footer -->
