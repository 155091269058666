// SERVICES
export { AuthService } from './_services';
export { AuthNoticeService } from './auth-notice/auth-notice.service';

// DATA SOURCERS

export { UsersDataSource } from './_data-sources/users.datasource';

// ACTIONS
export {
  Login,
  Logout,
  Register,
  UserRequested,
  UserRefreshed,
  UserLoaded,
  AuthActionTypes,
  AuthActions,
} from './_actions/auth.actions';

export {
  UserCreated,
  UserUpdated,
  UserDeleted,
  UserOnServerCreated,
  UsersPageLoaded,
  UsersPageCancelled,
  UsersPageToggleLoading,
  UsersPageRequested,
  UsersActionToggleLoading,
  AllUsersRequested,
  AllUsersLoaded,
  SaveSuccess,
  UserActionTypes,
  AddUserError,
  FetchUser,
} from './_actions/user.actions';

// EFFECTS
export { AuthEffects } from './_effects/auth.effects';
export { UserEffects } from './_effects/user.effects';

// REDUCERS
export { authReducer } from './_reducers/auth.reducers';
export { usersReducer } from './_reducers/user.reducers';

// SELECTORS
export {
  isLoggedIn,
  isLoggedOut,
  isUserLoaded,
  currentAuthToken,
  currentUser,
  currentUserRoleIds,
  isStaff,
} from './_selectors/auth.selectors';

export {
  selectUserById,
  selectUsersPageLoading,
  selectLastCreatedUserId,
  selectUsersInStore,
  selectHasUsersInStore,
  selectUsersPageLastQuery,
  selectUsersActionLoading,
  selectUsersShowInitWaitingMessage,
  selectUserError,
  selectSaveSuccess,
  selectAllUsers,
} from './_selectors/user.selectors';

// GUARDS
export { AuthGuard } from './_guards/auth.guard';

export { StaffGuard } from './_guards/staff.guard';
export { EsGuard } from './_guards/es.guard';
export { RestrictedGuard } from './_guards/restricted.guard';

// MODELS
export { User } from './_models/user.model';
export { Address } from './_models/address.model';
export { AuthNotice } from './auth-notice/auth-notice.interface';
