import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {
  map,
  catchError,
  delay,
  mergeMap,
  tap,
  finalize,
} from 'rxjs/operators';

@Component({
  selector: 'kt-info-view-bs',
  templateUrl: './info-view-bs.component.html',
  styleUrls: ['./info-view-bs.component.scss'],
})
export class InfoViewBsComponent implements OnInit {
  today = new Date();
  constructor(
    public bsRef: MatBottomSheetRef<InfoViewBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {}

  ngOnInit() {}

  close() {
    this.bsRef.dismiss();
  }
}
