import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
// LODASH
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core/reducers';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { User } from '../../../../core/auth';

import {
  UFActionFail,
  UFActionRefresh,
  UFActionSuccess,
  Userfile,
  UserfileActionTypes,
  UserfileDelete,
  UserfileMassDelete,
  UserfilesDataSource,
  UserfilesPageRequested,
} from '../../../../core/ronex';

@Component({
  selector: 'kt-user-files-list',
  templateUrl: './user-files-list.component.html',
  styleUrls: ['./user-files-list.component.scss'],
})
export class UserFilesListComponent implements OnInit, OnDestroy {
  @Input() user: User;

  dataSource: UserfilesDataSource;
  displayedColumns = ['select', 'name', 'date_created', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  lastQuery: QueryParamsModel;

  // Selection
  selection = new SelectionModel<Userfile>(true, []);
  userfilesResult: Userfile[] = [];

  adminView = false;
  serverError: Subject<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private dp: DatePipe,
  ) {}

  ngOnInit() {
    if (this.router.url.startsWith('/user-management')) {
      this.adminView = true;
    } else {
      this.adminView = false;
    }

    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.actions$
      .pipe(
        ofType<UFActionSuccess>(UserfileActionTypes.UFActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Delete,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<UFActionFail>(UserfileActionTypes.UFActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }

          // eslint-disable-next-line no-prototype-builtins
          if (payload.result.hasOwnProperty('ref_s') && payload.result.ref_s) {
            this.loadUserfileList();
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    const arf = this.actions$
      .pipe(
        ofType<UFActionRefresh>(UserfileActionTypes.UFActionRefresh),
        tap(({ payload }) => {
          this.loadUserfileList();
        }),
      )
      .subscribe();
    this.subscriptions.push(arf);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadUserfileList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup',
    )
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUserfileList();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.dataSource = new UserfilesDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.userfilesResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    this.paginator.pageIndex = 0;
    this.loadUserfileList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  loadUserfileList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    if (this.adminView) {
      queryParams.for_staff = true;
    }

    this.store.dispatch(new UserfilesPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};

    filter.uid = this.user.id;
    filter.name = this.searchInput.nativeElement.value;

    return filter;
  }

  deleteUserfile(_item: Userfile) {
    const _title = 'Διαγραφή Αρχείου';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε το αρχείο;';
    const _waitDesciption = 'Το αρχείο διαγράφεται ...';
    const _deleteMessage = `Το αρχείο έχει διαγραφεί`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new UserfileDelete({ userfile: _item }));
    });
  }

  deleteUserfiles() {
    const _title = 'Διαγραφή Αρχείων';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε τα αρχεία;';
    const _waitDesciption = 'Τα αρχεία διαγράφονται';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new UserfileMassDelete({ ids: _ids }));
    });
  }

  downloadUserfile(_item: Userfile) {
    this.http
      .get<Blob>(`api/userfiles/${_item.id}/download/`, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((data) => {
        const dis: string = data.headers.get('content-disposition');
        let fileName = undefined;
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

        const matches = fileNameRegex.exec(dis);

        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '').replace('utf-8', '');
        }

        fileName = decodeURIComponent(fileName);
        saveAs(data.body, fileName);
      });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.userfilesResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.userfilesResult.length) {
      this.selection.clear();
    } else {
      this.userfilesResult.forEach((row) => this.selection.select(row));
    }
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
