import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mergeMap, takeLast, last, map, tap, delay } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '../../../../../../../core/reducers';
import { mapValues, groupBy, omit, sortBy } from 'lodash';
import { User } from '../../../../../../../core/auth';

import { Charge } from '../../../../../../../core/ronex';

@Component({
  selector: 'kt-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss'],
})
export class PriceRangeComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() charges: Array<any>;

  @ViewChild('cCanvas') cCanvas: ElementRef;

  private subscriptions: Subscription[] = [];

  public context: CanvasRenderingContext2D;

  vinit = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private actions$: Actions,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.context = this.cCanvas.nativeElement.getContext('2d');
    this.draw();
    this.vinit = true;
  }

  draw() {
    const cw = this.cCanvas.nativeElement.clientWidth;
    const ch = this.cCanvas.nativeElement.clientHeight;

    const sorted = sortBy(this.charges, (ach) => parseFloat(ach.weight_start));
    const colors = [
      '#a55ca5',
      '#67b6c7',
      '#3ca639',
      '#df7f19',
      '#199edf',
      '#e34a3a',
      '#3ae384',
      '#9e3ae3',
      '#199edf',
      '#a55ca5',
    ];

    if (cw > 750) {
      //Draw horizontally
      this.cCanvas.nativeElement.width = this.cCanvas.nativeElement.clientWidth;
      this.cCanvas.nativeElement.height = 130;

      let max = parseInt(sorted[sorted.length - 1].weight_start);
      max += max * 0.3;

      sorted.forEach((item, index) => {
        const ws = parseInt(item.weight_start);
        const we = parseInt(item.weight_end);
        const xstart =
          ws === 0 ? 0 : this.cCanvas.nativeElement.width * (ws / max);
        const xend = we
          ? this.cCanvas.nativeElement.width * (we / max)
          : this.cCanvas.nativeElement.width;

        this.drawBar(
          this.context,
          xstart,
          40,
          xend - xstart,
          10,
          colors[index],
        );
        this.drawBar(
          this.context,
          xstart,
          50,
          xend - xstart,
          60,
          colors[index] + '82',
        );
        this.drawLine(this.context, xstart, 20, xstart, 40, '#aaa');
        this.drawText(
          this.context,
          'bottom',
          'left',
          '#373737',
          'bold 12px Arial',
          xstart + 10,
          30,
          `${item.weight_start} Kg`,
        );

        if (item.static_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            70,
            `Πάγιο:      €${item.static_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            70,
            `Πάγιο:      -`,
          );
        }
        if (item.per_kg_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            85,
            `Ανά Kg:    €${item.per_kg_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            85,
            `Ανά Kg:    -`,
          );
        }
        if (item.cod_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            100,
            `Αντικατ.:   €${item.cod_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            xstart + 20,
            100,
            `Αντικατ.:   -`,
          );
        }
      });
    } else {
      //Draw vertically
      this.cCanvas.nativeElement.width = this.cCanvas.nativeElement.clientWidth;
      let max = parseInt(sorted[sorted.length - 1].weight_start);
      max += max * 0.5;
      this.cCanvas.nativeElement.height = sorted.length * 100;

      sorted.forEach((item, index) => {
        const ws = parseInt(item.weight_start);
        const we = parseInt(item.weight_end);
        const ystart =
          ws === 0 ? 0 : this.cCanvas.nativeElement.height * (ws / max);
        const yend = we
          ? this.cCanvas.nativeElement.height * (we / max)
          : this.cCanvas.nativeElement.height;

        this.drawBar(
          this.context,
          60,
          ystart,
          10,
          yend - ystart,
          colors[index],
        );
        this.drawBar(
          this.context,
          70,
          ystart,
          this.cCanvas.nativeElement.width - 20,
          yend - ystart,
          colors[index] + '82',
        );
        this.drawLine(this.context, 20, ystart, 60, ystart, '#aaa');
        this.drawText(
          this.context,
          'bottom',
          'left',
          '#373737',
          'bold 12px Arial',
          0,
          ystart + 20,
          `${item.weight_start} Kg`,
        );

        if (item.static_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 25,
            `Πάγιο:      €${item.static_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 25,
            `Πάγιο:      -`,
          );
        }
        if (item.per_kg_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 40,
            `Ανά Kg:    €${item.per_kg_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 40,
            `Ανά Kg:    -`,
          );
        }
        if (item.cod_charge) {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 55,
            `Αντικατ.:   €${item.cod_charge}`,
          );
        } else {
          this.drawText(
            this.context,
            'bottom',
            'left',
            '#373737',
            '12px Arial',
            80,
            ystart + 55,
            `Αντικατ.:   -`,
          );
        }
      });
    }
  }

  drawText(ctx, textBaseline, textAlign, fillStyle, font, posX, posY, text) {
    ctx.save();
    ctx.textBaseline = textBaseline;
    ctx.textAlign = textAlign;
    ctx.fillStyle = fillStyle;
    ctx.font = font;
    ctx.fillText(text, posX, posY);
    ctx.restore();
  }

  drawBar(ctx, upperLeftCornerX, upperLeftCornerY, width, height, color) {
    ctx.save();
    ctx.fillStyle = color;
    ctx.fillRect(upperLeftCornerX, upperLeftCornerY, width, height);
    ctx.restore();
  }

  drawLine(ctx, startX, startY, endX, endY, color) {
    ctx.save();
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
    ctx.restore();
  }

  onResize($event) {
    this.draw();
  }

  ngOnChanges() {
    if (this.vinit) this.draw();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
