// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { AreaGroupService } from '../_services/area_group.service';
// State
import { AppState } from '../../reducers';
import {
  AreaGroupActionFail,
  AreaGroupActionRefresh,
  AreaGroupActionSuccess,
  AreaGroupActionToggleLoading,
  AreaGroupActionTypes,
  AreaGroupCreate,
  AreaGroupDelete,
  AreaGroupPageLoaded,
  AreaGroupPageRequested,
  AreaGroupPageToggleLoading,
  AreaGroupUpdate,
  AreaGroupUpsert,
} from '../_actions/area_group.actions';

@Injectable()
export class AreaGroupEffects {
  showPageLoadingDistpatcher = new AreaGroupPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new AreaGroupPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new AreaGroupActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new AreaGroupActionToggleLoading({
    isLoading: false,
  });

  loadAreaGroupPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaGroupPageRequested>(
        AreaGroupActionTypes.AreaGroupPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ess.findAreaGroups(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new AreaGroupPageLoaded({
          areaGroups: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  deleteAreaGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaGroupDelete>(AreaGroupActionTypes.AreaGroupDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.deleteAreaGroup(payload.areaGroup).pipe(
          switchMap(() => [
            new AreaGroupActionSuccess({
              result: `Η ομάδα διαγράφηκε επιτυχώς`,
            }),
            new AreaGroupActionRefresh({ result: true }),
          ]),
          catchError((err) => [new AreaGroupActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  createAreaGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaGroupCreate>(AreaGroupActionTypes.AreaGroupCreate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.createAreaGroup(payload.areaGroup).pipe(
          switchMap((res) => [
            new AreaGroupUpsert({ areaGroup: res }),
            new AreaGroupActionSuccess({
              result: `Η ομάσα προστέθηκε επιτυχώς`,
            }),
          ]),
          catchError((err) => [new AreaGroupActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  updateAreaGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AreaGroupUpdate>(AreaGroupActionTypes.AreaGroupUpdate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.updateAreaGroup(payload.areaGroup).pipe(
          switchMap((res) => [
            new AreaGroupUpsert({ areaGroup: res }),
            new AreaGroupActionSuccess({
              result: `Η ομάδα ενημερώθηκε επιτυχώς`,
            }),
          ]),
          catchError((err) => [new AreaGroupActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  constructor(
    private actions$: Actions,
    private ess: AreaGroupService,
    private store: Store<AppState>,
  ) {}
}
