import { BaseModel } from '../../_base/crud';
import { Shipment } from './shipment.model';
import { User } from '../../auth/_models/user.model';

export class KgCheckReport extends BaseModel {
  id: number;
  date_created: Date;
  corrected_after_delivery: Array<Shipment>;
  inv_corrected_after_delivery: Array<Shipment>;
  not_found: Array<string>;
  cad_log: Array<any>;
}
