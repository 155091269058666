<kt-portlet>
  <kt-portlet-header
    [title]="'Προς:  ' + data.item.recipient_name"
    [class]="'kt-portlet__head--lg tthc'"
  >
    <ng-container ktPortletTools>
      <button
        class="mright mat-button-mt-4 red-btn"
        mat-raised-button
        (click)="close()"
        color="primary"
      >
        <mat-icon>keyboard_return</mat-icon>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <div class="row">
      <div class="col">
        <div class="kt-section__content">
          <div class="main_content">
            <h2 class="tth pb-3 pt-2">Στοιχεία Αποστολής</h2>
            <perfect-scrollbar class="scroller">
              <mat-list class="shcard">
                <mat-list-item>
                  <h4 class="dtitle">Κωδ. Αποστολής:</h4>
                  <span>{{ data.item.vg_code }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.adm && data.financial">
                  <h4 class="dtitle">Χρήστης:</h4>
                  <span>
                    ({{ data.item.auser.id }}) &nbsp; {{ data.item.auser.lastname }}
                    {{ data.item.auser.firstname }}</span
                  >
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Κατάσταση:</h4>
                  <div *ngIf="data.item._status === 'PENDING'" class="pending">
                    <span class="status-icon"><i class="flaticon2-download"></i></span>
                    <span class="status-text">Εκκρεμεί</span>
                  </div>
                  <div *ngIf="data.item._status === 'PROCESSED'" class="approved">
                    <span class="status-icon"
                      ><i class="flaticon2-black-back-closed-envelope-shape"></i
                    ></span>
                    <span class="status-text">Εγκρίθηκε</span>
                  </div>
                  <div *ngIf="data.item._status === 'PAID' && data.adm" class="paid">
                    <span class="status-icon"><i class="fas fa-money-check-alt"></i></span>
                    <span class="status-text">Εισπραγμένη</span>
                  </div>
                  <div *ngIf="data.item._status === 'PAID' && !data.adm" class="approved">
                    <span class="status-icon"
                      ><i class="flaticon2-black-back-closed-envelope-shape"></i
                    ></span>
                    <span class="status-text">Εγκρίθηκε</span>
                  </div>
                  <div *ngIf="data.item._status === 'PAID_APPROVED'" class="paida">
                    <span class="status-icon"><i class="fas fa-hand-holding-usd"></i></span>
                    <span class="status-text">Πρός Απόδοση</span>
                  </div>
                  <div *ngIf="data.item._status === 'INVOICED'" class="inv">
                    <span class="status-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="status-text">Εξοφλημένη</span>
                  </div>
                  <div *ngIf="data.item._status === 'RETURNED'" class="returned">
                    <span class="status-icon"><i class="fas fa-undo-alt"></i></span>
                    <span class="status-text">Επεστραμένη</span>
                  </div>
                  <div
                    *ngIf="data.item._status === 'CANCELLATION_PENDING'"
                    class="cancellation_pending"
                  >
                    <span class="status-icon"><i class="fas fa-exclamation-circle"></i></span>
                    <span class="status-text">Προς Ακύρωση</span>
                  </div>
                  <div *ngIf="data.item._status === 'CANCELLED'" class="cancelled">
                    <span class="status-icon"><i class="fas fa-times-circle"></i></span>
                    <span class="status-text">Ακυρωμένη</span>
                  </div>
                  <div *ngIf="data.item._status === 'CLARIFICATION'" class="cancelled">
                    <span class="status-icon"><i class="fas fa-question"></i></span>
                    <span class="status-text">Προς Διασάφηση</span>
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.is_returned && data.item.vg_code_return">
                  <h4 class="dtitle">Κωδ. Επιστροφής:</h4>
                  <ng-container *ngIf="data.item.return_vgs.length === 0">
                    <span>{{ data.item.vg_code_return }} </span>
                  </ng-container>
                  <ng-container *ngIf="data.item.return_vgs.length !== 0">
                    <span *ngFor="let retcode of data.item.return_vgs; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>
                      {{ retcode.vg_code }}
                    </span>
                  </ng-container>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.is_returned">
                  <h4 class="dtitle">Επεστραμένη:</h4>
                  <span class="ready"><i class="fas fa-check"></i></span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.is_delivered">
                  <h4 class="dtitle">Παραδομένη:</h4>
                  <span class="ready"><i class="fas fa-check"></i></span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_delivered && data.item.is_delivered">
                  <h4 class="dtitle">Παραδόθηκε:</h4>
                  <span>{{ data.item.date_delivered | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_returned && data.item.is_returned">
                  <h4 class="dtitle">Επεστράφη:</h4>
                  <span>{{ data.item.date_returned | date: "mediumDate" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_cancelled">
                  <h4 class="dtitle">Ακυρώθηκε:</h4>
                  <span>{{ data.item.date_cancelled | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_created">
                  <h4 class="dtitle">Δημιουργήθηκε:</h4>
                  <span>{{ data.item.date_created | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_approved">
                  <h4 class="dtitle">Εγκρίθηκε:</h4>
                  <span>{{ data.item.date_approved | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.adm && data.item.date_paid">
                  <h4 class="dtitle">Εισπράχτηκε:</h4>
                  <span>{{ data.item.date_paid | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.date_invoiced">
                  <h4 class="dtitle">Εξοφλήθη:</h4>
                  <span>{{ data.item.date_invoiced | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.adm && data.item.date_prepaid">
                  <h4 class="dtitle">Προπληρώθηκε:</h4>
                  <span>{{ data.item.date_prepaid | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.is_cod">
                  <h4 class="dtitle">Αντικαταβολή:</h4>
                  <span class="cod">{{ data.item.cod_ammount | currency: "EUR" }}</span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.is_cod && data.item.dec_cod_ammount">
                  <h4 class="dtitle">Δηλωθείσα Αντικαταβολή:</h4>
                  <span class="cod">{{ data.item.dec_cod_ammount | currency: "EUR" }}</span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Βάρος:</h4>
                  <span>{{ data.item.weight }} &nbsp; Kg</span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.orig_weight">
                  <h4 class="dtitle">Δηλωθέν Βάρος:</h4>
                  <span>{{ data.item.orig_weight }} &nbsp; Kg</span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.spdx_validated_weight && data.adm">
                  <h4 class="dtitle">Τιμολογηθέν Βάρος:</h4>
                  <span>{{ data.item.spdx_validated_weight }} &nbsp; Kg</span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Τεμάχια:</h4>
                  <span>{{ data.item.items }} </span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Παράδοση:</h4>
                  <span *ngIf="data.item.recipient_service == 1">Παράδοση στον παραλήπτη</span>
                  <span *ngIf="data.item.recipient_service == 2">Παραλαβή απο θυρίδα</span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Ονοματεπώνυμο:</h4>
                  <span>{{ data.item.recipient_name }} </span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Διεύθυνση:</h4>
                  <span>{{ data.item.recipient_address }} </span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Ταχυδρομικός Κώδικας:</h4>
                  <span>{{ data.item.recipient_postcode }} </span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Περιοχή:</h4>
                  <ng-container *ngIf="data.item.recipient_selected_area">
                    <span
                      >{{ data.item.recipient_selected_area.name }}
                      {{ data.item.recipient_selected_area.nomos }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!data.item.recipient_selected_area">
                    <span>{{ data.item.recipient_area }} </span>
                  </ng-container>
                </mat-list-item>
                <mat-list-item
                  *ngIf="data.item.recipient_selected_area && data.item.recipient_area"
                >
                  <h4 class="dtitle">Υποπεριοχή:</h4>
                  <span>{{ data.item.recipient_area }} </span>
                </mat-list-item>
                <mat-list-item>
                  <h4 class="dtitle">Τηλέφωνο:</h4>
                  <span>{{ data.item.recipient_phone }} </span>
                </mat-list-item>
                <mat-list-item *ngIf="data.item.recipient_phone2">
                  <h4 class="dtitle">Δεύτερο Τηλέφωνο:</h4>
                  <span>{{ data.item.recipient_phone2 }} </span>
                </mat-list-item>
                <mat-list-item
                  *ngIf="data.adm && data.item.date_send_delivered && data.item.send_delivered"
                >
                  <h4 class="dtitle">Παράδοση Αποστολής:</h4>
                  <span>{{ data.item.date_send_delivered | date: "medium" }} </span>
                </mat-list-item>
                <mat-list-item
                  *ngIf="data.adm && data.item.date_return_delivered && data.item.return_delivered"
                >
                  <h4 class="dtitle">Παράδοση Επιστροφής:</h4>
                  <span>{{ data.item.date_return_delivered | date: "medium" }} </span>
                </mat-list-item>
              </mat-list>
              <div *ngIf="data.item.user_comments" class="comments">
                <mat-divider *ngIf="data.item.user_comments"></mat-divider>
                <h5 class="com_title">Παρατητήσεις:</h5>
                {{ data.item.user_comments }}
              </div>
              <div *ngIf="data.item.sh_comments" class="comments">
                <mat-divider *ngIf="data.item.sh_comments"></mat-divider>
                <h5 class="com_title">Σχόλια:</h5>
                {{ data.item.sh_comments }}
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <!--mat-divider *ngIf="data.financial" [vertical]="true" class="kt-hidden-mobile"></mat-divider-->
      <div *ngIf="data.financial" class="mrule vr kt-hidden-mobile"></div>
      <div *ngIf="data.financial" class="col lrg">
        <kt-charge-analysis [data]="data"></kt-charge-analysis>
      </div>
      <div *ngIf="!data.financial" class="mrule vr kt-hidden-mobile"></div>
      <!--mat-divider *ngIf="!data.financial" [vertical]="true" class="kt-hidden-mobile"></mat-divider-->
      <div *ngIf="!data.financial" class="status">
        <div class="container-fluid blue-bg">
          <div class="container mlc">
            <h2 class="tth pb-3 pt-2">Tracking Αποστολής</h2>
            <div *ngIf="!(_status | async)?.length" class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
            <perfect-scrollbar class="scroller">
              <div *ngIf="_status | async" class="tdata" [ngClass]="{ ccenter: _cs }">
                <ng-container
                  *ngFor="
                    let stat of _status | async;
                    let last = last;
                    let even = even;
                    let odd = odd
                  "
                >
                  <ng-container *ngIf="even">
                    <div class="row align-items-center how-it-works">
                      <div class="col-2 text-center bottom">
                        <div class="circle">{{ stat._date | date: "dd/MM/yy HH:mm" }}</div>
                      </div>
                      <div class="col-10">
                        <h5 class="stats">{{ stat._status }}</h5>
                        <p class="station">{{ stat._station }}</p>
                      </div>
                    </div>

                    <div *ngIf="!last" class="row timeline">
                      <div class="col-2">
                        <div class="corner top-right"></div>
                      </div>
                      <div class="col-8">
                        <hr />
                      </div>
                      <div class="col-2">
                        <div class="corner left-bottom"></div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="odd">
                    <div class="row align-items-center justify-content-end how-it-works">
                      <div class="col-10 text-right">
                        <h5 class="stats">{{ stat._status }}</h5>
                        <p class="station">{{ stat._station }}</p>
                      </div>
                      <div class="col-2 text-center full">
                        <div class="circle">{{ stat._date | date: "dd/MM/yy HH:mm" }}</div>
                      </div>
                    </div>

                    <div *ngIf="!last" class="row timeline">
                      <div class="col-2">
                        <div class="corner right-bottom"></div>
                      </div>
                      <div class="col-8">
                        <hr />
                      </div>
                      <div class="col-2">
                        <div class="corner top-left"></div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
