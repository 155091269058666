// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Models
import { GConfig } from '../_models/gconfig.model';

export enum GConfigActionTypes {
  GConfigRequested = '[GConfig API] GConfig Requested',
  GConfigLoaded = '[GConfig] GConfig Loaded',
  GCActionSuccess = '[GConfig] GConfig Action Success',
  GCActionFail = '[GConfig] GConfig Action Failed',
  GConfigUpdate = '[GConfig API] GConfig Update',
  GConfigUpsert = '[GConfig] GConfig Upsert',
  GConfigBulkUpdate = '[GConfig API] GConfig Bulk Update',
  GConfigUpsertMany = '[GConfig] GConfig Upsert Many',
}

export class GCActionSuccess implements Action {
  readonly type = GConfigActionTypes.GCActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class GCActionFail implements Action {
  readonly type = GConfigActionTypes.GCActionFail;
  constructor(public payload: { result: any }) {}
}

export class GConfigRequested implements Action {
  readonly type = GConfigActionTypes.GConfigRequested;
}

export class GConfigLoaded implements Action {
  readonly type = GConfigActionTypes.GConfigLoaded;
  constructor(public payload: { gconfig: GConfig[] }) {}
}

export class GConfigBulkUpdate implements Action {
  readonly type = GConfigActionTypes.GConfigBulkUpdate;
  constructor(public payload: { values: any }) {}
}

export class GConfigUpdate implements Action {
  readonly type = GConfigActionTypes.GConfigUpdate;
  constructor(public payload: { gconfig: GConfig }) {}
}

export class GConfigUpsert implements Action {
  readonly type = GConfigActionTypes.GConfigUpsert;
  constructor(public payload: { gconfig: GConfig }) {}
}

export class GConfigUpsertMany implements Action {
  readonly type = GConfigActionTypes.GConfigUpsertMany;
  constructor(public payload: { gconfig: GConfig[] }) {}
}

export type GConfigActions =
  | GConfigRequested
  | GConfigLoaded
  | GCActionSuccess
  | GCActionFail
  | GConfigUpdate
  | GConfigUpsert
  | GConfigBulkUpdate
  | GConfigUpsertMany;
