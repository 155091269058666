// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import {
  debounceTime,
  distinctUntilChanged,
  map,
  skip,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
// LODASH
import { orderBy } from 'lodash';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';

// Models and selectors
import {
  ESActionFail,
  ESActionRefresh,
  ESActionSuccess,
  ExternalShipment,
  ExternalShipmentActionTypes,
  ExternalShipmentCheck,
  ExternalShipmentCreate,
  ExternalShipmentDataSource,
  ExternalShipmentDelete,
  ExternalShipmentMassDelete,
  ExternalShipmentPageRequested,
} from '../../../../../core/ronex';

import {
  AllUsersRequested,
  AuthService,
  currentUser,
  selectUsersInStore,
  User,
} from '../../../../../core/auth';

import { HttpClient } from '@angular/common/http';
import { SubheaderService } from '../../../../../core/_base/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'kt-external-shipments',
  templateUrl: './external-shipments.component.html',
  styleUrls: ['./external-shipments.component.scss'],
})
export class ExternalShipmentsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  dataSource: ExternalShipmentDataSource;

  displayedColumns = [
    'select',
    'vg_code',
    'date_created',
    'date_approved',
    '_status',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('usrSel', { static: true }) usrSel: ElementRef;

  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<ExternalShipment>(true, []);
  shipmentsResult: ExternalShipment[] = [];
  adminView = false;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  serverError: Subject<any>;

  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  currUser: User = undefined;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  usrCtrl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  _filteredOptions: Observable<User[]>;
  fUsers: User[] = [];

  fStatus: Array<string> = ['PENDING', 'PROCESSED', 'NOT_MINE'];

  fvg_codes: Array<string> = [];

  dpFC: any;
  dpTC: any;
  dpFA: any;
  dpTA: any;

  dpFCV: any;

  cleanup = false;

  newES: ExternalShipment;

  @ViewChild('usrInput') usrInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private actions$: Actions,
    private auth: AuthService,
    private dp: DatePipe,
  ) {}

  ngOnInit() {
    this.newES = new ExternalShipment();
    this.newES.vg_code = '';

    if (this.router.url.startsWith('/shipment-management/admin')) {
      this.adminView = true;
      this.store.dispatch(new AllUsersRequested());
      this.displayedColumns = [
        'select',
        'user',
        'vg_code',
        'date_created',
        'date_approved',
        '_status',
        'is_return',
        'actions',
      ];
    } else {
      this.store
        .pipe(
          select(currentUser),
          tap((res) => {
            if (res) {
              this.currUser = res;
              this.newES.user = this.currUser;
            }
          }),
          takeWhile((res) => !res),
        )
        .subscribe((res) => console.log(res));
      this.adminView = false;
    }

    if (this.adminView) {
      this._filteredOptions = fromEvent(
        this.usrSel.nativeElement,
        'keyup',
      ).pipe(
        map((res) => this.usrSel.nativeElement.value),
        startWith(undefined),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((val) => this.__filter(val || '')),
      );
    }

    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.actions$
      .pipe(
        ofType<ESActionSuccess>(ExternalShipmentActionTypes.ESActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Delete,
            5000,
            true,
            true,
          );
          if (this.cleanup) {
            this.newES = new ExternalShipment();
            this.newES.vg_code = '';
            this.newES.user = this.currUser;
            this.cleanup = false;
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<ESActionFail>(ExternalShipmentActionTypes.ESActionFail),
        tap(({ payload }) => {
          if (this.cleanup) {
            const vg_code = this.newES.vg_code;
            const _usr = this.newES.user;
            this.newES = new ExternalShipment();
            this.newES.vg_code = vg_code;
            this.newES.user = _usr;
            this.cleanup = false;
          }

          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
          // eslint-disable-next-line no-prototype-builtins
          if (payload.result.hasOwnProperty('ref_s') && payload.result.ref_s) {
            this.loadShipmentsList();
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    const arf = this.actions$
      .pipe(
        ofType<ESActionRefresh>(ExternalShipmentActionTypes.ESActionRefresh),
        tap(({ payload }) => {
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(arf);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Διαχείριση Εξωτερικών Αποστολών');

    // Init DataSource
    this.dataSource = new ExternalShipmentDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.shipmentsResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 50;
    this.sort.active = 'date_created';
    this.sort.direction = 'desc';
    this.loadShipmentsList();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  vg_add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.fvg_codes.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  vg_remove(code: string) {
    const index = this.fvg_codes.indexOf(code);
    if (index >= 0) {
      this.fvg_codes.splice(index, 1);
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  //Filter shit here

  filterUsers(event) {
    const val = event.target.value;
    this.filteredOptions = this._filter(val);
  }

  __filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        //take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  _filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) => !this.fUsers.some((el) => el.id === usr.id),
          ),
        ),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  refs(): void {
    this.loadShipmentsList();
  }

  remove(usr: User): void {
    const index = this.fUsers.indexOf(usr);
    if (index >= 0) {
      this.fUsers.splice(index, 1);
      this.filteredOptions = this._filter('');
      this.paginator.pageIndex = 0;
      this.loadShipmentsList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fUsers.push(event.option.value);
    this.usrInput.nativeElement.value = '';
    this.usrCtrl.setValue(null);
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  fStatusChanged(event) {
    if (event.source._checked) {
      this.fStatus = [...this.fStatus, event.value];
    } else {
      this.fStatus = this.fStatus.filter((e) => e !== event.value);
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpFCe(type: string, event: MatDatepickerInputEvent<any>) {
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpTCe(type: string, event: MatDatepickerInputEvent<any>) {
    if (this.dpTC) {
      this.dpTC = this.dpTC
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpFAe(type: string, event: MatDatepickerInputEvent<any>) {
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpTAe(type: string, event: MatDatepickerInputEvent<any>) {
    if (this.dpTA) {
      this.dpTA = this.dpTA
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  loadShipmentsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    if (this.adminView) {
      queryParams.for_staff = true;
    }

    this.store.dispatch(
      new ExternalShipmentPageRequested({ page: queryParams }),
    );
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};

    filter.uids = this.fUsers.map((usr) => usr.id);
    filter.vg_codes = [...this.fvg_codes];
    filter._status = this.fStatus;

    if (this.dpFC) {
      filter.dpfc = this.dpFC.format();
    }
    if (this.dpTC) {
      filter.dptc = this.dpTC.format();
    }
    if (this.dpFA) {
      filter.dpfa = this.dpFA.format();
    }
    if (this.dpTA) {
      filter.dpta = this.dpTA.format();
    }

    return filter;
  }

  deleteShipment(_item: ExternalShipment) {
    const _title = 'Διαγραφή Εξωτερικής Αποστολής';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε την αποστολή';
    const _waitDesciption = 'Η αποστολή διαγράφεται ...';
    const _deleteMessage = `Η αποστολή έχει διαγραφεί`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new ExternalShipmentDelete({ shipment: _item }));
    });
  }

  deleteShipments() {
    // Mass shipment delete here
    const _title = 'Διαγραφή Εξωτερικών Αποστολών';
    const _description =
      'Είστε σίγουρος πως θέλετε να διαγράψετε τις αποστολές;';
    const _waitDesciption = 'Οι αποστολές διαγράφονται';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new ExternalShipmentMassDelete({ ids: _ids }));
    });
  }

  addNew() {
    console.log(this.newES);
    if (!this.newES.vg_code) {
      this.serverError.next('Ο κωδικός αποστολής είναι απαραίτητο πεδίο');
    } else if (!this.newES.user) {
      this.serverError.next('Ο χρήστης είναι απαραίτητο πεδίο');
    } else {
      this.cleanup = true;
      this.store.dispatch(new ExternalShipmentCreate({ shipment: this.newES }));
    }
  }

  check() {
    this.store.dispatch(new ExternalShipmentCheck());
  }

  displayFn(user?: User): string | undefined {
    return user ? `${user.lastname} ${user.firstname}` : undefined;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.shipmentsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.shipmentsResult.length) {
      this.selection.clear();
    } else {
      this.shipmentsResult.forEach((row) => this.selection.select(row));
    }
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
