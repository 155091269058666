<kt-portlet>
  <kt-portlet-header
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="loading$"
  >
    <ng-container ktPortletTools>
      <a
        *ngIf="adminView"
        routerLink="/shipment-management/admin/shipments"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Πίσω στις αποστολές"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Πίσω</span>
      </a>
      <a
        *ngIf="!adminView"
        routerLink="/shipment-management/shipments"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Πίσω στις αποστολές"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Πίσω</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-secondary kt-margin-r-10"
        (click)="reset()"
        [disabled]="selectedTab !== 0"
        mat-raised-button
        matTooltip="Απαλοιφή αλλαγών"
      >
        <i class="la la-cog"></i>
        <span class="kt-hidden-mobile">Απαλοιφή</span>
      </a>
      <a
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="onSumbit(false)"
        mat-raised-button
        matTooltip="Αποθήκευση και συνέχεια"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση</span>
      </a>
      <a
        *ngIf="!retr"
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="onSumbit(false, true)"
        mat-raised-button
        matTooltip="Αποθήκευση και Νέα"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση &amp; Νέα</span>
      </a>
      <ng-container *ngIf="!retr">
        <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item color="primary" (click)="onSumbit(true)">
            Αποθήκευση και έξοδος
          </button>
          <button mat-menu-item color="primary" (click)="onSumbit(false)">
            Αποθήκευση και συνέχεια
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <mat-tab-group [(selectedIndex)]="selectedTab">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fa fa-paper-plane"></i>
          Στοιχεία Αποστολής
        </ng-template>
        <ng-template matTabContent>
          <!--begin::Form-->
          <div *ngIf="shipment">
            <form [formGroup]="shipmentForm" class="kt-form kt-form--group-seperator-dashed">
              <kt-alert
                *ngIf="hasFormErrors"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
              </kt-alert>
              <kt-alert
                *ngIf="serverError | async as err"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                {{ err }}
              </kt-alert>
              <kt-alert *ngIf="blacklisted | async" type="accent" [showCloseButton]="false">
                ΠΡΟΣΟΧΗ : Τα αρχεία μας δείχνουν πως ο συγκεκριμένος παραλήπτης έχει ιστορικό μη
                παραλαβής δεμάτων.
              </kt-alert>
              <kt-alert
                *ngFor="let msg of area_messages"
                type="primary"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="msgClose(msg)"
              >
                {{ msg }}
              </kt-alert>
              <div class="kt-form__section kt-form__section--first">
                <div *ngIf="!retr" class="form-group kt-form__group row">
                  <div *ngIf="!shipment.is_dsr" class="col-lg-2 kt-margin-bottom-20-mobile plr">
                    <mat-slide-toggle formControlName="is_cod">Αντικαταβολή</mat-slide-toggle>
                  </div>
                  <div
                    *ngIf="shipmentForm.controls.is_cod.value"
                    class="col-lg-3 kt-margin-bottom-20-mobile plr"
                  >
                    <mat-form-field class="mat-form-field-fluid">
                      <!--input matInput  currencyMask [options]=" { prefix: '&euro; ',  thousands: ',', decimal: '.' } " placeholder="Ποσό Αντικαταβολής ( &euro; 00.00 )" class="right-align" formControlName="cod_ammount"/-->
                      <input
                        *ngIf="!shipment.is_dsr"
                        matInput
                        [textMask]="{ mask: curr_mask }"
                        placeholder="Ποσό Αντικαταβολής ( &euro; 00.00 )"
                        class="right-align"
                        formControlName="cod_ammount"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το
                        <strong>ποσό</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        class="right-align"
                        placeholder="Αριθμός αντικειμένων (1 εώς 15)"
                        formControlName="items"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τον <strong>Αριθμό Τεμαχίων</strong> της Αποστολής
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: kg_mask }"
                        placeholder="Βάρος Αποστολής ( 00.00 Kg )"
                        class="right-align"
                        formControlName="weight"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το
                        <strong>Βάρος</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="retr" class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input matInput placeholder="Κωδικός Αποστολής" formControlName="vg_code" />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τον
                        <strong>Κωδικό Αποστολής</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        class="right-align"
                        placeholder="Αριθμός αντικειμένων (1 εώς 15)"
                        formControlName="items"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τον <strong>Αριθμό Τεμαχίων</strong> της Αποστολής
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: kg_mask }"
                        placeholder="Βάρος Αποστολής ( 00.00 Kg )"
                        class="right-align"
                        formControlName="weight"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το
                        <strong>Βάρος</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col plr jtc pb0">
                    <p>
                      <strong>Ογκομετρικό Βάρος : </strong>Υπολογίζεται με τον κανόνα
                      <strong>Μήκος x Πλάτος x Ύψος / 5000</strong>. Η μονάδα μέτρησης είναι τα
                      εκατοστά / cm.
                      <strong>Αν υπερβαίνει το φυσικός βάρος θα χρησιμοποιηθεί αντ' αυτού</strong>.
                    </p>
                  </div>
                </div>
                <div class="form-group kt-form__group row pt0">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: cm_mask }"
                        placeholder="Μήκος Πακέτου ( 00.00 cm )"
                        class="right-align"
                        formControlName="size_x"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ εισάγετε το
                        <strong>Μήκος</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: cm_mask }"
                        placeholder="Ύψος Πακέτου ( 00.00 cm )"
                        class="right-align"
                        formControlName="size_y"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ εισάγετε το
                        <strong>'Υψος</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr pt0">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [textMask]="{ mask: cm_mask }"
                        placeholder="Πλάτος Πακέτου ( 00.00 cm )"
                        class="right-align"
                        formControlName="size_z"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ εισάγετε το
                        <strong>Πλάτος</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!retr" class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr d-flex align-items-center">
                    <div class="mat-form-field-fluid">
                      <label> <strong>Παράδοση:</strong> </label>&nbsp;
                      <mat-radio-group formControlName="recipient_service">
                        <mat-radio-button checked="true" value="1">Στον Παραλήπτη</mat-radio-button>
                        <mat-radio-button value="2">Σε Θυρίδα</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr d-flex align-items-center">
                    <div class="mat-form-field-fluid pr-4">
                      <label> Διαστάσεις <strong>Voucher:</strong> </label>&nbsp;
                      <mat-button-toggle-group
                        class="my-1"
                        name="spdxPaper"
                        #spdxPaper="matButtonToggleGroup"
                        formControlName="voucher_a6"
                        multiple="false"
                        aria-label="Voucher Size"
                      >
                        <mat-button-toggle [value]="false">A4</mat-button-toggle>
                        <mat-button-toggle [value]="true">A6</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                </div>
                <div *ngIf="adminView" class="form-group kt-form__group row">
                  <div class="col-lg-6 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [matAutocomplete]="auto"
                        placeholder="Χρήστης"
                        formControlName="user"
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="userChange($event.option.value)"
                      >
                        <perfect-scrollbar>
                          <mat-option
                            *ngFor="let option of filteredOptions | async"
                            [value]="option"
                          >
                            {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                            {{ option.firstname }} [ {{ option.email }} ]
                          </mat-option>
                        </perfect-scrollbar>
                      </mat-autocomplete>
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τον
                        <strong>Χρήστη</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!shipment.is_dsr" class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Όνοματεπώνυμο Παραλήπτη"
                        formControlName="recipient_name"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το
                        <strong>Ονοματεπώνυμο</strong>
                        του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-8 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Διεύθυνση Παραλήπτη"
                        formControlName="recipient_address"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε την <strong>Διεύθυνση</strong> του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!shipment.is_dsr" class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Ταχυδρομικός Κώδικας"
                        formControlName="recipient_postcode"
                        (input)="tkChanged()"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τον <strong>Τ.Κ.</strong> του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-label>Περιοχή Παραλήπτη</mat-label>
                      <mat-select
                        [disabled]="areaOptions.length < 1 || !activeCourier"
                        formControlName="recipient_selected_area"
                        (selectionChange)="arSelChanged()"
                        [compareWith]="areaCompare"
                      >
                        <mat-option *ngFor="let op of areaOptions" [value]="op">
                          {{ op?.name }} ({{ op?.nomos }})
                          <ng-container *ngIf="op && op.courier !== activeCourier">
                            <ng-container *ngIf="op.courier === 'ELTA'">
                              [ΕΛΤΑ Courier]</ng-container
                            >
                            <ng-container *ngIf="op.courier === 'SPEEDEX'"> [SPEEDEX]</ng-container>
                            <ng-container *ngIf="op.courier === 'COURIER_CENTER'">
                              [COURIER CENTER]</ng-container
                            >
                          </ng-container>
                        </mat-option>
                      </mat-select>
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ επιλέξτε την <strong>περιοχή</strong> του παραλήπτη.
                        <strong *ngIf="adminView">ΠΡΕΠΕΙ ΝΑ ΥΠΑΡΧΕΙ ΕΠΙΛΕΓΜΕΝΟΣ ΧΡΗΣΤΗΣ</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Υποπεριοχή Παραλήπτη"
                        formControlName="recipient_area"
                        [readonly]="!shipmentForm.controls.recipient_selected_area.value"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Εφόσον είναι απαραίτητο
                        <strong> διευκρινίστε την υποπεριοχή </strong>
                        του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!shipment.is_dsr" class="form-group kt-form__group row">
                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Τηλέφωνο Παραλήπτη"
                        formControlName="recipient_phone"
                      />
                      <mat-error
                        >Το πεδίο αυτό είναι
                        <strong>απαραίτητο</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το <strong>Τηλέφωνο</strong> του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        placeholder="Δεύτερο Τηλέφωνο"
                        formControlName="recipient_phone2"
                      />
                      <mat-error
                        >Η τιμή του πεδίου
                        <strong>δεν είναι σωστή</strong>
                      </mat-error>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε το <strong>Δεύερο Τηλέφωνο</strong> του παραλήπτη.
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group kt-form__group row">
                  <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <textarea
                        matInput
                        placeholder="Παρατηρήσεις"
                        formControlName="user_comments"
                      ></textarea>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τις
                        <strong>Παρατηρήσεις σας</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group kt-form__group row">
                  <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
                    <mat-form-field class="mat-form-field-fluid">
                      <textarea
                        matInput
                        placeholder="Σχόλια"
                        formControlName="sh_comments"
                      ></textarea>
                      <mat-hint align="start"
                        >Παρακαλώ ειστάγετε τα
                        <strong>Σχόλια σας</strong>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--end::Form-->
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="adminView && !adding">
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-euro-sign"></i>
          Επιπλέον Χρεώσεις
        </ng-template>
        <ng-template matTabContent>
          <kt-extra-charges [(extraChargesSubject)]="extraChargesSubject"></kt-extra-charges>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </kt-portlet-body>
</kt-portlet>
