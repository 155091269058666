import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mergeMap, takeLast, last, map, tap, delay } from 'rxjs/operators';
import { mapValues, groupBy, omit, sortBy } from 'lodash';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'kt-aggr-fin',
  templateUrl: './aggr-fin.component.html',
  styleUrls: ['./aggr-fin.component.scss'],
})
export class AggrFinComponent implements OnInit {
  @Input() data: any;
  @Input() _type: string;

  @ViewChild('chart') chart: ChartComponent;

  public chartOptions: Partial<any> | (() => Partial<any>);

  constructor() {}

  ngOnInit() {
    const ordered = {};
    const colors: Array<string> = [];

    if ('PENDING' in this.data) {
      ordered['PENDING'] = this.data['PENDING'];
      colors.push('#ce4b23');
    }
    if ('PROCESSED' in this.data) {
      ordered['PROCESSED'] = this.data['PROCESSED'];
      colors.push('#2e9918');
    }
    if ('PAID' in this.data) {
      ordered['PAID'] = this.data['PAID'];
      colors.push('#2bb5cc');
    }
    if ('PAID_APPROVED' in this.data) {
      ordered['PAID_APPROVED'] = this.data['PAID_APPROVED'];
      colors.push('#d53f3f');
    }
    if ('INVOICED' in this.data) {
      ordered['INVOICED'] = this.data['INVOICED'];
      colors.push('#954bd2');
    }
    if ('RETURNED' in this.data) {
      ordered['RETURNED'] = this.data['RETURNED'];
      colors.push('#c97d05');
    }
    if ('CANCELLATION_PENDING' in this.data) {
      ordered['CANCELLATION_PENDING'] = this.data['CANCELLATION_PENDING'];
      colors.push('#d26618');
    }
    if ('CANCELLED' in this.data) {
      ordered['CANCELLED'] = this.data['CANCELLED'];
      colors.push('#c82c2c');
    }

    this.chartOptions = {
      series: [
        {
          name: this._type,
          data: Object.values(ordered),
        },
      ],
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        style: {
          colors: ['#333'],
        },
        offsetY: -25,
        formatter: (val) => '€ ' + val,
      },
      chart: {
        width: 430,
        height: 200,
        //height: 250,
        type: 'bar',
      },
      colors: colors,
      title: {
        text: this._type,
      },
      yaxis: {
        labels: {
          formatter: (val) => '€ ' + val,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: Object.keys(ordered).map((item) => {
          switch (item) {
            case 'PENDING':
              return 'Εκκρεμεί';
              break;
            case 'PROCESSED':
              return 'Εγκρίθηκε';
              break;
            case 'PAID':
              return 'Εισπράχτηκε';
              break;
            case 'PAID_APPROVED':
              return 'Προς Απόδοση';
              break;
            case 'INVOICED':
              return 'Εξοφλήθη';
              break;
            case 'RETURNED':
              return 'Επεστράφη';
              break;
            case 'CANCELLATION_PENDING':
              return 'Προς Ακύρωση';
              break;
            case 'CANCELLED':
              return 'Ακυρωμένη';
              break;
            default:
              return item;
          }
        }),
      },
    };
  }
}
