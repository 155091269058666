// RxJS
import { of, forkJoin } from 'rxjs';
import {
  last,
  takeUntil,
  catchError,
  take,
  finalize,
  tap,
  debounceTime,
  delay,
  distinctUntilChanged,
} from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectShipmentsInStore,
  selectShipmentsPageLoading,
  selectShipmentsShowInitWaitingMessage,
} from '../_selectors/shipment.selectors';
import { selectChargesForShipment } from '../_selectors/charge.selectors';
import { selectCSChargesForShipment } from '../_selectors/cscharge.selectors';
import { selectUserById } from '../../auth/_selectors/user.selectors';
import { User } from '../../auth/_models/user.model';
import { Shipment } from '../_models/shipment.model';
import { Charge } from '../_models/charge.model';
import {
  FetchUser,
  AllUsersRequested,
  AllUsersLoaded,
  UserActionTypes,
} from '../../auth/_actions/user.actions';

import {
  ChargeActionTypes,
  AllChargesLoaded,
  LoadAllCharges,
} from '../_actions/charge.actions';

import {
  CSChargeActionTypes,
  AllCSChargesLoaded,
  LoadAllCSCharges,
} from '../_actions/cscharge.actions';

export function calculateCharge(
  user: User,
  charge: Charge,
  shipment: Shipment,
) {
  const user_vat: number = user.vat_val;
  const static_charge: number = charge
    ? parseFloat('' + charge.static_charge) || 0
    : 0;
  const per_kg_charge: number = charge
    ? parseFloat('' + charge.per_kg_charge) || 0
    : 0;
  const _ws: number = charge ? parseFloat('' + charge.weight_start) || 0 : 0;
  const _we: number = charge ? parseFloat('' + charge.weight_end) || 0 : 0;
  let cod_charge: number = charge ? parseFloat('' + charge.cod_charge) || 0 : 0;

  let kge = 0;
  let kgec = 0;
  const acfd: Array<any> = [];

  if (charge) {
    kge =
      parseFloat('' + shipment.weight) - parseFloat('' + charge.weight_start);
    //kge = parseFloat(kge.toFixed(0));
    kge = Math.ceil(kge);
  }

  if (per_kg_charge > 0 && charge) {
    kgec = kge * charge.per_kg_charge;
  }

  if (!shipment.is_cod || parseFloat('' + shipment.cod_ammount) === 0) {
    cod_charge = 0;
  }

  let extra_charges = 0;

  if (shipment.extra_charges && shipment.extra_charges.length > 0) {
    shipment.extra_charges.forEach((k: any) => {
      extra_charges += parseFloat(k.ammount);
    });
  }

  if (shipment.area_charges && shipment.area_charges.length > 0) {
    shipment.area_charges.forEach((k: any) => {
      //console.log(k);
      if (k.enforce_zone) {
        if (shipment.weight >= k.zone_start && shipment.weight <= k.zone_end) {
          //console.log('asdasda');
          if (k.static_charge) {
            extra_charges += parseFloat('' + k.static_charge);
            acfd.push({
              reason: k.charge_reason,
              items: 1,
              pc: parseFloat('' + k.static_charge),
              tc: parseFloat('' + k.static_charge),
            });
          }

          if (k.per_kg_charge) {
            let kgtc = parseFloat('' + shipment.weight);
            if (k.weight_start) {
              kgtc -= parseFloat('' + k.weight_start);
            }
            kgtc = Math.ceil(kgtc);
            const tac = kgtc * parseFloat('' + k.per_kg_charge);
            //console.log('tac',tac,'kgtc',kgtc);

            if (kgtc > 0) {
              extra_charges += tac;
              acfd.push({
                reason: k.charge_reason,
                items: kgtc,
                pc: parseFloat('' + k.per_kg_charge),
                tc: tac,
              });
            }
          }
        }
      } else {
        if (k.static_charge) {
          extra_charges += parseFloat('' + k.static_charge);
          acfd.push({
            reason: k.charge_reason,
            items: 1,
            pc: parseFloat('' + k.static_charge),
            tc: parseFloat('' + k.static_charge),
          });
        }

        if (k.per_kg_charge) {
          let kgtc = parseFloat('' + shipment.weight);
          if (k.weight_start) {
            kgtc -= parseFloat('' + k.weight_start);
          }
          kgtc = Math.ceil(kgtc);
          const tac = kgtc * parseFloat('' + k.per_kg_charge);
          //console.log('tac',tac,'kgtc',kgtc);

          if (kgtc > 0) {
            extra_charges += tac;
            acfd.push({
              reason: k.charge_reason,
              items: kgtc,
              pc: parseFloat('' + k.per_kg_charge),
              tc: tac,
            });
          }
        }
      }
    });
  }

  const pvat_charge = kgec + static_charge + cod_charge + extra_charges;
  const total_vat: number =
    ((cod_charge + kgec + static_charge + extra_charges) / 100) * user_vat;
  const final_charge: number = pvat_charge + total_vat;

  return {
    ...shipment,
    auser: user,
    ws: _ws,
    we: _we,
    pkg: per_kg_charge,
    ekg: kge,
    ekgc: kgec,
    pvat: pvat_charge,
    codc: cod_charge,
    basec: static_charge,
    tvat: total_vat,
    vat: user_vat,
    ec: extra_charges,
    cost: final_charge,
    acfd: acfd,
  };
}

export class SWChargesDataSource extends BaseDataSource {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private _loadData: boolean = true,
  ) {
    super();

    this.loading$ = this.store.pipe(select(selectShipmentsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectShipmentsShowInitWaitingMessage),
    );

    if (this._loadData) {
      this.loadData();
    }
  }

  loadData() {
    forkJoin(
      this.actions$.pipe(
        ofType<AllUsersLoaded>(UserActionTypes.AllUsersLoaded),
        take(1),
      ),
      this.actions$.pipe(
        ofType<AllChargesLoaded>(ChargeActionTypes.AllChargesLoaded),
        take(1),
      ),
      this.actions$.pipe(
        ofType<AllCSChargesLoaded>(CSChargeActionTypes.AllCSChargesLoaded),
        take(1),
      ),
    ).subscribe(() => {
      this.actions$.pipe;
      this.store
        .pipe(
          select(selectShipmentsInStore),
          //Make sure all users are loaded so we can get their vat
          //takeUntil( this.actions$.pipe( ofType<AllUsersLoaded>(UserActionTypes.AllUsersLoaded) ) ),
          //last(),
        )
        .subscribe((response: QueryResultsModel) => {
          this.paginatorTotalSubject.next(response.totalCount);
          this.entitySubject.next(
            response.items.map((obj) => {
              let charge: any;
              let cscharge: any;

              this.store
                .pipe(select(selectChargesForShipment(obj)), take(1))
                .subscribe((res) => {
                  charge = res;
                });

              this.store
                .pipe(select(selectCSChargesForShipment(obj)), take(1))
                .subscribe((res) => {
                  cscharge = res;
                });

              let user: User;

              this.store
                .pipe(select(selectUserById(obj.user)), take(1))
                .subscribe((res: User) => {
                  user = res;
                });
              //console.log(obj.id , obj.vg_code , cscharge);
              if (cscharge) {
                return calculateCharge(user, cscharge, obj);
              } else {
                return calculateCharge(user, charge, obj);
              }
            }),
          );
        });
    });
    this.store.dispatch(new AllUsersRequested());
    this.store.dispatch(new LoadAllCharges());
    this.store.dispatch(new LoadAllCSCharges());
  }
}
