// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { KgCheckReportState } from '../_reducers/kg_check_report.reducers';
import { each } from 'lodash';
import { KgCheckReport } from '../_models/kg_check_report.model';

export const selectKgCheckReportState =
  createFeatureSelector<KgCheckReportState>('KgCheckReports');

export const selectKgCheckReportById = (id: number) =>
  createSelector(
    selectKgCheckReportState,
    (KgCheckReportState) => KgCheckReportState.entities[id],
  );

export const selectKgCheckReportPageLoading = createSelector(
  selectKgCheckReportState,
  (KgCheckReportState) => {
    return KgCheckReportState.listLoading;
  },
);

export const selectKgCheckReportActionLoading = createSelector(
  selectKgCheckReportState,
  (KgCheckReportState) => KgCheckReportState.actionsloading,
);

export const selectKgCheckReportPageLastQuery = createSelector(
  selectKgCheckReportState,
  (KgCheckReportState) => KgCheckReportState.lastQuery,
);

export const selectKgCheckReportInStore = createSelector(
  selectKgCheckReportState,
  (KgCheckReportState) => {
    const items: KgCheckReport[] = [];
    each(KgCheckReportState.ids, (id: number) => {
      items.push(KgCheckReportState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: KgCheckReport[] = httpExtension.sortArray(
      items,
      KgCheckReportState.lastQuery.sortField,
      KgCheckReportState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, KgCheckReportState.totalCount, '');
  },
);

export const selectKgCheckReportShowInitWaitingMessage = createSelector(
  selectKgCheckReportState,
  (KgCheckReportState) => KgCheckReportState.showInitWaitingMessage,
);

export const selectHasKgCheckReportInStore = createSelector(
  selectKgCheckReportState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
