// Angular
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
// Partials for CRUD
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  ApproveShipmentDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from '../../../../views/partials/content/crud';

import {
  PdfViewBsComponent,
  ShipmentViewBsComponent,
  PickupViewBsComponent,
  InfoViewBsComponent,
} from '../../../../views/partials/ronex';

export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
}

@Injectable()
export class LayoutUtilsService {
  /**
   * Service constructor
   *
   * @param snackBar: MatSnackBar
   * @param dialog: MatDialog
   */
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private bs: MatBottomSheet,
  ) {}

  /**
   * Showing (Mat-Snackbar) Notification
   *
   * @param message: string
   * @param type: MessageType
   * @param duration: number
   * @param showCloseButton: boolean
   * @param showUndoButton: boolean
   * @param undoButtonDuration: number
   * @param verticalPosition: 'top' | 'bottom' = 'top'
   */
  showActionNotification(
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration = 10000,
    _showCloseButton = true,
    _showUndoButton = true,
    _undoButtonDuration = 3000,
    _verticalPosition: 'top' | 'bottom' = 'bottom',
  ) {
    const _data = {
      message: _message,
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      showUndoButton: _showUndoButton,
      undoButtonDuration: _undoButtonDuration,
      verticalPosition: _verticalPosition,
      type: _type,
      action: 'Undo',
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition,
    });
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   *
   * @param title: stirng
   * @param description: stirng
   * @param waitDesciption: string
   */
  deleteElement(title = '', description = '', waitDesciption = '') {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px',
    });
  }

  approveElement(title = '', description = '', waitDesciption = '') {
    return this.dialog.open(ApproveShipmentDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px',
    });
  }

  /**
   * Showing Fetching Window(Mat-Dialog)
   *
   * @param _data: any
   */
  fetchElements(_data) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: _data,
      width: '400px',
    });
  }

  /**
   * Showing Update Status for Entites Window
   *
   * @param title: string
   * @param statuses: string[]
   * @param messages: string[]
   */
  updateStatusForEntities(title, statuses, messages) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: '480px',
    });
  }

  viewVoucher(data: any) {
    this.bs.open(PdfViewBsComponent, { data: data });
  }

  viewShipment(data: any) {
    this.bs.open(ShipmentViewBsComponent, { data: data });
  }

  viewPickupOrder(data: any) {
    this.bs.open(PickupViewBsComponent, { data: data });
  }

  viewInfo(data: any) {
    this.bs.open(InfoViewBsComponent, { data: data });
  }
}
