// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { KgCheckReport } from '../_models/kg_check_report.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum KgCheckReportActionTypes {
  KgCheckReportPageRequested = '[Kg Check Report List Page] Kg Check Report Page Requested',
  KgCheckReportPageLoaded = '[Kg Check Report API] Kg Check Report Page Loaded',
  KgCheckReportPageCancelled = '[Kg Check Report API] Kg Check Report Page Cancelled',
  KgCheckReportPageToggleLoading = '[Kg Check Report] Kg Check Report Page Toggle Loading',
  KgCheckReportActionToggleLoading = '[Kg Check Report] Kg Check Report Action Toggle Loading',
  KCRActionSuccess = '[Kg Check Report API] Last action succeded',
  KCRActionFail = '[Kg Check Report API] Last action failed',
  KCRActionRefresh = '[Kg Check Report API] Emit to all interested parties to refresh all kg check reports',
}

export class KgCheckReportPageRequested implements Action {
  readonly type = KgCheckReportActionTypes.KgCheckReportPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class KgCheckReportPageLoaded implements Action {
  readonly type = KgCheckReportActionTypes.KgCheckReportPageLoaded;
  constructor(
    public payload: {
      KgCheckReports: KgCheckReport[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class KgCheckReportPageCancelled implements Action {
  readonly type = KgCheckReportActionTypes.KgCheckReportPageCancelled;
}

export class KgCheckReportPageToggleLoading implements Action {
  readonly type = KgCheckReportActionTypes.KgCheckReportPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class KgCheckReportActionToggleLoading implements Action {
  readonly type = KgCheckReportActionTypes.KgCheckReportActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class KCRActionRefresh implements Action {
  readonly type = KgCheckReportActionTypes.KCRActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class KCRActionSuccess implements Action {
  readonly type = KgCheckReportActionTypes.KCRActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class KCRActionFail implements Action {
  readonly type = KgCheckReportActionTypes.KCRActionFail;
  constructor(public payload: { result: any }) {}
}

export type KgCheckReportActions =
  | KgCheckReportPageRequested
  | KgCheckReportPageLoaded
  | KgCheckReportPageCancelled
  | KgCheckReportPageToggleLoading
  | KgCheckReportActionToggleLoading
  | KCRActionRefresh
  | KCRActionSuccess
  | KCRActionFail;
