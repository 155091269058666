// Angular
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
// NGRX
import { Store } from '@ngrx/store';
// AppState
import { AppState } from '../../../core/reducers';

// Auth

@Component({
  selector: 'kt-user-management',
  templateUrl: './usr-mngt.component.html',
  styleUrls: ['./usr-mngt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsrMngtComponent {
  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param router: Router
   */
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}
}
