<kt-portlet>
  <kt-portlet-header
    [title]="'Χρήστες'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <a
        (click)="refs()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Ανανέωση Αποστολών"
      >
        <i class="la la-refresh"></i>
        <span class="kt-hidden-mobile">Ανανέωση</span>
      </a>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="row align-items-center">
      <div class="col-md-12 kt-margin-bottom-10-mobile">
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
      </div>
    </div>
    <div class="kt-form">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Αναζήτηση Χρηστών </mat-panel-title>
          <mat-panel-description>
            Αναζήτηση χρηστών και κατάστασης αποστολών.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFCe('change', $event)"
                  [(ngModel)]="dpFC"
                  [matDatepicker]="dpFCP"
                  placeholder="Δημιουργήθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFCP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTCe('change', $event)"
                  [(ngModel)]="dpTC"
                  [matDatepicker]="dpTCP"
                  placeholder="Δημιουργήθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTCP></mat-datepicker>
                <mat-hint align="start"> <strong>Εώς</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFAe('change', $event)"
                  [(ngModel)]="dpFA"
                  [matDatepicker]="dpFAP"
                  placeholder="Εγκρίθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFAP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTAe('change', $event)"
                  [(ngModel)]="dpTA"
                  [matDatepicker]="dpTAP"
                  placeholder="Εγκρίθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTAP></mat-datepicker>
                <mat-hint align="start"> <strong>Εώς</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFPe('change', $event)"
                  [(ngModel)]="dpFP"
                  [matDatepicker]="dpFPP"
                  placeholder="Εισπράχθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFPP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFPP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία είσπραξης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTPe('change', $event)"
                  [(ngModel)]="dpTP"
                  [matDatepicker]="dpTPP"
                  placeholder="Εισπράχτηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTPP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTPP></mat-datepicker>
                <mat-hint align="start"> <strong>Εώς</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFIe('change', $event)"
                  [(ngModel)]="dpFI"
                  [matDatepicker]="dpFIP"
                  placeholder="Εξοφλήθη από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFIP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFIP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία εξόφλησης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTIe('change', $event)"
                  [(ngModel)]="dpTI"
                  [matDatepicker]="dpTIP"
                  placeholder="Εξοφλήθη εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTIP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTIP></mat-datepicker>
                <mat-hint align="start"> <strong>Εώς</strong> ημερομηνία εξόφλησης </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="spend tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PENDING')"
                  value="PENDING"
                >
                  <i class="flaticon2-download mri aicon"></i>
                  Εκκρεμείς
                </mat-button-toggle>
                <mat-button-toggle
                  class="sappr tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PROCESSED')"
                  value="PROCESSED"
                >
                  <i class="flaticon2-black-back-closed-envelope-shape mri"></i>
                  Εγκεκριμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="spaid tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID')"
                  value="PAID"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Εισπραγμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="spaida tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID_APPROVED')"
                  value="PAID_APPROVED"
                >
                  <i class="fas fa-hand-holding-usd mri aicon"></i>
                  Προς Απόδοση
                </mat-button-toggle>
                <mat-button-toggle
                  class="sinv tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('INVOICED')"
                  value="INVOICED"
                >
                  <i class="fas fa-file-invoice-dollar mri aicon"></i>
                  Εξοφλημένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="sreturned tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('RETURNED')"
                  value="RETURNED"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancellation_pending tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLATION_PENDING')"
                  value="CANCELLATION_PENDING"
                >
                  <i class="fas fa-exclamation-circle mri aicon"></i>
                  Προς Ακύρωση
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancelled tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLED')"
                  value="CANCELLED"
                >
                  <i class="fas fa-times-circle mri aicon"></i>
                  Ακυρωμένες
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="deliveredStatusChanged($event)"
                  [checked]="delivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="odeliveredStatusChanged($event)"
                  [checked]="odelivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Μόνο Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="returnedStatusChanged($event)"
                  [checked]="returned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="oreturnedStatusChanged($event)"
                  [checked]="oreturned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Μόνο Επεστραμένες
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #usrInput
                    (keyup)="filterUsers($event)"
                    placeholder="Χρήστης..."
                    [formControl]="usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"> <strong>Αναζήτση</strong> με χρήστες </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>

    <div class="kt-form kt-margin-b-30">
      <div
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: bigBucket.length > 0 }"
      >
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline _mma">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Επιλεγμένες εγγραφές: </span> {{ bigBucket.length }}
              </label>
            </div>
            <div class="kt-form__control kt-form__group--inline _mma">
              <button
                (click)="genXLS()"
                mat-raised-button
                matTooltip="Δημιουργία Excel από τις επιλεγμένες"
                class="xls-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-excel xls-i"></i>
                Δημιουργία Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__bottom">
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <mat-paginator
        [pageSize]="paginator.pageSize"
        [pageSizeOptions]="[10, 50, 100, 200]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>

    <div class="mat-table__wrapper">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        matSortDisableClear
      >
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleSelection(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Ονοματεπώνυμο</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.lastname + " " + user.firstname }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="cods">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Αντικαταβολές</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <kt-aggr-fin [data]="user.financial_data.cod" [_type]="'Αντικαταβολές'"></kt-aggr-fin>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="charges">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Χρεώσεις</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <kt-aggr-fin [data]="user.financial_data.charges" [_type]="'Χρεώσεις'"></kt-aggr-fin>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">Δεν βρέθηκαν εγγραφές</div>
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Παρακαλώ περιμένετε....
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
