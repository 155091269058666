// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  UserwfdActions,
  UserwfdActionTypes,
} from '../_actions/userwfd.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Userwfd } from '../_models/userwfd.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserwfdState extends EntityState<Userwfd> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Userwfd> = createEntityAdapter<Userwfd>();

export const initialUserwfdState: UserwfdState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function userwfdReducer(
  state = initialUserwfdState,
  action: UserwfdActions,
): UserwfdState {
  switch (action.type) {
    case UserwfdActionTypes.UserwfdPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case UserwfdActionTypes.UserwfdActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case UserwfdActionTypes.UserwfdPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case UserwfdActionTypes.UserwfdPageLoaded: {
      return adapter.addMany(action.payload.userwfds, {
        ...initialUserwfdState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getUserwfdState = createFeatureSelector<UserwfdState>('userwfds');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
