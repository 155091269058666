// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { LiquidationState } from '../_reducers/liquidation.reducers';
import { each } from 'lodash';
import { Liquidation } from '../_models/liquidation.model';

export const selectLiquidationState =
  createFeatureSelector<LiquidationState>('liquidations');

export const selectLiquidationById = (id: number) =>
  createSelector(
    selectLiquidationState,
    (liquidationState) => liquidationState.entities[id],
  );

export const selectLiquidationPageLoading = createSelector(
  selectLiquidationState,
  (liquidationState) => {
    return liquidationState.listLoading;
  },
);

export const selectLiquidationActionLoading = createSelector(
  selectLiquidationState,
  (liquidationState) => liquidationState.actionsloading,
);

export const selectLiquidationPageLastQuery = createSelector(
  selectLiquidationState,
  (liquidationState) => liquidationState.lastQuery,
);

export const selectLiquidationsInStore = createSelector(
  selectLiquidationState,
  (liquidationState) => {
    const items: Liquidation[] = [];
    each(liquidationState.ids, (id: number) => {
      items.push(liquidationState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: Liquidation[] = httpExtension.sortArray(
      items,
      liquidationState.lastQuery.sortField,
      liquidationState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, liquidationState.totalCount, '');
  },
);

export const selectLiquidationShowInitWaitingMessage = createSelector(
  selectLiquidationState,
  (liquidationState) => liquidationState.showInitWaitingMessage,
);

export const selectHasLiquidationsInStore = createSelector(
  selectLiquidationState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
