<kt-portlet>
  <kt-portlet-header
    [title]="'Blacklist'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <a
        (click)="refs()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Ανανέωση"
      >
        <i class="la la-refresh"></i>
        <span class="kt-hidden-mobile">Ανανέωση</span>
      </a>
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

  <!-- start::Body (attribute: ktPortletBody) -->
  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="row align-items-center">
      <div class="col-md-12 kt-margin-bottom-10-mobile">
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
      </div>
    </div>

    <div class="container">
      <div class="kt-form kt-margin-b-30">
        <div class="kt-form__filtration">
          <div class="row align-items-center kt-margin-b-30">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση Κωδ. Αποστολής"
                  #searchInputKA
                  placeholder="Κωδ. Αποστολής"
                />
                <mat-hint align="start"> <strong>Αναζήτηση</strong> με Κωδ. Αποστολής </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση Παραλήπτη"
                  #searchInputPAR
                  placeholder="Παραλήπτης"
                />
                <mat-hint align="start"> <strong>Αναζήτηση</strong> με Παραλήπτη </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση Διεύθυνσης"
                  #searchInputADD
                  placeholder="Διεύθυνση"
                />
                <mat-hint align="start"> <strong>Αναζήτηση</strong> με Διεύθυνση </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση με Τ.Κ."
                  #searchInputTK
                  placeholder="Ταχυδρομικός Κώδικας"
                />
                <mat-hint align="start"> <strong>Αναζήτηση</strong> με Τ.Κ </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-4 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση με Τηλέφωνο"
                  #searchInputPHONE
                  placeholder="Τηλέφωνο"
                />
                <mat-hint align="start"> <strong>Αναζήτηση</strong> με Τηλέφωνο </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__bottom">
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <mat-paginator
        [pageSize]="50"
        [pageSizeOptions]="[10, 50, 100, 200]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>

    <div class="mat-table__wrapper">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        matSortActive="name"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Παραλήπτης</mat-header-cell>
          <mat-cell *matCellDef="let blacklist"
            ><span>{{ blacklist.name }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Διεύθυνση</mat-header-cell>
          <mat-cell *matCellDef="let blacklist"
            ><span>{{ blacklist.address }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="postcode">
          <mat-header-cell *matHeaderCellDef>Τ.Κ.</mat-header-cell>
          <mat-cell *matCellDef="let blacklist"
            ><span>{{ blacklist.postcode }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef>Τηλέφωνο</mat-header-cell>
          <mat-cell *matCellDef="let blacklist"
            ><span>{{ blacklist.phone }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="phone2">
          <mat-header-cell *matHeaderCellDef>Δεύτερο Τηλ.</mat-header-cell>
          <mat-cell *matCellDef="let blacklist"
            ><span>{{ blacklist.phone2 }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
          <mat-cell *matCellDef="let blacklist">
            <button
              mat-icon-button
              color="primary"
              matTooltip="Δείτε τις σχετικές αποστολές"
              type="button"
              (click)="viewShipments(blacklist)"
            >
              <i class="fas fa-paper-plane"></i>
            </button>
            <button
              mat-icon-button
              color="warn"
              matTooltip="Διαγραφή Εγγραφής"
              type="button"
              (click)="deleteBlacklist(blacklist)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">Δεν βρέθηκαν εγγραφές</div>
      <!-- Message for empty data  -->
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Παρακαλώ περιμένετε....
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
