export class Notification {
  id: number;
  _type: string;
  _date: Date;
  _icon: string;
  _ctype: string;
  _text: string;
  _router_link: string;

  defaults() {
    this.id = undefined;
    this._date = new Date();
    this._icon = 'flaticon2-notification';
    this._ctype = 'kt-font-brand';
    this._type = 'event';
    this._router_link = '/';
  }

  public equals(other: Notification): boolean {
    return this._type === other._type && this._text === other._text;
  }
}
