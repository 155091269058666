// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  CSChargeActions,
  CSChargeActionTypes,
} from '../_actions/cscharge.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { CSCharge } from '../_models/cscharge.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CSChargeState extends EntityState<CSCharge> {
  totalCount: number;
}

export const adapter: EntityAdapter<CSCharge> = createEntityAdapter<CSCharge>();

export const initialCSChargeState: CSChargeState = adapter.getInitialState({
  totalCount: 0,
});

export function cschargeReducer(
  state = initialCSChargeState,
  action: CSChargeActions,
): CSChargeState {
  switch (action.type) {
    case CSChargeActionTypes.AllCSChargesLoaded:
      return adapter.setAll(action.payload.cscharges, state);
    case CSChargeActionTypes.CSChargesUpsert:
      return adapter.upsertMany(action.payload.cscharges, state);

    default:
      return state;
  }
}

export const getCSChargeState =
  createFeatureSelector<CSChargeState>('cscharges');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
