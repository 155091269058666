export class ExtraCharge {
  id: number;
  reason: string;
  description: string;
  ammount: number;
  iid: string;

  clear() {
    this.id = undefined;
    this.reason = '';
    this.description = '';
    this.ammount = undefined;
    this.iid = undefined;
  }
}
