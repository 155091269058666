<div ngbDropdown [placement]="'bottom-right'" class="dropdown">
  <a
    ngbDropdownToggle
    href="javascript:;"
    class="btn btn-label-brand btn-bold btn-sm dropdown-toggle"
  >
    Export
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
    <ul class="kt-nav">
      <li class="kt-nav__section kt-nav__section--first">
        <span class="kt-nav__section-text">Choose an action:</span>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-graph-1"></i>
          <span class="kt-nav__link-text">Export</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
          <span class="kt-nav__link-text">Save</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-layers-1"></i>
          <span class="kt-nav__link-text">Import</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
          <span class="kt-nav__link-text">Update</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-file-1"></i>
          <span class="kt-nav__link-text">Customize</span>
        </a>
      </li>
    </ul>
  </div>
</div>
