// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UsrMngtModule } from './usr-mngt/usr-mngt.module';
import { ShipmentsModule } from './shipments/shipments.module';
import { UserInterfaceModule } from './user-interface/user-interface.module';
import { AdminSettingsModule } from './admin-settings/admin-settings.module';
@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    UsrMngtModule,
    ShipmentsModule,
    UserInterfaceModule,
    AdminSettingsModule,
  ],
  providers: [],
})
export class PagesModule {}
