// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { NotificationState } from '../_reducers/notification.reducers';
import { each } from 'lodash';
import { Notification } from '../_models/notification.model';

export const selectNotificationState =
  createFeatureSelector<NotificationState>('notification');

//export const selectUserById = (userId: number) => createSelector(
//    selectUsersState,
//    usersState => usersState.entities[userId]
//);

export const selectNotificationsInStore = createSelector(
  selectNotificationState,
  (notificationState) => {
    const items: Notification[] = [];
    each(notificationState.entities, (element) => {
      items.push(element);
    });
    return items;
  },
);

export const selectNotificationBP = createSelector(
  selectNotificationState,
  (notificationState, props) => {
    return Object.values(notificationState.entities).find(
      (n: Notification) => n._text === props._text && n._type === props._type,
    );
  },
);

export const NotificationExists = createSelector(
  selectNotificationState,
  (notificationState, props) => {
    let res = false;
    Object.values(notificationState.entities).map((el) => {
      if (props.on.equals(el)) {
        res = true;
      }
    });
    return res;
  },
);
