// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import { distinctUntilChanged, map, skip, take, tap } from 'rxjs/operators';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
// LODASH
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions } from '@ngrx/effects';

// Services
import {
  LayoutUtilsService,
  QueryParamsModel,
} from '../../../../../core/_base/crud';

// Models and selectors
import {
  Userwfd,
  UserwfdDataSource,
  UserwfdPageRequested,
} from '../../../../../core/ronex';

import {
  AuthService,
  selectUsersInStore,
  User,
} from '../../../../../core/auth';
import { HttpClient } from '@angular/common/http';
import { SubheaderService } from '../../../../../core/_base/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'kt-userwfd',
  templateUrl: './userwfd.component.html',
  styleUrls: ['./userwfd.component.scss'],
})
export class UserwfdComponent implements OnInit, OnDestroy {
  dataSource: UserwfdDataSource;
  displayedColumns = ['select', 'id', 'lastname', 'cods', 'charges'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;

  lastQuery: QueryParamsModel;

  selection = new SelectionModel<Userwfd>(true, []);
  bigBucket: Array<any> = [];
  userwfdResult: Userwfd[] = [];

  private subscriptions: Subscription[] = [];

  serverError: Subject<any>;
  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  separatorKeysCodes: number[] = [ENTER, COMMA];
  usrCtrl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  fUsers: User[] = [];
  fStatus: Array<string> = ['PROCESSED', 'PAID', 'PAID_APPROVED', 'RETURNED'];

  dpFC: any;
  dpTC: any;

  dpFA: any;
  dpTA: any;

  dpFP: any;
  dpTP: any;

  dpFI: any;
  dpTI: any;

  delivered = true;
  odelivered = false;
  returned = true;
  oreturned = false;

  @ViewChild('usrInput') usrInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private actions$: Actions,
    private auth: AuthService,
    private dp: DatePipe,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadUserwfdList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Οικονομικά Χρηστών');

    // Init DataSource
    this.dataSource = new UserwfdDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.userwfdResult = res;
        this.restoreSelection();
      });
    this.subscriptions.push(entitiesSubscription);

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.sort.active = 'id';
    this.sort.direction = 'desc';

    this.loadUserwfdList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  filterUsers(event) {
    const val = event.target.value;
    this.filteredOptions = this._filter(val);
  }

  _filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) => !this.fUsers.some((el) => el.id === usr.id),
          ),
        ),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        take(1),
      );
    }
  }

  refs(): void {
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  remove(usr: User): void {
    const index = this.fUsers.indexOf(usr);
    if (index >= 0) {
      this.fUsers.splice(index, 1);
      this.filteredOptions = this._filter('');
      this.paginator.pageIndex = 0;
      this.clearSelection();
      this.loadUserwfdList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fUsers.push(event.option.value);
    this.usrInput.nativeElement.value = '';
    this.usrCtrl.setValue(null);
    this.paginator.pageIndex = 0;
    this.clearSelection();
    this.loadUserwfdList();
  }

  deliveredStatusChanged(event) {
    if (event.source._checked) {
      this.delivered = true;
    } else {
      this.delivered = false;
    }

    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  odeliveredStatusChanged(event) {
    if (event.source._checked) {
      this.odelivered = true;
    } else {
      this.odelivered = false;
    }

    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  returnedStatusChanged(event) {
    if (event.source._checked) {
      this.returned = true;
    } else {
      this.returned = false;
    }

    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  oreturnedStatusChanged(event) {
    if (event.source._checked) {
      this.oreturned = true;
    } else {
      this.oreturned = false;
    }

    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  fStatusChanged(event) {
    if (event.source._checked) {
      this.fStatus = [...this.fStatus, event.value];
    } else {
      this.fStatus = this.fStatus.filter((e) => e !== event.value);
    }

    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpFCe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFA = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpTCe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTA = event.value || undefined;
    if (this.dpTC) {
      this.dpTC = this.dpTC
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpFAe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFA = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpTAe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTA = event.value || undefined;
    if (this.dpTA) {
      this.dpTA = this.dpTA
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpFPe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFP = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpTPe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTP = event.value || undefined;
    if (this.dpTP) {
      this.dpTP = this.dpTP
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpFIe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFI = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  dpTIe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTI = event.value || undefined;
    if (this.dpTI) {
      this.dpTI = this.dpTI
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadUserwfdList();
  }

  //End filter shit

  loadUserwfdList() {
    this.selection.clear();

    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    this.store.dispatch(new UserwfdPageRequested({ page: queryParams }));

    this.selection.clear();
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};

    filter.uids = this.fUsers.map((usr) => usr.id);
    filter._status = this.fStatus;

    if (!this.delivered) {
      filter.is_delivered = false;
    }

    if (this.odelivered) {
      filter.only_delivered = true;
    }

    if (!this.returned) {
      filter.is_returned = false;
    }

    if (this.oreturned) {
      filter.only_returned = true;
    }

    if (this.dpFC) {
      filter.dpfc = this.dpFC.format();
    }

    if (this.dpTC) {
      filter.dptc = this.dpTC.format();
    }

    if (this.dpFA) {
      filter.dpfa = this.dpFA.format();
    }

    if (this.dpTA) {
      filter.dpta = this.dpTA.format();
    }

    if (this.dpFP) {
      filter.dpfp = this.dpFP.format();
    }

    if (this.dpTP) {
      filter.dptp = this.dpTP.format();
    }

    if (this.dpFI) {
      filter.dpfi = this.dpFI.format();
    }

    if (this.dpTI) {
      filter.dpti = this.dpTI.format();
    }

    return filter;
  }

  genXLS() {
    import('xlsx').then((XLSX) => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const data: Array<Object> = [];

      this.bigBucket.forEach((elem: any) => {
        const o: { [k: string]: any } = {};
        o['ID'] = elem.id;
        o['Ονοματεπώνυμο'] = elem.firstname + ' ' + elem.lastname;
        o['Αντ. Εκκρεμεί'] = elem.financial_data.cod.PENDING || 0;
        o['Αντ. Εγκρίθηκε'] = elem.financial_data.cod.PROCESSED || 0;
        o['Αντ. Εισπράχτηκε'] = elem.financial_data.cod.PAID || 0;
        o['Αντ. Προς Απόδοση'] = elem.financial_data.cod.PAID_APPROVED || 0;
        o['Αντ. Εξοφλήθη'] = elem.financial_data.cod.INVOICED || 0;
        o['Αντ. Επεστράφη'] = elem.financial_data.cod.RETURNED || 0;
        o['Αντ. Προς Ακύρωση'] =
          elem.financial_data.cod.CANCELLATION_PENDING || 0;
        o['Αντ. Ακυρωμένη'] = elem.financial_data.cod.CANCELLED || 0;
        o['Χρ. Εκκρεμεί'] = elem.financial_data.charges.PENDING || 0;
        o['Χρ. Εγκρίθηκε'] = elem.financial_data.charges.PROCESSED || 0;
        o['Χρ. Εισπράχτηκε'] = elem.financial_data.charges.PAID || 0;
        o['Χρ. Προς Απόδοση'] = elem.financial_data.charges.PAID_APPROVED || 0;
        o['Χρ. Εξοφλήθη'] = elem.financial_data.charges.INVOICED || 0;
        o['Χρ. Επεστράφη'] = elem.financial_data.charges.RETURNED || 0;
        o['Χρ. Προς Ακύρωση'] =
          elem.financial_data.charges.CANCELLATION_PENDING || 0;
        o['Χρ. Ακυρωμένη'] = elem.financial_data.charges.CANCELLED || 0;
        data.push(o);
      });
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook: import('xlsx').WorkBook = XLSX.utils.book_new();
      const wscols = [
        { width: 7 },
        { width: 20 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
      ];
      workSheet['!cols'] = wscols;
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Οικονομικά Χρηστών');
      XLSX.writeFile(
        workBook,
        this.makeid(10) + '-' + this.makeid(4) + '.xlsx',
      );
    });
  }

  makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }

  //Handle bigBucket Selection
  toggleSelection(row: any) {
    this.selection.toggle(row);

    if (this.selection.isSelected(row)) {
      //Push object copy to the bucket
      this.bigBucket.push({ ...row });
    } else {
      //Remove object from the bucket
      this.bigBucket = this.bigBucket.filter((item) => item.id !== row.id);
    }
  }

  clearSelection() {
    this.bigBucket.splice(0, this.bigBucket.length);
    this.selection.clear();
  }

  masterToggle() {
    if (this.selection.selected.length === this.userwfdResult.length) {
      this.selection.selected.forEach(
        (k) => (this.bigBucket = this.bigBucket.filter((l) => l.id !== k.id)),
      );
      this.selection.clear();
    } else {
      this.userwfdResult.forEach((row) => {
        if (!this.selection.isSelected(row)) {
          this.selection.select(row);
          this.bigBucket.push({ ...row });
        }
      });
    }
  }

  restoreSelection() {
    this.userwfdResult.forEach((sh) => {
      if (this.bigBucket.filter((it) => it.id === sh.id).length > 0) {
        this.selection.select(sh);
      }
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.userwfdResult.length;
    return numSelected === numRows;
  }

  s2ab(s: any): any {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf); //create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }
}
