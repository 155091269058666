// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  UserfileActions,
  UserfileActionTypes,
} from '../_actions/userfile.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Userfile } from '../_models/userfile.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserfileState extends EntityState<Userfile> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedUserfileId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Userfile> = createEntityAdapter<Userfile>();

export const initialUserfileState: UserfileState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  lastCreatedUserfileId: undefined,
  showInitWaitingMessage: true,
});

export function userfileReducer(
  state = initialUserfileState,
  action: UserfileActions,
): UserfileState {
  switch (action.type) {
    case UserfileActionTypes.UserfilesPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedUserfileId: undefined,
      };
    case UserfileActionTypes.UserfilesActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    case UserfileActionTypes.UserfilesPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };
    case UserfileActionTypes.UserfileRemove:
      return adapter.removeOne(action.payload.userfile.id, state);
    case UserfileActionTypes.UserfileMassRemove:
      return adapter.removeMany(action.payload.ids, state);
    case UserfileActionTypes.UserfileUpsert:
      return adapter.upsertOne(action.payload.userfile, {
        ...state,
        lastCreatedUserfileId: action.payload.userfile.id,
      });
    case UserfileActionTypes.UserfilesPageLoaded: {
      return adapter.addMany(action.payload.userfiles, {
        ...initialUserfileState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }
    default:
      return state;
  }
}

export const getUserfileState =
  createFeatureSelector<UserfileState>('userfiles');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
