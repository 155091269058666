// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import {
  ShipmentActions,
  ShipmentActionTypes,
} from '../_actions/shipment.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Shipment } from '../_models/shipment.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ShipmentsState extends EntityState<Shipment> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedShipmentId: number;
  lastQuery: QueryParamsModel;
  listFilters: any;
  financialFilters: any;
  userFilters: any;
  userFinancialFilters: any;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Shipment> = createEntityAdapter<Shipment>();

export const initialShipmentsState: ShipmentsState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  listFilters: {},
  userFilters: {},
  financialFilters: {},
  userFinancialFilters: {},
  lastCreatedShipmentId: undefined,
  showInitWaitingMessage: true,
});

export function shipmentsReducer(
  state = initialShipmentsState,
  action: ShipmentActions,
): ShipmentsState {
  switch (action.type) {
    case ShipmentActionTypes.ShipmentsPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedShipmentId: undefined,
      };
    case ShipmentActionTypes.ShipmentsActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    case ShipmentActionTypes.ShipmentsPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };
    case ShipmentActionTypes.ShipmentUpsert:
      return adapter.upsertOne(action.payload.shipment, {
        ...state,
        lastCreatedShipmentId: action.payload.shipment.id,
      });
    case ShipmentActionTypes.ShipmentRemove:
      return adapter.removeOne(action.payload.shipment.id, state);
    case ShipmentActionTypes.ShipmentMassRemove:
      return adapter.removeMany(action.payload.ids, state);
    case ShipmentActionTypes.ShipmentMassUpsert:
      return adapter.upsertMany(action.payload.shipments, state);

    case ShipmentActionTypes.SetListFilters:
      return {
        ...state,
        listFilters: action.payload.filters,
      };

    case ShipmentActionTypes.SetUserFilters:
      return {
        ...state,
        userFilters: action.payload.filters,
      };

    case ShipmentActionTypes.SetFinancialFilters:
      return {
        ...state,
        financialFilters: action.payload.filters,
      };

    case ShipmentActionTypes.SetUserFinancialFilters:
      return {
        ...state,
        userFinancialFilters: action.payload.filters,
      };

    case ShipmentActionTypes.ShipmentsPageLoaded: {
      return adapter.addMany(action.payload.shipments, {
        ...initialShipmentsState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        listFilters: state.listFilters,
        userFilters: state.userFilters,
        financialFilters: state.financialFilters,
        userFinancialFilters: state.userFinancialFilters,
        showInitWaitingMessage: false,
      });
    }
    default:
      return state;
  }
}

export const getShipmentState =
  createFeatureSelector<ShipmentsState>('shipments');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
