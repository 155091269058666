import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  GCActionFail,
  GCActionSuccess,
  GConfig,
  GConfigActionTypes,
  GConfigBulkUpdate,
  selectGConfigById,
} from '../../../../../../core/ronex';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { Actions, ofType } from '@ngrx/effects';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-acs2',
  templateUrl: './acs2.component.html',
  styleUrls: ['./acs2.component.scss'],
})
export class Acs2Component implements OnInit, OnDestroy {
  @Input() saveEvent: Observable<void>;

  acs2_api_endpoint: GConfig = new GConfig();
  acs2_api_key: GConfig = new GConfig();
  acs2_company_id: GConfig = new GConfig();
  acs2_company_password: GConfig = new GConfig();
  acs2_user_id: GConfig = new GConfig();
  acs2_user_password: GConfig = new GConfig();
  acs2_billing_code: GConfig = new GConfig();

  hasFormErrors = false;
  serverError: Subject<any>;
  csForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private actions$: Actions,
    private csFB: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.saveEvent.subscribe(() => this.save());
    this.subscriptions.push(ss);

    this.fetchDataFromStore();
    this.createForm();

    const sss = this.actions$
      .pipe(
        ofType<GCActionSuccess>(GConfigActionTypes.GCActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(sss);

    const sf = this.actions$
      .pipe(
        ofType<GCActionFail>(GConfigActionTypes.GCActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);
  }

  fetchDataFromStore() {
    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_API_ENDPOINT')),
        take(1),
        tap((res) => (this.acs2_api_endpoint = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_API_KEY')),
        take(1),
        tap((res) => (this.acs2_api_key = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_COMPANY_ID')),
        take(1),
        tap((res) => (this.acs2_company_id = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_COMPANY_PASSWORD')),
        take(1),
        tap((res) => (this.acs2_company_password = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_USER_ID')),
        take(1),
        tap((res) => (this.acs2_user_id = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_USER_PASSWORD')),
        take(1),
        tap((res) => (this.acs2_user_password = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__ACS2_BILLING_CODE')),
        take(1),
        tap((res) => (this.acs2_billing_code = { ...res })),
      )
      .subscribe();
  }

  createForm() {
    const fgd: { [k: string]: any } = {
      acs2_api_endpoint: [this.acs2_api_endpoint.value, Validators.required],
      acs2_api_key: [this.acs2_api_key.value, Validators.required],
      acs2_company_id: [this.acs2_company_id.value, Validators.required],
      acs2_company_password: [
        this.acs2_company_password.value,
        Validators.required,
      ],
      acs2_user_id: [this.acs2_user_id.value, Validators.required],
      acs2_user_password: [this.acs2_user_password.value, Validators.required],
      acs2_billing_code: [this.acs2_billing_code.value, Validators.required],
    };

    this.csForm = this.csFB.group(fgd);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  save() {
    this.hasFormErrors = false;
    this.serverError.next(false);

    const controls = this.csForm.controls;
    if (this.csForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      return;
    }

    this.acs2_api_endpoint.value = controls.acs2_api_endpoint.value;
    this.acs2_api_key.value = controls.acs2_api_key.value;
    this.acs2_company_id.value = controls.acs2_company_id.value;
    this.acs2_company_password.value = controls.acs2_company_password.value;
    this.acs2_user_id.value = controls.acs2_user_id.value;
    this.acs2_user_password.value = controls.acs2_user_password.value;
    this.acs2_billing_code.value = controls.acs2_billing_code.value;

    const buo: { [key: string]: any } = {};
    buo[this.acs2_api_endpoint.identifier] = this.acs2_api_endpoint.value;
    buo[this.acs2_api_key.identifier] = this.acs2_api_key.value;
    buo[this.acs2_company_id.identifier] = this.acs2_company_id.value;
    buo[this.acs2_company_password.identifier] =
      this.acs2_company_password.value;
    buo[this.acs2_user_id.identifier] = this.acs2_user_id.value;
    buo[this.acs2_user_password.identifier] = this.acs2_user_password.value;
    buo[this.acs2_billing_code.identifier] = this.acs2_billing_code.value;

    this.store.dispatch(new GConfigBulkUpdate({ values: buo }));
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.serverError.next(false);
  }
}
