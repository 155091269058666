<div autoClose="outside" class="kt-header__topbar-item" ngbDropdown placement="bottom">
  <div class="kt-header__topbar-wrapper" ngbDropdownToggle>
    <span
      [ngClass]="{
        'kt-pulse kt-pulse--brand': pulse,
        'kt-pulse--light': pulseLight,
        'kt-header__topbar-icon--success': iconType === 'success'
      }"
      class="kt-header__topbar-icon"
    >
      <!--i *ngIf="!useSVG" [ngClass]="icon"></i>
            <span *ngIf="useSVG" class="kt-svg-icon" [ngClass]="{'kt-svg-icon--success' : iconType === 'success'}" [inlineSVG]="icon"></span-->
      <span [ngClass]="{ 'kt-svg-icon--success': iconType === 'success' }" class="kt-svg-icon">
        <svg
          height="24px"
          version="1.1"
          viewBox="0 0 24 24"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
          <title>Stockholm-icons / Code / Compiling</title>
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g
            fill="none"
            fill-rule="evenodd"
            id="Stockholm-icons-/-Code-/-Compiling"
            stroke="none"
            stroke-width="1"
          >
            <rect height="24" id="bound" width="24" x="0" y="0"></rect>
            <path
              d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
              fill="#000000"
              id="Combined-Shape"
              opacity="0.3"
            ></path>
            <path
              d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
              fill="#000000"
              id="Combined-Shape"
            ></path>
          </g>
        </svg>
      </span>
      <span [hidden]="!pulse" class="kt-pulse__ring"></span>
    </span>
    <span
      [hidden]="!dot"
      class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand"
    ></span>
  </div>

  <div
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
    ngbDropdownMenu
  >
    <form>
      <!--begin: Head -->
      <div
        [ngStyle]="{ 'background-image': backGroundStyle() }"
        class="kt-head kt-head--skin-{{ skin }} kt-head--fit-x kt-head--fit-b"
      >
        <h3 class="kt-head__title">
          Ειδοποιήσεις&nbsp;
          <span
            (click)="clearNotifications()"
            *ngIf="pulse"
            class="btn btn-{{ type }} btn-sm btn-bold btn-font-md"
            >{{ (notifications$ | async)?.length }} Νέες</span
          >
        </h3>
      </div>
      <!--end: Head -->

      <mat-tab-group>
        <mat-tab id="tab-id-1">
          <ng-template mat-tab-label>
            <span class="ntab-control">Ειδοποιήσεις</span>
          </ng-template>
          <div
            *ngIf="(alerts$ | async)?.length > 0"
            [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
            [perfectScrollbar]="{ wheelPropagation: false }"
            class="kt-notification kt-margin-t-10 kt-margin-b-10"
          >
            <ng-container *ngFor="let item of alerts$ | async">
              <a
                (click)="removeNotification(item?.id)"
                *ngIf="!checkSpecial(item?._router_link)"
                class="kt-notification__item"
                href="javascript:"
                routerLink="{{ item._router_link }}"
              >
                <div class="kt-notification__item-icon">
                  <i class="{{ item._icon }}  {{ item._ctype }}"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    {{ item._text }}
                  </div>
                  <div class="kt-notification__item-time">
                    {{ item._date | date }}
                  </div>
                </div>
              </a>
              <a
                (click)="handleSpecial(item)"
                *ngIf="checkSpecial(item?._router_link)"
                class="kt-notification__item"
                href="javascript:"
              >
                <div class="kt-notification__item-icon">
                  <i class="{{ item._icon }}  {{ item._ctype }}"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    {{ item._text }}
                  </div>
                  <div class="kt-notification__item-time">
                    {{ item._date | date }}
                  </div>
                </div>
              </a>
            </ng-container>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
              <div class="ps__thumb-x" style="left: 0px; width: 0px" tabindex="0"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 0px">
              <div class="ps__thumb-y" style="top: 0px; height: 0px" tabindex="0"></div>
            </div>
          </div>
          <div
            *ngIf="(alerts$ | async)?.length === 0"
            class="kt-grid kt-grid--ver"
            style="min-height: 200px"
          >
            <div
              class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle"
            >
              <div class="kt-grid__item kt-grid__item--middle kt-align-center">
                Δεν υπάρχουν νέα
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab id="tab-id-2">
          <ng-template mat-tab-label>
            <span class="ntab-control">Συμβάντα</span>
          </ng-template>
          <div
            *ngIf="(events$ | async)?.length > 0"
            [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
            [perfectScrollbar]="{ wheelPropagation: false }"
            class="kt-notification kt-margin-t-10 kt-margin-b-10"
          >
            <ng-container *ngFor="let item of events$ | async">
              <a
                (click)="removeNotification(item?.id)"
                *ngIf="!checkSpecial(item?._router_link)"
                class="kt-notification__item"
                href="javascript:"
                routerLink="{{ item._router_link }}"
              >
                <div class="kt-notification__item-icon">
                  <i class="{{ item._icon }}  {{ item._ctype }}"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    {{ item._text }}
                  </div>
                  <div class="kt-notification__item-time">
                    {{ item._date | date }}
                  </div>
                </div>
              </a>
              <a
                (click)="handleSpecial(item)"
                *ngIf="checkSpecial(item?._router_link)"
                class="kt-notification__item"
                href="javascript:"
              >
                <div class="kt-notification__item-icon">
                  <i class="{{ item._icon }}  {{ item._ctype }}"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    {{ item._text }}
                  </div>
                  <div class="kt-notification__item-time">
                    {{ item._date | date }}
                  </div>
                </div>
              </a>
            </ng-container>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
              <div class="ps__thumb-x" style="left: 0px; width: 0px" tabindex="0"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 0px">
              <div class="ps__thumb-y" style="top: 0px; height: 0px" tabindex="0"></div>
            </div>
          </div>

          <div
            *ngIf="(events$ | async)?.length === 0"
            class="kt-grid kt-grid--ver"
            style="min-height: 200px"
          >
            <div
              class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle"
            >
              <div class="kt-grid__item kt-grid__item--middle kt-align-center">
                Δεν υπάρχουν νέα
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab id="tab-id-3">
          <ng-template mat-tab-label>
            <span class="ntab-control">Καταγραφές</span>
          </ng-template>
          <div
            *ngIf="(logs$ | async)?.length > 0"
            [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
            [perfectScrollbar]="{ wheelPropagation: false }"
            class="kt-notification kt-margin-t-10 kt-margin-b-10"
          >
            <a
              (click)="removeNotification(item?.id)"
              *ngFor="let item of logs$ | async"
              class="kt-notification__item"
              href="javascript:"
              routerLink="{{ item._router_link }}"
            >
              <div class="kt-notification__item-icon">
                <i class="{{ item._icon }}  {{ item._ctype }}"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  {{ item._text }}
                </div>
                <div class="kt-notification__item-time">
                  {{ item._date | date }}
                </div>
              </div>
            </a>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
              <div class="ps__thumb-x" style="left: 0px; width: 0px" tabindex="0"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 0px">
              <div class="ps__thumb-y" style="top: 0px; height: 0px" tabindex="0"></div>
            </div>
          </div>
          <div
            *ngIf="(logs$ | async)?.length === 0"
            class="kt-grid kt-grid--ver"
            style="min-height: 200px"
          >
            <div
              class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle"
            >
              <div class="kt-grid__item kt-grid__item--middle kt-align-center">
                Δεν υπάρχουν νέα
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>
