import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { CSCharge } from '../_models/cscharge.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CSChargeService {
  constructor(private http: HttpClient) {}

  getAllCSCharges(): Observable<CSCharge[]> {
    return this.http.get<CSCharge[]>('api/charges/cs/');
  }

  createCSCharges(charges: Array<CSCharge>): Observable<CSCharge[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/charges/cs/add_set/',
      { set: charges },
      { headers: httpHeaders },
    );
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
