<kt-portlet>
  <kt-portlet-header [title]="'Ρυθμίσεις API'" [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTools>
      <a
        href="javascript:;"
        class="btn btn-primary kt-margin-r-10"
        color="primary"
        (click)="saveSubject.next()"
        mat-raised-button
        matTooltip="Αποθήκευση"
      >
        <i class="la la-save"></i>
        <span class="kt-hidden-mobile">Αποθήκευση</span>
      </a>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          ΕΛΤΑ Courier
        </ng-template>
        <ng-template matTabContent>
          <kt-elta [saveEvent]="saveSubject.asObservable()"></kt-elta>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          ΤΑΧΥΔΕΜΑ
        </ng-template>
        <ng-template matTabContent>
          <kt-taxydema [saveEvent]="saveSubject.asObservable()"></kt-taxydema>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          DIAKINISIS
        </ng-template>
        <ng-template matTabContent>
          <kt-diakinisis [saveEvent]="saveSubject.asObservable()"></kt-diakinisis>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          ACS1
        </ng-template>
        <ng-template matTabContent>
          <kt-acs1 [saveEvent]="saveSubject.asObservable()"></kt-acs1>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          ACS2
        </ng-template>
        <ng-template matTabContent>
          <kt-acs2 [saveEvent]="saveSubject.asObservable()"></kt-acs2>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          ACS3
        </ng-template>
        <ng-template matTabContent>
          <kt-acs3 [saveEvent]="saveSubject.asObservable()"></kt-acs3>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          Speedex
        </ng-template>
        <ng-template matTabContent>
          <kt-speedex [saveEvent]="saveSubject.asObservable()"></kt-speedex>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          Courier Center
        </ng-template>
        <ng-template matTabContent>
          <kt-courier-center [saveEvent]="saveSubject.asObservable()"></kt-courier-center>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="mat-tab-label-icon fas fa-shipping-fast"></i>
          Παλιά Speedex
        </ng-template>
        <ng-template matTabContent>
          <kt-speedex2 [saveEvent]="saveSubject.asObservable()"></kt-speedex2>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </kt-portlet-body>
</kt-portlet>
