<!--suppress ALL -->
<kt-portlet>
  <!-- PORTLET LOADING | Binded to TABLE Datasource -->
  <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
  <kt-portlet-header
    [title]="'Αποστολές'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <span class="sp"
        ><mat-spinner color="primary" *ngIf="isPrinting | async" diameter="25"></mat-spinner
      ></span>
      <a
        (click)="refs()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Ανανέωση Αποστολών"
      >
        <i class="la la-refresh"></i>
        <span class="kt-hidden-mobile">Ανανέωση</span>
      </a>

      <a
        (click)="clearFilters()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Αφαίρεση Φίλτρων"
      >
        <i class="far fa-calendar-minus"></i>
        <span class="kt-hidden-mobile">Καθαρισμός Φίλτρων</span>
      </a>
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        type="file"
        id="file"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        #fileInputTaxyPaid
        (change)="onTaxyPaidSelected($event)"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="file_taxy"
        type="file"
      />
      <input
        #fileInputACSPaid
        (change)="onACSPaidSelected($event)"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="file_acs"
        type="file"
      />
      <input
        #fileInputACSCheck
        (change)="onACSCheckSelected($event)"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="file_acs_check"
        type="file"
      />
      <input
        #fileInputGenikiPaid
        (change)="onGenikiPaidSelected($event)"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="file_geniki"
        type="file"
      />
      <input
        #fileInputGenikiApproved
        (change)="onGenikiApprovedSelected($event)"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        id="file_geniki_app"
        type="file"
      />
      <input
        hidden
        (change)="onSPDXPaidSelected($event)"
        #fileInputSPDXPaid
        type="file"
        id="file_spdx"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onSPDXValidateSelected($event)"
        #fileInputSPDXValidate
        type="file"
        id="file_spdx_validate"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onSendDeliveredSelected($event)"
        #fileInputSendDelivered
        type="file"
        id="file_send_delivered"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onReturnDeliveredSelected($event)"
        #fileInputReturnDelivered
        type="file"
        id="file_return_delivered"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onCkeckKgSelected($event)"
        #fileInputCheckKg
        type="file"
        id="file_check_kg"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onCSPaidSelected($event)"
        #fileInputCSPaid
        type="file"
        id="file_cs"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        hidden
        (change)="onXLSSearchSelected($event)"
        #fileInputXLSSearch
        type="file"
        id="file_search"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
      <!-- mat-raised-button | Rectangular contained button w/ elevation  -->
      <button *ngIf="adminView" mat-raised-button color="warn" [matMenuTriggerFor]="menu">
        <mat-icon>description</mat-icon>
        XLS
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="xls-menu">
        <button mat-menu-item class="elta-red" (click)="fileInput.click()">
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες ΕΛΤΑ</span>
        </button>
        <mat-divider></mat-divider>
        <button (click)="fileInputTaxyPaid.click()" class="taxy-orange" mat-menu-item>
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες ΤΑΧΥΔΕΜΑ/DIAKINISIS</span>
        </button>
        <mat-divider></mat-divider>
        <button (click)="fileInputACSPaid.click()" class="elta-red" mat-menu-item>
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες ACS</span>
        </button>
        <button (click)="fileInputACSCheck.click()" class="elta-red" mat-menu-item>
          <i class="fas fa-balance-scale-right"></i>
          <span>Έλεγχος Βάρους ACS</span>
        </button>
        <mat-divider></mat-divider>
        <button (click)="fileInputGenikiApproved.click()" class="speedex-green" mat-menu-item>
          <i class="fas fa-money-check-alt"></i>
          <span>Εγκεκριμένες ΓΕΝΙΚΗ ΤΑΧΥΔΡΟΜΙΚΗ</span>
        </button>
        <button (click)="fileInputGenikiPaid.click()" class="speedex-green" mat-menu-item>
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες ΓΕΝΙΚΗ ΤΑΧΥΔΡΟΜΙΚΗ</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="cs-blue" (click)="fileInputCSPaid.click()">
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες Courier Center</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="speedex-green" (click)="fileInputSPDXPaid.click()">
          <i class="fas fa-money-check-alt"></i>
          <span>Εισπραγμένες SPEEDEX</span>
        </button>
        <button mat-menu-item class="speedex-green" (click)="fileInputSPDXValidate.click()">
          <i class="fas fa-tasks"></i>
          <span>Διασταύρωση SPEEDEX</span>
        </button>
        <button mat-menu-item class="speedex-green" (click)="fileInputCheckKg.click()">
          <i class="fas fa-balance-scale-right"></i>
          <span>Έλεγχος Βάρους SPEEDEX</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="general-blue" (click)="fileInputSendDelivered.click()">
          <i class="fas fa-dolly"></i>
          <span>Παράδοση Αποστολών</span>
        </button>
        <button mat-menu-item class="return-yellow" (click)="fileInputReturnDelivered.click()">
          <i class="fas fa-undo-alt"></i>
          <span>Παράδοση Επιστροφών</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="general-blue" (click)="fileInputXLSSearch.click()">
          <i class="fas fa-search"></i>
          <span>Αναζήτηση</span>
        </button>
      </mat-menu>
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

  <!-- start::Body (attribute: ktPortletBody) -->
  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="row align-items-center">
      <div class="col-md-12 kt-margin-bottom-10-mobile">
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
        <kt-alert
          *ngIf="customWarning | async as warn"
          type="accent"
          [showCloseButton]="true"
          [duration]="20000"
          (close)="onWarningClose($event)"
        >
          {{ warn }}
        </kt-alert>
        <kt-alert
          *ngIf="customInfo | async as info"
          type="primary"
          [showCloseButton]="true"
          [duration]="120000"
          (close)="onInfoClose($event)"
        >
          {{ info }}
        </kt-alert>
        <kt-alert
          *ngIf="customInfo2 | async as info"
          type="primary"
          [showCloseButton]="true"
          [duration]="120000"
          (close)="onInfoClose2($event)"
        >
          {{ info }}
        </kt-alert>
        <kt-alert
          *ngIf="customInfo3 | async as info"
          type="primary"
          [showCloseButton]="true"
          [duration]="120000"
          (close)="onInfoClose3($event)"
        >
          {{ info }}
        </kt-alert>
        <kt-alert
          *ngIf="customInfo4 | async as info"
          type="primary"
          [showCloseButton]="true"
          [duration]="120000"
          (close)="onInfoClose4($event)"
        >
          {{ info }}
        </kt-alert>
      </div>
    </div>

    <div class="kt-form">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Αναζήτηση Αποστολών</mat-panel-title>
          <mat-panel-description>
            Αναζήτηση αποστολών και με απλά και σύνθετα φίλτρα.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- start::FILTERS -->
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-4 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput #searchInput placeholder="Παραλήπτης" />
                <mat-hint align="start"><strong>Αναζήτση</strong> με παραλήπτη</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-4 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Τηλέφωνο Παραλήπτη" #searchInputTel />
                <mat-hint align="start"><strong>Αναζήτση</strong> με τηλέφωνο παραλήπτη</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-4 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-label>Επιλογή Courier</mat-label>
                <mat-select [(value)]="courier" (selectionChange)="courierChange($event)">
                  <mat-option>Οποιοδήποτε</mat-option>
                  <mat-option value="ELTA"> ΕΛΤΑ Courier</mat-option>
                  <mat-option value="SPEEDEX"> SPEEDEX</mat-option>
                  <mat-option value="COURIER_CENTER"> COURIER CENTER</mat-option>
                  <mat-option value="TAXYDEMA"> ΤΑΧΥΔΕΜΑ</mat-option>
                  <mat-option value="DIAKINISIS"> DIAKINISIS</mat-option>
                  <mat-option value="GENIKI"> ΓΕΝΙΚΗ ΤΑΧΥΔΡΟΜΙΚΗ</mat-option>
                  <mat-option value="ACS1"> ACS1</mat-option>
                  <mat-option value="ACS2"> ACS2</mat-option>
                  <mat-option value="ACS3"> ACS3</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFAe('change', $event)"
                  [(ngModel)]="dpFA"
                  [matDatepicker]="dpFAP"
                  placeholder="Εγκρίθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFAP"></mat-datepicker-toggle>
                <mat-datepicker #dpFAP></mat-datepicker>
                <mat-hint align="start"><strong>Από</strong> ημερομηνία έγκρισης</mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTAe('change', $event)"
                  [(ngModel)]="dpTA"
                  [matDatepicker]="dpTAP"
                  placeholder="Εγκρίθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTAP"></mat-datepicker-toggle>
                <mat-datepicker #dpTAP></mat-datepicker>
                <mat-hint align="start"><strong>Εώς</strong> ημερομηνία έγκρισης</mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFRe('change', $event)"
                  [(ngModel)]="dpFR"
                  [matDatepicker]="dpFRP"
                  placeholder="Επεστράφη από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFRP"></mat-datepicker-toggle>
                <mat-datepicker #dpFRP></mat-datepicker>
                <mat-hint align="start"><strong>Από</strong> ημερομηνία επιστροφής</mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTRe('change', $event)"
                  [(ngModel)]="dpTR"
                  [matDatepicker]="dpTRP"
                  placeholder="Επεστράφη εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTRP"></mat-datepicker-toggle>
                <mat-datepicker #dpTRP></mat-datepicker>
                <mat-hint align="start"><strong>Εώς</strong> ημερομηνία επιστροφής</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div *ngIf="adminView" class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFPe('change', $event)"
                  [(ngModel)]="dpFP"
                  [matDatepicker]="dpFPP"
                  placeholder="Εισπράχθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFPP"></mat-datepicker-toggle>
                <mat-datepicker #dpFPP></mat-datepicker>
                <mat-hint align="start"><strong>Από</strong> ημερομηνία είσπραξης</mat-hint>
              </mat-form-field>
            </div>
            <div *ngIf="adminView" class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTPe('change', $event)"
                  [(ngModel)]="dpTP"
                  [matDatepicker]="dpTPP"
                  placeholder="Εισπράχτηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTPP"></mat-datepicker-toggle>
                <mat-datepicker #dpTPP></mat-datepicker>
                <mat-hint align="start"><strong>Εώς</strong> ημερομηνία έγκρισης</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFIe('change', $event)"
                  [(ngModel)]="dpFI"
                  [matDatepicker]="dpFIP"
                  placeholder="Εξοφλήθη από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFIP"></mat-datepicker-toggle>
                <mat-datepicker #dpFIP></mat-datepicker>
                <mat-hint align="start"><strong>Από</strong> ημερομηνία εξόφλησης</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTIe('change', $event)"
                  [(ngModel)]="dpTI"
                  [matDatepicker]="dpTIP"
                  placeholder="Εξοφλήθη εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTIP"></mat-datepicker-toggle>
                <mat-datepicker #dpTIP></mat-datepicker>
                <mat-hint align="start"><strong>Εώς</strong> ημερομηνία εξόφλησης</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="adminView" class="row align-items-center mbr">
            <div *ngIf="adminView" class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFSDe('change', $event)"
                  [(ngModel)]="dpFSD"
                  [matDatepicker]="dpFSDP"
                  placeholder="Παράδοση Αποστ. από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFSDP"></mat-datepicker-toggle>
                <mat-datepicker #dpFSDP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Από</strong> ημερομηνία παράδοσης αποστολής
                </mat-hint>
              </mat-form-field>
            </div>
            <div *ngIf="adminView" class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTSDe('change', $event)"
                  [(ngModel)]="dpTSD"
                  [matDatepicker]="dpTSDP"
                  placeholder="Παράδοση Αποστ. εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTSDP"></mat-datepicker-toggle>
                <mat-datepicker #dpTSDP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Εώς</strong> ημερομηνία παράδοσης αποστολής
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFRDe('change', $event)"
                  [(ngModel)]="dpFRD"
                  [matDatepicker]="dpFRDP"
                  placeholder="Παράδοση Επιστ. από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFRDP"></mat-datepicker-toggle>
                <mat-datepicker #dpFRDP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Από</strong> ημερομηνία παράδοσης επιστροφής
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTRDe('change', $event)"
                  [(ngModel)]="dpTRD"
                  [matDatepicker]="dpTRDP"
                  placeholder="Παράδοση Επιστ. εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTRDP"></mat-datepicker-toggle>
                <mat-datepicker #dpTRDP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Εώς</strong> ημερομηνία παράδοσης επιστροφής
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="adminView" class="row align-items-center mbr">
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFADe('change', $event)"
                  [(ngModel)]="dpFAD"
                  [matDatepicker]="dpFADP"
                  placeholder="Παράδοση Courier από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFADP"></mat-datepicker-toggle>
                <mat-datepicker #dpFADP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Από</strong> ημερομηνία παράδοσης courier
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-3 kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTADe('change', $event)"
                  [(ngModel)]="dpTAD"
                  [matDatepicker]="dpTADP"
                  placeholder="Παράδοση Courier εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTADP"></mat-datepicker-toggle>
                <mat-datepicker #dpTADP></mat-datepicker>
                <mat-hint align="start">
                  <strong>Εώς</strong> ημερομηνία παράδοσης courier
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="spend tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PENDING')"
                  value="PENDING"
                >
                  <i class="flaticon2-download mri aicon"></i>
                  Εκκρεμείς
                </mat-button-toggle>
                <mat-button-toggle
                  class="sappr tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PROCESSED')"
                  value="PROCESSED"
                >
                  <i class="flaticon2-black-back-closed-envelope-shape mri"></i>
                  Εγκεκριμένες
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="adminView"
                  class="spaid tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID')"
                  value="PAID"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Εισπραγμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="spaida tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID_APPROVED')"
                  value="PAID_APPROVED"
                >
                  <i class="fas fa-hand-holding-usd mri aicon"></i>
                  Προς Απόδοση
                </mat-button-toggle>
                <mat-button-toggle
                  class="sinv tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('INVOICED')"
                  value="INVOICED"
                >
                  <i class="fas fa-file-invoice-dollar mri aicon"></i>
                  Εξοφλημένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="sreturned tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('RETURNED')"
                  value="RETURNED"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancellation_pending tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLATION_PENDING')"
                  value="CANCELLATION_PENDING"
                >
                  <i class="fas fa-exclamation-circle mri aicon"></i>
                  Προς Ακύρωση
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancelled tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLED')"
                  value="CANCELLED"
                >
                  <i class="fas fa-times-circle mri aicon"></i>
                  Ακυρωμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancelled tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CLARIFICATION')"
                  value="CLARIFICATION"
                >
                  <i class="fas fa-question mri aicon"></i>
                  Προς Διασάφηση
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="prepStatusChanged($event)"
                  [checked]="sprepaid"
                >
                  <i class="fas fas fa-euro-sign mri aicon"></i>
                  Προπληρωμένες
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="oprepStatusChanged($event)"
                  [checked]="oprepaid"
                >
                  <i class="fas fas fa-euro-sign mri aicon"></i>
                  Μόνο Προπληρωμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="deliveredStatusChanged($event)"
                  [checked]="delivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="odeliveredStatusChanged($event)"
                  [checked]="odelivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Μόνο Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="returnedStatusChanged($event)"
                  [checked]="returned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="oreturnedStatusChanged($event)"
                  [checked]="oreturned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Μόνο Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="odsrStatusChanged($event)"
                  [checked]="odsr"
                >
                  <i class="fas fas fa-shipping-fast mri aicon"></i>
                  Μόνο με Pickup Order
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="sendDeliveredStatusChanged($event)"
                  [checked]="snd_del"
                >
                  <i class="fas fa-dolly mri aicon"></i>
                  Χωρίς Παράδοση Αποστολής
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="ordStatusChanged($event)"
                  [checked]="ord"
                >
                  <i class="fas fa-exchange-alt mri aicon"></i>
                  Χωρίς Παράδοση Επιστροφής
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="ospStatusChanged($event)"
                  [checked]="osp"
                >
                  <i class="fas fa-file-import mri aicon"></i>
                  Σε Εκκρεμότητα Αποστολής
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="nocodStatusChanged($event)"
                  [checked]="nocod"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Χωρίς Αντικαταβολή
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="withDiffInvWeight($event)"
                  [checked]="diffinvw"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Με Διαφορές στο τιμολ. Βάρος
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  *ngIf="adminView"
                  class="prpaid tbt"
                  (change)="oCodStatusChanged($event)"
                  [checked]="ocod"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Μόνο με Αντικαταβολή
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="arsrStatusChanged($event)"
                  [checked]="arsr"
                >
                  <i class="fas fas fa-map mri aicon"></i>
                  Χωρίς Περιοχή
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-12 kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <!--input matInput placeholder="Αναζήτηση Κωδ. Αποστολής" #searchInputKA placeholder="Κωδ. Αποστολής"-->
                <mat-chip-list #vgcodeList aria-label="Κωδ. Αποστολής">
                  <mat-chip
                    class="fchip"
                    *ngFor="let code of fvg_codes"
                    selectable="false"
                    removable="true"
                    (removed)="vg_remove(code)"
                  >
                    {{ code }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Κωδ. Αποστολής..."
                    [matChipInputFor]="vgcodeList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    matChipInputAddOnBlur="true"
                    (matChipInputTokenEnd)="vg_add($event)"
                  />
                </mat-chip-list>
                <mat-hint align="start"><strong>Αναζήτση</strong> με Κωδ. Αποστολής</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="adminView" class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #usrInput
                    (keyup)="filterUsers($event)"
                    placeholder="Χρήστης..."
                    [formControl]="usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"><strong>Αναζήτση</strong> με χρήστες</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="adminView" class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #_chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fabUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="_ab_remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #_usrInput
                    (keyup)="_ab_filterUsers($event)"
                    placeholder="Εκτός από Χρήστες..."
                    [formControl]="_usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="_chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="_ab_selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of _ab_filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"><strong>Αναζήτση εκτός από</strong> χρήστες</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <div *ngIf="adminView" class="row align-items-center kt-margin-top-20 kt-margin-bottom-20">
        <kt-totals
          [shipments]="shipmentsResult"
          [selection]="false"
          style="width: 100%"
        ></kt-totals>
      </div>
    </div>

    <div *ngIf="adminView" class="mitsos">
      <div
        class="row align-items-center collapse kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: bigBucket.length > 0 }"
      >
        <h3 class="subset">Σύνολα Επιλεγμένων</h3>
      </div>
      <div
        class="row align-items-center collapse kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: bigBucket.length > 0 }"
      >
        <kt-totals
          [t_selection]="selection.selected"
          [shipments]="bigBucket"
          [selection]="true"
          style="width: 100%"
        ></kt-totals>
      </div>
    </div>
    <div class="kt-form kt-margin-b-30">
      <!-- end::FILTERS -->

      <!-- start::GROUP ACTIONS -->
      <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
      <!-- Group actions are shared for all LISTS -->
      <div
        *ngIf="adminView"
        class="row align-items-center kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
      >
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline mma">
            <div class="kt-form__control kt-form__group--inline">
              <button
                *ngIf="adminView"
                (click)="selectAllByStatus('PROCESSED')"
                mat-raised-button
                matTooltip="Επιλογή όλων των Εγκρίθηκαν"
                class="approve-btn-l mat-button-mt-4"
              >
                <mat-icon>thumb_up</mat-icon>
                Επιλογή Εγκρίθηκαν
              </button>
              <button
                *ngIf="adminView"
                (click)="selectAllByStatus('PAID')"
                mat-raised-button
                matTooltip="Επιλογή όλων των Εισπράχτηκαν"
                class="paid-btn-l mat-button-mt-4"
              >
                <i class="fas fa-money-check-alt"></i>
                Επιλογή Εισπράχτηκαν
              </button>
              <button
                *ngIf="adminView"
                (click)="selectAllByStatus('PAID_APPROVED')"
                mat-raised-button
                matTooltip="Επιλογή όλων των Προς Απόδοση"
                class="paida-btn-l mat-button-mt-4"
              >
                <i class="fas fa-hand-holding-usd"></i>
                Επιλογή Προς Απόδοση
              </button>
              <button
                *ngIf="adminView"
                (click)="selectAllByStatus('INVOICED')"
                mat-raised-button
                matTooltip="Επιλογή όλων των Εξοφλήθηκαν"
                class="inv-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-invoice-dollar"></i>
                Επιλογή Εξοφλήθηκαν
              </button>
              <button
                *ngIf="adminView"
                (click)="selectAllByStatus('RETURNED')"
                mat-raised-button
                matTooltip="Επιλογή όλων των Επεστραμένων"
                class="returned-btn-l mat-button-mt-4"
              >
                <i class="fas fa-undo-alt"></i>
                Επιλογή Επεστράφησαν
              </button>
              <button
                *ngIf="adminView"
                (click)="clearSelection()"
                mat-raised-button
                matTooltip="Καθαρισμός Επιλεγμένων απο όλες τις σελίδες"
                class="clear-btn-l mat-button-mt-4"
              >
                <i class="fas fa-trash"></i>
                Καθαρισμός Επιλεγμένων
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="adminView"
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: bigBucket.length > 0 }"
      >
        <!-- We show 'Group Actions' div if smth are selected -->
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline _mma">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Επιλεγμένες εγγραφές: </span> {{ bigBucket.length }}
              </label>
              <!-- selectedCountsTitle => function from codeBehind (users-list.component.ts file) -->
              <!-- selectedCountsTitle => just returns title of selected items count -->
              <!-- for example: Selected records count: 4 -->
            </div>
            <div class="kt-form__control kt-form__group--inline _mma">
              <button
                *ngIf="adminView"
                (click)="markProcessed()"
                mat-raised-button
                matTooltip="Αλλαγή κατάστασης σε 'Εγκρίθηκε'"
                class="approve-btn-l mat-button-mt-4"
              >
                <mat-icon>thumb_up</mat-icon>
                Εγκρίθηκαν
              </button>
              <button
                *ngIf="adminView"
                (click)="markPaid()"
                mat-raised-button
                matTooltip="Αλλαγή κατάστασης σε 'Εισπράχτηκε'"
                class="paid-btn-l mat-button-mt-4"
              >
                <i class="fas fa-money-check-alt"></i>
                Εισπράχτηκαν
              </button>
              <button
                *ngIf="adminView"
                (click)="markPaidApproved()"
                mat-raised-button
                matTooltip="Αλλαγή κατάστασης σε 'Προς Απόδοση'"
                class="paida-btn-l mat-button-mt-4"
              >
                <i class="fas fa-hand-holding-usd"></i>
                Προς Απόδοση
              </button>
              <button
                *ngIf="adminView"
                (click)="markInvoiced()"
                mat-raised-button
                matTooltip="Αλλαγή κατάστασης σε 'Εξοφλημένη'"
                class="inv-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-invoice-dollar"></i>
                Εξοφλημένες
              </button>
              <button
                *ngIf="adminView"
                (click)="massMarkCancellationPending()"
                mat-raised-button
                matTooltip="Αλλαγή κατάστασης σε 'Προς Ακύρωση'"
                class="returned-btn-l mat-button-mt-4"
              >
                <i class="fas fa-exclamation-circle"></i>
                Προς Ακύρωση
              </button>
              <button
                *ngIf="adminView"
                (click)="check_kg()"
                mat-raised-button
                matTooltip="Διαστάυρωση βάρους με τα ΕΛΤΑ"
                class="approve-btn-l mat-button-mt-4"
              >
                <i class="fas fa-balance-scale-right"></i>
                Έλεγχος Βάρους
              </button>
              <button
                *ngIf="adminView"
                (click)="massMarkPrepaid()"
                mat-raised-button
                matTooltip="Μαρκάρισμα ως Προπληρωμένες"
                class="prepaid-btn-l mat-button-mt-4"
              >
                <i class="fas fa-euro-sign"></i>
                Προπληρωμένες
              </button>
              <button
                *ngIf="adminView"
                (click)="genXLS()"
                mat-raised-button
                matTooltip="Δημιουργία Excel από τις επιλεγμένες"
                class="xls-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-excel xls-i"></i>
                Δημιουργία Excel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="adminView && fUsers.length === 1"
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: bigBucket.length > 0 }"
      >
        <!-- We show 'Group Actions' div if smth are selected -->
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Έκδοση παραστατικών: </span>
              </label>
            </div>
            <div class="kt-form__control kt-form__group--inline _mma">
              <button
                (click)="createCodXLS()"
                mat-raised-button
                matTooltip="Παραγωγή Απόδοσης Αντικαταβολών"
                class="cod-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-excel"></i>
                Απόδοση Αντικαταβολών
              </button>
              <button
                (click)="createChargesXLS()"
                mat-raised-button
                matTooltip="Παραγωγή Ανάλυσης Χρεώσεων"
                class="charges-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-excel"></i>
                Ανάλυση Χρεώσεων
              </button>
              <button
                (click)="createReceipt()"
                mat-raised-button
                matTooltip="Παραγωγή Απόδειξης Πληρωμής"
                class="receipt-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-pdf"></i>
                Απόδειξη Πληρωμής
              </button>
              <button
                (click)="addExtraCharge()"
                mat-raised-button
                matTooltip="Προσθήκη επιπλέον χρέωσης στην εκκαθάριση"
                class="returned-btn-l mat-button-mt-4"
              >
                <i class="fas fa-plus"></i>
                Επιπλέον Χρέωση
              </button>
              <button
                (click)="liquidate()"
                mat-raised-button
                matTooltip="Εκκαθάριση Αποστολών"
                class="liquidate-btn-l mat-button-mt-4"
              >
                <i class="fas fa-euro-sign"></i>
                Εκκαθάριση
              </button>
              <mat-button-toggle class="prpaid tbt" (change)="stateDl($event)" checked="true">
                <i class="fas fa-download mri"></i>
                Αυτόματη Μεταφόρτωση Αρχείων
              </mat-button-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="adminView && extraCharges.length > 0"
      class="row align-items-center kt-margin-top-20 kt-margin-bottom-20"
      [ngClass]="{ show: bigBucket.length > 0 }"
    >
      <!-- We show 'Group Actions' div if smth are selected -->
      <div class="col-xl-12">
        <div class="row kt-margin-top-20 kt-margin-bottom-20">
          <div class="col-xl-12">
            <h4 class="ec-title">Επιπλέον Χρεώσεις</h4>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div
          *ngFor="let charge of extraCharges"
          class="form-group kt-form__group row kt-margin-top-20 kt-margin-bottom-20"
        >
          <div class="col-md-8 kt-margin-bottom-20-mobile plr">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="Αιτία Χρέωσης"
                [(ngModel)]="charge.reason"
                name="reason-{{ charge.id }}"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε την
                <strong>αιτία της χρέωσης</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-3 kt-margin-bottom-20-mobile plr">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                [textMask]="{ mask: curr_mask1 }"
                placeholder="Ποσό ( &euro; 00.00 )"
                class="right-align"
                [(ngModel)]="charge.charge"
                name="charge-{{ charge.id }}"
              />
              <mat-hint align="start"
                >Παρακαλώ ειστάγετε το
                <strong>ποσό</strong>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <button
              (click)="removeExtraCharge(charge.id)"
              mat-raised-button
              matTooltip="Διαγραφή επιπλέον χρέωσης"
              class="receipt-btn-l mat-button-mt-4"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table__bottom">
      <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <!-- MATERIAL PAGINATOR | Binded to dasources -->
      <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
      <mat-paginator
        [pageSize]="paginator.pageSize"
        [pageSizeOptions]="[10, 50, 100, 200, 300]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>

    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
    <div class="mat-table__wrapper" [class.adm-view]="adminView">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        matSortDisableClear
      >
        <!-- Checkbox Column -->

        <!-- Table with selection -->
        <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleSelection(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="!adminView" matColumnDef="recipient_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Παραλήπτης</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.recipient_name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="vg_code">
          <mat-header-cell *matHeaderCellDef>Κωδ. Αποστολής</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.user | lookupUser | async as usr">
              <ng-container *ngIf="!adminView">
                <ng-container *ngIf="shipment.vg_code">{{ shipment.vg_code }}</ng-container>
                <ng-container *ngIf="!shipment.vg_code">N/A</ng-container>
              </ng-container>
              <a
                href="javascript:;"
                *ngIf="!usr.is_company && adminView"
                matTooltip="
                                                                                                    ID: {{
                  usr.id
                }}&#13;
                                                                                                    Email: {{
                  usr.email
                }}&#13;
                                                                                                    Τηλ: {{
                  usr.phone
                }}&#13;
                                                                                                    Κιν: {{
                  usr.mobile
                }}&#13;
                                                                                                    ΑΦΜ: {{
                  usr.vat
                }}&#13;
                                                                                                    ΑΔΤ: {{
                  usr.adt
                }}
                                                                                                    "
                [matTooltipClass]="'brk-tooltip'"
              >
                <ng-container *ngIf="shipment.vg_code">{{ shipment.vg_code }}</ng-container>
                <ng-container *ngIf="!shipment.vg_code">N/A</ng-container>
              </a>
              <a
                href="javascript:;"
                *ngIf="usr.is_company && adminView"
                matTooltip="
                                                                                                   ID: {{
                  usr.id
                }}&#13;
                                                                                                   Email: {{
                  usr.email
                }}&#13;
                                                                                                   Τηλ: {{
                  usr.phone
                }}&#13;
                                                                                                   Κιν: {{
                  usr.mobile
                }}&#13;
                                                                                                   Εταιρία: {{
                  usr.companyName
                }}&#13;
                                                                                                   ΑΦΜ Εταιρίας: {{
                  usr.companyVat
                }}&#13;
                                                                                                   ΔΟΥ: {{
                  usr.companyFiscalService
                }}
                                                                                                   "
                [matTooltipClass]="'brk-tooltip'"
              >
                <ng-container *ngIf="shipment.vg_code">{{ shipment.vg_code }}</ng-container>
                <ng-container *ngIf="!shipment.vg_code">N/A</ng-container>
              </a>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_approved">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Εγκρίθηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_approved | date }}</mat-cell>
        </ng-container>

        <ng-container *ngIf="adminView" matColumnDef="date_paid">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Εισπράχτηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_paid | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="cod_ammount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Αν/βολή</mat-header-cell>
          <mat-cell *matCellDef="let shipment"
            ><span class="cod">{{ shipment.cod_ammount | currency: "EUR" }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="prereturn_ammount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Αμ. Αποδ.</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span class="prepd">{{ shipment.prereturn_ammount | currency: "EUR" }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cost">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Χρέωση</mat-header-cell>
          <mat-cell *matCellDef="let shipment"
            ><span class="cod">{{ shipment.cost | currency: "EUR" }}</span></mat-cell
          >
        </ng-container>

        <ng-container *ngIf="adminView" matColumnDef="prepaid_ammount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Προπλ.</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span class="prepd">{{ shipment.prepaid_ammount | currency: "EUR" }}</span>
            <span *ngIf="shipment.is_prepaid && !shipment.prepaid_ammount" class="prepaid"
              ><i class="fas fa-check"></i
            ></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="is_delivered">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Παρ/μένη</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.is_delivered" class="gready"><i class="fas fa-check"></i></span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="_status">
          <mat-header-cell *matHeaderCellDef>Κατάσταση</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <div *ngIf="shipment._status === 'PENDING'" class="pending">
              <span class="status-icon"><i class="flaticon2-download"></i></span>
              <span class="status-text">Εκκρεμεί</span>
            </div>
            <div *ngIf="shipment._status === 'PROCESSED'" class="approved">
              <span class="status-icon"
                ><i class="flaticon2-black-back-closed-envelope-shape"></i
              ></span>
              <span class="status-text">Εγκρίθηκε</span>
            </div>
            <div *ngIf="shipment._status === 'PAID' && adminView" class="paid">
              <span class="status-icon"><i class="fas fa-money-check-alt"></i></span>
              <span class="status-text">Εισπραγμένη</span>
            </div>
            <div *ngIf="shipment._status === 'PAID' && !adminView" class="approved">
              <span class="status-icon"
                ><i class="flaticon2-black-back-closed-envelope-shape"></i
              ></span>
              <span class="status-text">Εγκρίθηκε</span>
            </div>
            <div *ngIf="shipment._status === 'PAID_APPROVED'" class="paida">
              <span class="status-icon"><i class="fas fa-hand-holding-usd"></i></span>
              <span class="status-text">Πρός Απόδ.</span>
            </div>
            <div *ngIf="shipment._status === 'INVOICED'" class="inv">
              <span class="status-icon"><i class="fas fa-file-invoice-dollar"></i></span>
              <span class="status-text">Εξοφλημένη</span>
            </div>
            <div *ngIf="shipment._status === 'RETURNED'" class="returned">
              <span class="status-icon"><i class="fas fa-undo-alt"></i></span>
              <span class="status-text">Επεστραμένη</span>
            </div>
            <div *ngIf="shipment._status === 'CANCELLATION_PENDING'" class="cancellation_pending">
              <span class="status-icon"><i class="fas fa-exclamation-circle"></i></span>
              <span class="status-text">Προς Ακύρ.</span>
            </div>
            <div *ngIf="shipment._status === 'CANCELLED'" class="cancelled">
              <span class="status-icon"><i class="fas fa-times-circle"></i></span>
              <span class="status-text">Ακυρωμένη</span>
            </div>
            <div *ngIf="shipment._status === 'CLARIFICATION'" class="cancelled">
              <span class="status-icon"><i class="fas fa-question"></i></span>
              <span class="status-text">Διασάφηση</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
          <mat-cell class="sbn" *matCellDef="let shipment">
            <button
              (click)="viewShipment(shipment)"
              mat-icon-button
              color="primary"
              matTooltip="Λεπτομέρειες Αποστολής"
            >
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button
              *ngIf="
                shipment.courier !== 'GENIKI' &&
                shipment._status !== 'PENDING' &&
                shipment._status !== 'CANCELLED' &&
                adminView
              "
              (click)="viewShipmentTracking(shipment)"
              mat-icon-button
              class="paid-btn"
              matTooltip="Εντοπισμός Αποστολής"
            >
              <i class="fas fa-map-marked-alt"></i>
            </button>
            <button
              *ngIf="
                shipment.courier !== 'GENIKI' &&
                shipment._status !== 'PENDING' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                shipment._status !== 'CANCELLED'
              "
              (click)="viewVoucher(shipment)"
              mat-icon-button
              color="warn"
              matTooltip="Προβολή ΣΥ.ΔΕ.ΤΑ."
            >
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'PROCESSED' &&
                shipment._status !== 'RETURNED' &&
                adminView
              "
              mat-icon-button
              matTooltip="Αλλαγή κατάστασης σε 'Εγκρίθηκε'"
              class="approve-btn"
              type="button"
              (click)="markProcessed_s(shipment)"
            >
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'PAID' &&
                shipment._status !== 'RETURNED' &&
                shipment._status !== 'CANCELLED' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                adminView
              "
              mat-icon-button
              matTooltip="Αλλαγή κατάστασης σε 'Εισπράχτηκε'"
              class="paid-btn"
              type="button"
              (click)="markPaid_s(shipment)"
            >
              <i class="fas fa-money-check-alt"></i>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'PAID_APPROVED' &&
                shipment._status !== 'RETURNED' &&
                shipment._status !== 'PROCESSED' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                shipment._status !== 'CANCELLED' &&
                adminView
              "
              mat-icon-button
              matTooltip="Αλλαγή κατάστασης σε 'Προς Απόδοση'"
              class="paida-btn"
              type="button"
              (click)="markPaidApproved_s(shipment)"
            >
              <i class="fas fa-hand-holding-usd"></i>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'INVOICED' &&
                shipment._status !== 'PAID' &&
                shipment._status !== 'PROCESSED' &&
                shipment._status !== 'CANCELLED' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                adminView
              "
              mat-icon-button
              matTooltip="Αλλαγή κατάστασης σε 'Εξοφλημένη'"
              class="inv-btn"
              type="button"
              (click)="markInvoiced_s(shipment)"
            >
              <i class="fas fa-file-invoice-dollar"></i>
            </button>
            <button
              *ngIf="
                shipment.courier !== 'GENIKI' &&
                (shipment._status == 'PROCESSED' ||
                  shipment._status == 'PAID' ||
                  shipment._status == 'RETURNED') &&
                adminView &&
                shipment.courier != 'SPEEDEX'
              "
              mat-icon-button
              matTooltip="Διασταύρωση βάρους με τα ΕΛΤΑ'"
              class="approve-btn"
              type="button"
              (click)="check_kg_s(shipment)"
            >
              <i class="fas fa-balance-scale-right"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && !shipment.is_prepaid && adminView"
              mat-icon-button
              matTooltip="Μαρκάρισμα ως Προπληρωμένη"
              class="prepaid-btn"
              type="button"
              (click)="markPrepaid(shipment)"
            >
              <i class="fas fa-euro-sign"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && shipment.is_prepaid && adminView"
              mat-icon-button
              matTooltip="Μαρκάρισμα ως μη Προπληρωμένη"
              class="unprepaid-btn"
              type="button"
              (click)="unmarkPrepaid(shipment)"
            >
              <i class="fas fa-euro-sign"></i>
            </button>
            <button
              (click)="markReturned(shipment)"
              *ngIf="shipment._status == 'PROCESSED' && adminView && !shipment.is_dsr"
              class="approve-btn"
              mat-icon-button
              matTooltip="Αλλαγή Κατάστασης σε Επεστραμένη"
              type="button"
            >
              <i class="fas fa-arrow-alt-circle-left"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && !shipment.is_prereturn && adminView"
              mat-icon-button
              matTooltip="Μαρκάρισμα ως Προ-Αποδομένη"
              class="prepaid-btn"
              type="button"
              (click)="markPrereturn(shipment)"
            >
              <i class="fas fa-file-invoice-dollar"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && shipment.is_prereturn && adminView"
              mat-icon-button
              matTooltip="Μαρκάρισμα ως μη Προ-Αποδομένη"
              class="unprepaid-btn"
              type="button"
              (click)="unmarkPrereturn(shipment)"
            >
              <i class="fas fa-file-invoice-dollar"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && adminView && shipment.courier == 'ELTA'"
              mat-icon-button
              matTooltip="Υποβολή της αποστολής για έλεγχο ακύρωρης"
              class="cancellation_pending-btn"
              type="button"
              (click)="markCancellationPending(shipment)"
            >
              <i class="fas fa-exclamation-circle"></i>
            </button>
            <button
              *ngIf="
                shipment.courier !== 'GENIKI' &&
                shipment._status == 'PROCESSED' &&
                adminView &&
                !shipment.is_dsr
              "
              mat-icon-button
              matTooltip="Έλεγχος Επιστροφής"
              class="approve-btn"
              type="button"
              (click)="checkReturned(shipment)"
            >
              <i class="fas fa-reply"></i>
            </button>
            <button
              *ngIf="
                (shipment._status == 'PROCESSED' || shipment._status == 'PAID_APPROVED') &&
                adminView
              "
              mat-icon-button
              matTooltip="Προς διασάφηση"
              class="unprepaid-btn"
              type="button"
              (click)="markClarification(shipment)"
            >
              <i class="fas fa-question"></i>
            </button>
            <button
              *ngIf="shipment._status == 'PROCESSED' && adminView && !shipment.is_dsr"
              mat-icon-button
              matTooltip="Αλλαγή παράδοσης αποστολής"
              [ngClass]="{
                'unprepaid-btn': !shipment.send_delivered,
                'approve-btn': shipment.send_delivered
              }"
              type="button"
              (click)="toggleSendDelivered(shipment)"
            >
              <i class="fas fa-dolly"></i>
            </button>
            <button
              *ngIf="(shipment.is_returned || shipment.is_dsr) && adminView"
              mat-icon-button
              matTooltip="Αλλαγή παράδοσης επιστροφής"
              [ngClass]="{
                'unprepaid-btn': !shipment.return_delivered,
                'approve-btn': shipment.return_delivered
              }"
              type="button"
              (click)="toggleReturnDelivered(shipment)"
            >
              <i class="fas fa-exchange-alt"></i>
            </button>
            <button
              *ngIf="
                shipment._status == 'PROCESSED' &&
                (shipment.courier == 'SPEEDEX' ||
                  shipment.courier == 'COURIER_CENTER' ||
                  shipment.courier == 'TAXYDEMA' ||
                  shipment.courier == 'ACS1' ||
                  shipment.courier == 'ACS2' ||
                  shipment.courier == 'ACS3' ||
                  shipment.courier == 'DIAKINISIS') &&
                shipment.send_pending &&
                !shipment.send_delivered
              "
              mat-icon-button
              matTooltip="Ακύρωση Αποστολής"
              class="unprepaid-btn"
              type="button"
              (click)="shipmentCancel(shipment)"
            >
              <i class="fas fa-power-off"></i>
            </button>
            <button
              *ngIf="shipment.is_dsr && shipment.return_of"
              (click)="viewShipmentTracking(shipment.return_of)"
              mat-icon-button
              class="orig-dsr"
              matTooltip="Αρχική Αποστολή"
            >
              <i class="fas fa-mail-bulk"></i>
            </button>
            <button
              *ngIf="shipment.return_shipment"
              (click)="viewShipmentTracking(shipment.return_shipment)"
              mat-icon-button
              class="return-dsr"
              matTooltip="Επιστροφική Αποστολή"
            >
              <i class="fas fa-mail-bulk"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ is_dsr: row.is_dsr }"
        ></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
      <!-- Message for empty data  -->
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Please wait....
      </div>
    </div>

    <div id="pdf_js">
      <ng2-pdfjs-viewer
        #pdfViewerOnDemand
        [startPrint]="true"
        [openFile]="false"
        [download]="false"
      ></ng2-pdfjs-viewer>
    </div>
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
