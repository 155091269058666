// Angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
import {
  FetchUser,
  isStaff,
  selectUserById,
  User,
  UserActionTypes,
  UserLoaded,
} from '../../../../../core/auth';
import {
  ActionFail,
  calculateCharge,
  FetchedShipment,
  FetchShipment,
  Notification,
  NotificationDelete,
  NotificationsClear,
  selectChargesForShipment,
  selectNotificationsInStore,
  selectShipmentById,
  Shipment,
  ShipmentActionTypes,
} from '../../../../../core/ronex';

import { filter, map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { LayoutUtilsService } from '../../../../../core/_base/crud';

@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  // Show dot on top of the icon
  @Input() dot: string;

  // Show pulse on icon
  @Input() pulse: boolean;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() type: 'brand' | 'success' = 'success';

  //@Output() close = new EventEmitter();

  notifications$: Observable<any>;
  alerts$: Observable<any>;
  events$: Observable<any>;
  logs$: Observable<any>;

  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  constructor(
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.pulse = false;

    this.notifications$ = this.store.pipe(select(selectNotificationsInStore));
    this.events$ = this.notifications$.pipe(
      map((evn) => evn.filter((n: Notification) => n._type === 'event')),
    );
    this.alerts$ = this.notifications$.pipe(
      map((evn) => evn.filter((n: Notification) => n._type === 'alert')),
    );
    this.logs$ = this.notifications$.pipe(
      map((evn) => evn.filter((n: Notification) => n._type === 'log')),
    );
    const sub = this.notifications$.subscribe((res) => {
      if (res == undefined || res.length == 0) {
        this.pulse = false;
      } else {
        this.pulse = true;
      }
    });
    this.subscriptions.push(sub);
  }

  clearNotifications() {
    this.store.dispatch(new NotificationsClear());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  removeNotification(aid: number) {
    this.store.dispatch(new NotificationDelete({ id: aid }));
    //this.close.emit(null);
  }

  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }

    return 'url(' + this.bgImage + ')';
  }

  checkSpecial(link: string): boolean {
    return link.startsWith('special::');
  }

  handleSpecial(item: any) {
    if (item._router_link.startsWith('special::sid=')) {
      const sid: number = parseInt(
        item._router_link.replace('special::sid=', ''),
      );

      //Make sure shipment is in store
      this.store.dispatch(new FetchShipment({ id: sid }));

      this.store
        .pipe(
          select(selectShipmentById(sid)),
          takeWhile((res: Shipment) => !res, true),
          takeUntil(
            this.actions$.pipe(
              ofType<ActionFail>(ShipmentActionTypes.ActionFail),
            ),
          ),
          takeUntil(
            this.actions$.pipe(
              ofType<FetchedShipment>(ShipmentActionTypes.FetchedShipment),
            ),
          ),
          filter((res: Shipment) => res != undefined),
          take(1),
        )
        .subscribe((res) => {
          const shipment: Shipment = res;

          this.store.dispatch(new FetchUser({ id: res.user }));

          this.store
            .pipe(
              select(selectUserById(shipment.user)),
              takeWhile((_res: User) => !_res, true),
              takeUntil(
                this.actions$.pipe(
                  ofType<UserLoaded>(UserActionTypes.UserLoaded),
                ),
              ),
              filter((_res: User) => _res != undefined),
              take(1),
            )
            .subscribe((_res) => {
              const user: User = _res;
              this.store
                .pipe(select(selectChargesForShipment(shipment)), take(1))
                .subscribe((res) => {
                  const charge: any = res;
                  const fs: any = calculateCharge(user, charge, shipment);
                  this.store.pipe(select(isStaff), take(1)).subscribe((res) =>
                    this.layoutUtilsService.viewShipment({
                      item: fs,
                      adm: res,
                      financial: res,
                    }),
                  );
                });
            });
        });

      this.removeNotification(item.id);
    }
  }
}
