<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
  <div
    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
  >
    <!--begin::Aside-->
    <!--div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside mlgbg" style="background-image: url(./assets/media//bg/bg-10.jpg);"-->
    <div
      class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside mlgbg"
    >
      <div class="kt-grid__item">
        <div class="kt-login__logo">
          <div class="callogo">
            <img class="allogo" src="./assets/media/logos/rnlogo-dark.png" alt="" />
            <div class="lsh">Η πρώτη e-courier στην <span class="greece">Ελλάδα</span></div>
          </div>
        </div>
      </div>
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
        <div class="kt-grid__item kt-grid__item--middle w-100">
          <div class="row mb-5">
            <div class="col-6 text-center">
              <div class="text-center mb-2">
                <i class="fas fa-shipping-fast tico"></i>
              </div>
              <div class="tinfo">
                <div class="ttile">Πολλαπλές Courier</div>
                <div class="tdesc">
                  Αποστολές με <strong>ΕΛΤΑ courier</strong> και
                  <strong>ΤΑΧΥΔΕΜΑ</strong> ταυτόχρονα και <strong>αυτόματη δρομολόγηση</strong> για
                  την ταχύτερη εξυπηρέτηση σας.
                </div>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="text-center mb-2">
                <i class="fas fa-people-carry tico"></i>
              </div>
              <div class="tinfo">
                <div class="ttile">Συνδυασμένες Αποστολές</div>
                <div class="tdesc">
                  Για επαγγελματίες και ιδιώτες με <strong>on-line έκδοση voucher</strong> 24ώρες το
                  εικοσιτετράωρο.
                </div>
              </div>
            </div>
            <div class="col-6 text-center mt-4">
              <div class="text-center mb-2">
                <i class="fas fa-hand-holding-usd tico"></i>
              </div>
              <div class="tinfo">
                <div class="ttile">Απόδοση Αντικαταβολών</div>
                <div class="tdesc">
                  Ανάλογα με τις προτιμήσεις σας, με
                  <strong>μετρητά ή τραπεζική κατάθεση</strong> για ιδιώτες και επαγγελματίες
                  <strong>καθημερινά</strong>.
                </div>
              </div>
            </div>
            <div class="col-6 text-center mt-4">
              <div class="text-center mb-2">
                <i class="fas fa-wifi tico"></i>
              </div>
              <div class="tinfo">
                <div class="ttile">Πλήρης On-Line Διαχείριση</div>
                <div class="tdesc">
                  Παρακολούθηση κάθε αποστολής &amp; ανάλυση χρέωσης
                  <strong>σε πραγματικό χρόνο.</strong> Έκδοση εντολών παραλαβής &amp; εκκαθαρίσεις
                  αντικαταβολών / χρεώσεων <strong>On-Line</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center atks">Ανοίξτε τώρα κωδικό συνεργασίας στη RoNEx</div>
          </div>
          <div class="row">
            <div class="col-12 text-center cusfo">
              Επικοινωνήστε μαζί μας για να σας δώσουμε την προσφορά μας
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center ccont">
              <strong>email:</strong> ronex.gr&#64;gmail.com
            </div>
          </div>
        </div>
      </div>
      <div class="kt-grid__item">
        <div class="kt-login__info">
          <div class="kt-login__copyright">© {{ today | date: "yyyy" }} RonEx</div>
          <div class="kt-login__menu">
            <a href="javascript:;" (click)="ox()" class="kt-link">Όροι Χρήσης</a>
            <a href="javascript:;" (click)="gdpr()" class="kt-link">Προσωπικά Δεδομένα</a>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
    >
      <router-outlet></router-outlet>
    </div>
    <!--end::Content-->
  </div>
</div>
