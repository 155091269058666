// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AreaState } from '../_reducers/area.reducers';
import { each } from 'lodash';
import { Area } from '../_models/area.model';

export const selectAreaState = createFeatureSelector<AreaState>('areas');

export const selectAreaById = (id: number) =>
  createSelector(selectAreaState, (areaState) => areaState.entities[id]);

export const selectAreaPageLoading = createSelector(
  selectAreaState,
  (areaState) => {
    return areaState.listLoading;
  },
);

export const selectAreaActionLoading = createSelector(
  selectAreaState,
  (areaState) => areaState.actionsloading,
);

export const selectAreaPageLastQuery = createSelector(
  selectAreaState,
  (areaState) => areaState.lastQuery,
);

export const selectAreasInStore = createSelector(
  selectAreaState,
  (areaState) => {
    const items: Area[] = [];
    each(areaState.ids, (id: number) => {
      items.push(areaState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: Area[] = httpExtension.sortArray(
      items,
      areaState.lastQuery.sortField,
      areaState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, areaState.totalCount, '');
  },
);

export const selectAreaShowInitWaitingMessage = createSelector(
  selectAreaState,
  (areaState) => areaState.showInitWaitingMessage,
);

export const selectHasAreasInStore = createSelector(
  selectAreaState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
