import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { User, selectUserById } from '../../../../../core/auth';
import { Observable } from 'rxjs';

@Pipe({
  name: 'lookupUser',
})
export class LookupUserPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(value: number, ...args: any[]): Observable<User> {
    return this.store.pipe(select(selectUserById(value)));
  }
}
