// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, switchMap } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { ChargeService } from '../_services/charge.service';
// State
import { AppState } from '../../reducers';
import {
  AllChargesLoaded,
  AllChargesLoadFailed,
  CActionFail,
  CActionSuccess,
  ChargeActionTypes,
  ChargesAdd,
  ChargesUpsert,
  LoadAllCharges,
} from '../_actions/charge.actions';

@Injectable()
export class ChargeEffects {
  loadAllCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAllCharges>(ChargeActionTypes.LoadAllCharges),
      switchMap(() =>
        this.cs.getAllCharges().pipe(
          switchMap((res) => [new AllChargesLoaded({ charges: res })]),
          catchError((err) => [new AllChargesLoadFailed({ result: err })]),
        ),
      ),
    ),
  );

  createCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChargesAdd>(ChargeActionTypes.ChargesAdd),
      map((action) => action.payload),
      switchMap((payload) =>
        this.cs.createCharges(payload.charges).pipe(
          switchMap((res) => [
            new ChargesUpsert({ charges: res }),
            new CActionSuccess({ result: `Οι χρεώσεις προστέθηκαν επιτυχώς` }),
          ]),
          catchError((err) => [new CActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  constructor(
    private actions$: Actions,
    private cs: ChargeService,
    private store: Store<AppState>,
  ) {}
}
