// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { InvoiceCheckReport } from '../_models/invoice_check_report.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum InvoiceCheckReportActionTypes {
  InvoiceCheckReportPageRequested = '[Invoice Check Report List Page] Invoice Check Report Page Requested',
  InvoiceCheckReportPageLoaded = '[Invoice Check Report API] Invoice Check Report Page Loaded',
  InvoiceCheckReportPageCancelled = '[Invoice Check Report API] Invoice Check Report Page Cancelled',
  InvoiceCheckReportPageToggleLoading = '[Invoice Check Report] Invoice Check Report Page Toggle Loading',
  InvoiceCheckReportActionToggleLoading = '[Invoice Check Report] Invoice Check Report Action Toggle Loading',
  ICRActionSuccess = '[Invoice Check Report API] Last action succeded',
  ICRActionFail = '[Invoice Check Report API] Last action failed',
  ICRActionRefresh = '[Invoice Check Report API] Emit to all interested parties to refresh all invoice check reports',
}

export class InvoiceCheckReportPageRequested implements Action {
  readonly type = InvoiceCheckReportActionTypes.InvoiceCheckReportPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class InvoiceCheckReportPageLoaded implements Action {
  readonly type = InvoiceCheckReportActionTypes.InvoiceCheckReportPageLoaded;
  constructor(
    public payload: {
      InvoiceCheckReports: InvoiceCheckReport[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class InvoiceCheckReportPageCancelled implements Action {
  readonly type = InvoiceCheckReportActionTypes.InvoiceCheckReportPageCancelled;
}

export class InvoiceCheckReportPageToggleLoading implements Action {
  readonly type =
    InvoiceCheckReportActionTypes.InvoiceCheckReportPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class InvoiceCheckReportActionToggleLoading implements Action {
  readonly type =
    InvoiceCheckReportActionTypes.InvoiceCheckReportActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class ICRActionRefresh implements Action {
  readonly type = InvoiceCheckReportActionTypes.ICRActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class ICRActionSuccess implements Action {
  readonly type = InvoiceCheckReportActionTypes.ICRActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class ICRActionFail implements Action {
  readonly type = InvoiceCheckReportActionTypes.ICRActionFail;
  constructor(public payload: { result: any }) {}
}

export type InvoiceCheckReportActions =
  | InvoiceCheckReportPageRequested
  | InvoiceCheckReportPageLoaded
  | InvoiceCheckReportPageCancelled
  | InvoiceCheckReportPageToggleLoading
  | InvoiceCheckReportActionToggleLoading
  | ICRActionRefresh
  | ICRActionSuccess
  | ICRActionFail;
