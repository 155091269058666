<kt-portlet>
  <kt-portlet-header [title]="data.title" [icon]="data.icon" [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTools>
      <button
        class="mright mat-button-mt-4 red-btn"
        mat-raised-button
        (click)="close()"
        color="primary"
      >
        <mat-icon>keyboard_return</mat-icon>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-section__content">
          <div perfectScrollbar [ngStyle]="{ 'max-height': '65vh' }" class="main_content">
            <div class="loaded_content" [innerHTML]="data.content"></div>
          </div>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
