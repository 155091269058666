<div class="container">
  <form (ngSubmit)="add()" #addFrom="ngForm" class="kt-form kt-form--group-seperator-dashed">
    <div class="form-group kt-form__group row">
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Αιτία Χρέωσης" [(ngModel)]="cta.reason" name="reason" />
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε την
            <strong>αιτία της χρέωσης</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            placeholder="Περιγραφή Χρέωσης"
            [(ngModel)]="cta.description"
            name="description"
          />
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε την
            <strong>περιγραφή της χρέωσης</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            [textMask]="{ mask: curr_mask }"
            placeholder="Ποσό ( &euro; 00.00 )"
            class="right-align"
            [(ngModel)]="cta.ammount"
            name="ammount"
          />
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε το
            <strong>ποσό</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group kt-form__group row">
      <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
        <button
          type="submit"
          class="btn btn-primary kt-margin-r-10 fright"
          color="accent"
          mat-raised-button
          matTooltip="Προσθήκη νέας χρέωσης"
        >
          <i class="fas fa-plus"></i>
          <span>Προσθήκη</span>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="container">
  <div class="mat-table__wrapper">
    <mat-table class="lmat-elevation-z8" #table [dataSource]="extraChargesSubject.value">
      <ng-container matColumnDef="reason">
        <mat-header-cell *matHeaderCellDef>Αιτία</mat-header-cell>
        <mat-cell *matCellDef="let ec"> {{ ec.reason }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Περιγραφή</mat-header-cell>
        <mat-cell *matCellDef="let ec"> {{ ec.description }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ammount">
        <mat-header-cell *matHeaderCellDef>Χρέωση</mat-header-cell>
        <mat-cell *matCellDef="let ec"
          ><span class="cod">{{ ec.ammount | currency: "EUR" }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
        <mat-cell *matCellDef="let ec">
          <button (click)="removeEC(ec)" mat-icon-button color="warn" matTooltip="Διαγραφή">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="mat-table__message" *ngIf="extraChargesSubject.value.length === 0">
      Δεν βρέθηκαν χρεώσεις
    </div>
    <!-- Message for empty data  -->
  </div>
</div>
