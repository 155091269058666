// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { InvoiceCheckReportState } from '../_reducers/invoice_check_report.reducers';
import { each } from 'lodash';
import { InvoiceCheckReport } from '../_models/invoice_check_report.model';

export const selectInvoiceCheckReportState =
  createFeatureSelector<InvoiceCheckReportState>('InvoiceCheckReports');

export const selectInvoiceCheckReportById = (id: number) =>
  createSelector(
    selectInvoiceCheckReportState,
    (InvoiceCheckReportState) => InvoiceCheckReportState.entities[id],
  );

export const selectInvoiceCheckReportPageLoading = createSelector(
  selectInvoiceCheckReportState,
  (InvoiceCheckReportState) => {
    return InvoiceCheckReportState.listLoading;
  },
);

export const selectInvoiceCheckReportActionLoading = createSelector(
  selectInvoiceCheckReportState,
  (InvoiceCheckReportState) => InvoiceCheckReportState.actionsloading,
);

export const selectInvoiceCheckReportPageLastQuery = createSelector(
  selectInvoiceCheckReportState,
  (InvoiceCheckReportState) => InvoiceCheckReportState.lastQuery,
);

export const selectInvoiceCheckReportInStore = createSelector(
  selectInvoiceCheckReportState,
  (InvoiceCheckReportState) => {
    const items: InvoiceCheckReport[] = [];
    each(InvoiceCheckReportState.ids, (id: number) => {
      items.push(InvoiceCheckReportState.entities[id]);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: InvoiceCheckReport[] = httpExtension.sortArray(
      items,
      InvoiceCheckReportState.lastQuery.sortField,
      InvoiceCheckReportState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(
      result,
      InvoiceCheckReportState.totalCount,
      '',
    );
  },
);

export const selectInvoiceCheckReportShowInitWaitingMessage = createSelector(
  selectInvoiceCheckReportState,
  (InvoiceCheckReportState) => InvoiceCheckReportState.showInitWaitingMessage,
);

export const selectHasInvoiceCheckReportInStore = createSelector(
  selectInvoiceCheckReportState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }
    return true;
  },
);
