import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Userfile } from '../_models/userfile.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserfileService {
  constructor(private http: HttpClient) {}

  getAllUserfiles(): Observable<Userfile[]> {
    return this.http.get<Userfile[]>('api/userfiles/');
  }

  findUserfiles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/userfiles/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  deleteUserfile(userfile: Userfile) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/userfiles'}/${userfile.id}/`;
    return this.http.delete(url, { headers: httpHeaders });
  }

  deleteUserfiles(ids: Array<number>): Observable<Array<number>> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<any>(
        'api/userfiles/mass_delete/',
        { ids: ids },
        { headers: httpHeaders },
      )
      .pipe(map((res) => res.ids));
  }

  createUserfile(fd: FormData): Observable<any> {
    return this.http.post<any>('api/userfiles/', fd, {
      reportProgress: true,
      observe: 'events',
    });
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
