// RxJS
import { of } from 'rxjs';
import {
  catchError,
  finalize,
  tap,
  debounceTime,
  delay,
  distinctUntilChanged,
} from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../../core/reducers';
import {
  selectInvoiceCheckReportInStore,
  selectInvoiceCheckReportPageLoading,
  selectInvoiceCheckReportShowInitWaitingMessage,
} from '../_selectors/invoice_check_report.selectors';

export class InvoiceCheckReportDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(
      select(selectInvoiceCheckReportPageLoading),
    );

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectInvoiceCheckReportShowInitWaitingMessage),
    );

    this.store
      .pipe(select(selectInvoiceCheckReportInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
