<!--begin::Form-->
<form [formGroup]="addressForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Oh snap! Change a few things up and try submitting again.
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group row">
      <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Διεύθυνση" formControlName="addressLine" />
          <mat-error
            >Η διεύθυνση είναι
            <strong>απαραίτητ</strong>
          </mat-error>
          <mat-hint align="start"
            >Εισάγετε
            <strong>οδό και αριθμό</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Πόλη ή Τόπος κατοικίας" formControlName="city" />
          <mat-error
            >Η πόλη είναι
            <strong>απαραίτητη</strong>
          </mat-error>
          <mat-hint align="start"
            >Εισάγετε την <strong>Πόλη ή τον Τόπο</strong> κατοικίας σας
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group kt-form__group row">
      <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Νομός" formControlName="state" />
          <mat-error
            >Ο νομός είναι
            <strong>απαραίτητος</strong>
          </mat-error>
          <mat-hint align="start">Εισάγετε τον <strong>Νομό</strong> που ανήκετε </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Ταχυδρομικός Κώδικας" formControlName="postCode" />
          <mat-error
            >Ο Τ.Κ. είναι
            <strong>απαραίτητος</strong>
          </mat-error>
          <mat-hint align="start"
            >Εισάγετε τον <strong>Ταχυδρομικό Κώδικα</strong> της περιοχής σας
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<!--end::Form-->
