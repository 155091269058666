// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { GConfigState } from '../_reducers/gconfig.reducers';
import { each } from 'lodash';
import { GConfig } from '../_models/gconfig.model';

export const selectGConfigState =
  createFeatureSelector<GConfigState>('gconfig');

export const selectGConfigById = (ident: string) =>
  createSelector(
    selectGConfigState,
    (gconfigState) => gconfigState.entities[ident],
  );
