<div style="text-align: center">
  <apx-chart
    *ngIf="(data | keyvalue)?.length"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [plotOptions]="chartOptions.plotOptions"
    [colors]="chartOptions.colors"
    [dataLabels]="chartOptions.dataLabels"
  ></apx-chart>
  <span *ngIf="!(data | keyvalue)?.length">Δεν βρέθηκαν {{ _type }}</span>
</div>
