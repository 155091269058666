// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { CSCharge } from '../_models/cscharge.model';
// Models
import { HttpErrorResponse } from '@angular/common/http';

export enum CSChargeActionTypes {
  LoadAllCSCharges = '[CSCharges API] Load All CSCharges',
  RefreshAllCSCharges = '[CSCharges API] Refresh All CSCharges',
  AllCSChargesLoaded = '[CSCharges] All CSCharges Loaded',
  AllCSChargesLoadFailed = '[CSCharges API] All CSCharges Failed to Load',
  CSCActionSuccess = '[CSCharges] Last action success',
  CSCActionFail = '[CSCharges] Last action failed',
  CSChargesUpsert = '[CSCharges] Upsert CSCharges',
  CSChargesAdd = '[CSCharges API] Add CSCharges',
}

export class CSCActionSuccess implements Action {
  readonly type = CSChargeActionTypes.CSCActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class CSCActionFail implements Action {
  readonly type = CSChargeActionTypes.CSCActionFail;
  constructor(public payload: { result: any }) {}
}

export class CSChargesAdd implements Action {
  readonly type = CSChargeActionTypes.CSChargesAdd;
  constructor(public payload: { cscharges: Array<CSCharge> }) {}
}

export class CSChargesUpsert implements Action {
  readonly type = CSChargeActionTypes.CSChargesUpsert;
  constructor(public payload: { cscharges: Array<CSCharge> }) {}
}

export class LoadAllCSCharges implements Action {
  readonly type = CSChargeActionTypes.LoadAllCSCharges;
}

export class RefreshAllCSCharges implements Action {
  readonly type = CSChargeActionTypes.LoadAllCSCharges;
}

export class AllCSChargesLoaded implements Action {
  readonly type = CSChargeActionTypes.AllCSChargesLoaded;
  constructor(public payload: { cscharges: Array<CSCharge> }) {}
}

export class AllCSChargesLoadFailed implements Action {
  readonly type = CSChargeActionTypes.AllCSChargesLoadFailed;
  constructor(public payload: { result: any }) {}
}

export type CSChargeActions =
  | LoadAllCSCharges
  | RefreshAllCSCharges
  | AllCSChargesLoaded
  | AllCSChargesLoadFailed
  | CSCActionSuccess
  | CSCActionFail
  | CSChargesUpsert
  | CSChargesAdd;
