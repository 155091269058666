export class AreaGroupAction {
  id: number;
  action_type: string;
  message: string;
  enforce_courier: string;
  weight_start: number;
  static_charge: number;
  per_kg_charge: number;
  charge_reason: string;
  enforce_zone: boolean;
  zone_start: number;
  zone_end: number;
}
