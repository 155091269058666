import { BaseModel } from '../../_base/crud';

export class CSCharge extends BaseModel {
  id: number;
  date_created: Date;
  weight_start: number;
  weight_end: number;
  static_charge: number;
  per_kg_charge: number;
  cod_charge: number;
  user: number;
  courier: string;

  clear(): void {
    this.id = undefined;
    this.date_created = undefined;
    this.weight_start = undefined;
    this.weight_end = undefined;
    this.static_charge = undefined;
    this.per_kg_charge = undefined;
    this.cod_charge = undefined;
    this.user = undefined;
    this.courier = undefined;
  }
}
