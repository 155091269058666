<form [formGroup]="speedexForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
  </kt-alert>
  <kt-alert
    *ngIf="serverError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group">
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Username" formControlName="spdx_username" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Username</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Password" formControlName="spdx_password" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Password</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Customer ID" formControlName="spdx_customer_id" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Customer ID σας.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Agreement ID" formControlName="spdx_agreement_id" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Agreement ID σας.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Agreement Description"
              formControlName="spdx_agreement_desc"
            />
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε τo
              <strong>Agreement Description σας.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="API Endpoint" formControlName="spdx_api_endpoint" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>API Endpoint.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
