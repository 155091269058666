<form [formGroup]="eltaForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
  </kt-alert>
  <kt-alert
    *ngIf="serverError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group row">
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Κωδικός Χρήστη " formControlName="user_code" />
          <mat-error
            >Το πεδίο αυτό είναι
            <strong>απαραίτητο</strong>
          </mat-error>
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε τον
            <strong>Κωδικό Χρήστη</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Κωδικός Ασφαλείας" formControlName="user_pass" />
          <mat-error
            >Το πεδίο αυτό είναι
            <strong>απαραίτητο</strong>
          </mat-error>
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε τον
            <strong>Κωδικό Ασφαλείας</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Κεφαλικός Κωδικός Αποστολέα" formControlName="apost_code" />
          <mat-error
            >Το πεδίο αυτό είναι
            <strong>απαραίτητο</strong>
          </mat-error>
          <mat-hint align="start"
            >Παρακαλώ ειστάγετε τον
            <strong>Κεφαλικό Κωδικό Αποστολέα</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
