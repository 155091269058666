// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// LODASH
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';

// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';
// Models
import {
  User,
  UserDeleted,
  UsersDataSource,
  UsersPageRequested,
} from '../../../../../core/auth';
import { SubheaderService } from '../../../../../core/_base/layout';

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4

@Component({
  selector: 'kt-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, OnDestroy {
  dataSource: UsersDataSource;
  displayedColumns = [
    'select',
    'id',
    'email',
    'firstname',
    'lastname',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<User>(true, []);
  usersResult: User[] = [];

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   * @param cdr
   */

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // load roles list
    //const rolesSubscription = this.store.pipe(select(selectAllRoles)).subscribe(res => this.allRoles = res);
    //this.subscriptions.push(rolesSubscription);

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadUsersList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup',
    )
      .pipe(
        // eslint-disable-next-line max-len
        debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUsersList();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Διαχείριση Χρηστών');

    // Init DataSource
    this.dataSource = new UsersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.usersResult = res;
        //console.log(this.usersResult);
      });
    this.subscriptions.push(entitiesSubscription);
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 50;

    this.loadUsersList();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  loadUsersList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );
    this.store.dispatch(new UsersPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    filter.lastName = searchText;

    filter.username = searchText;
    filter.email = searchText;
    filter.fillname = searchText;
    filter.qc = searchText;
    return filter;
  }

  /** ACTIONS */
  /**
   * Delete user
   *
   * @param _item: User
   */
  deleteUser(_item: User) {
    const _title = 'Διαγραφή Χρήστη';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε τον χρήστη';
    const _waitDesciption = 'Ο χρήστης διαγράφεται';
    const _deleteMessage = `Ο χρήστης έχει διαγραφεί`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(new UserDeleted({ id: _item.id }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete,
      );
    });
  }

  /**
   * Fetch selected rows
   */
  fetchUsers() {
    /*	const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.email} , ${elem.firstname} ${elem.lastname}`,
				id: elem.id.toString(),
				status: elem.email
			});
		});
		this.layoutUtilsService.fetchElements(messages);*/
  }

  deleteUsers() {
    const _title = 'Διαγραφή Χρηστών';
    const _description =
      'Είστε σίγουρος πως θέλετε να διαγράψετε τους χρήστες;';
    const _waitDesciption = 'Οι χρήστες διαγράφονται';
    const _deleteMessage = `Οι χρήστες διεγράφησαν`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.selection.selected.forEach((elem) => {
        this.store.dispatch(new UserDeleted({ id: elem.id }));
      });
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete,
      );
    });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.usersResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.usersResult.length) {
      this.selection.clear();
    } else {
      this.usersResult.forEach((row) => this.selection.select(row));
    }
  }

  /**
   * Redirect to edit page
   *
   * @param id
   */
  editUser(id) {
    this.router.navigate(['../users/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }
}
