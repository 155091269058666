import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApikeysService {
  constructor(private http: HttpClient) {}

  getApiKeys(): Observable<Array<any>> {
    return this.http.get<Array<any>>('api/auth/api_keys/');
  }

  removeKey(key: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/auth/api_keys'}/${key.id}/`;
    return this.http.delete(url, { headers: httpHeaders });
  }

  addKey(key: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>('api/auth/api_keys/', key, {
      headers: httpHeaders,
    });
  }
}
