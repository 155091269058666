import { BaseModel } from '../../_base/crud';

export class Userwfd extends BaseModel {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  companyName: string;
  is_company: boolean;
  financial_data: any;
}
