// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
// Layout
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

// Services and Models
import {
  Address,
  AddUserError,
  currentUser,
  FetchUser,
  SaveSuccess,
  selectLastCreatedUserId,
  selectUserById,
  selectUsersActionLoading,
  User,
  UserActionTypes,
  UserOnServerCreated,
  UserRefreshed,
  UserUpdated,
} from '../../../../../core/auth';

@Component({
  selector: 'kt-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
  // Public properties
  user: User;
  userId$: Observable<number>;
  oldUser: User;
  selectedTab = 0;
  loading$: Observable<boolean>;
  user_error$: Observable<any>;
  rolesSubject = new BehaviorSubject<number[]>([]);
  addressSubject = new BehaviorSubject<Address>(new Address());
  companySubject = new BehaviorSubject<any>(null);

  chargeSettingsSubject = new BehaviorSubject<any>(null);
  cschargeSettingsSubject = new BehaviorSubject<any>(null);
  userForm: UntypedFormGroup;
  hasFormErrors = false;
  dupEmail: Subject<any>;
  save_success$: Observable<any>;
  profile: boolean;

  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  per_mask = createNumberMask({
    suffix: ' %',
    prefix: '',
    allowDecimal: false,
  });

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param userFB: FormBuilder
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store<AppState>
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userFB: UntypedFormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService,
    private actions$: Actions,
  ) {}

  ngOnInit() {
    this.profile = false;
    this.dupEmail = new Subject();
    this.loading$ = this.store.pipe(select(selectUsersActionLoading));

    if (this.router.url.startsWith('/user-management/profile')) {
      const guser = this.store.pipe(select(currentUser)).subscribe((res) => {
        //console.log(res);
        this.user = res;

        const newAddress = new Address();
        newAddress.clear();
        newAddress.addressLine = this.user.user_address;
        newAddress.city = this.user.city;
        newAddress.postCode = this.user.post_code;
        newAddress.state = this.user.state;
        this.addressSubject.next(newAddress);
        this.companySubject.next({
          companyName: this.user.companyName,
          companyVat: this.user.companyVat,
          companyFiscalService: this.user.companyFiscalService,
          companyAddress: this.user.companyAddress,
          companyCity: this.user.companyCity,
          companyPostCode: this.user.companyPostCode,
        });
        this.oldUser = Object.assign({}, this.user);
        this.profile = true;
        this.chargeSettingsSubject.next({
          return_ammount: this.user.return_ammount,
          allow_prereturns: this.user.allow_prereturns,
          prereturn_charge: this.user.prereturn_charge,
          prereturn_max: this.user.prereturn_max,
          prereturn_perc: this.user.prereturn_perc,
        });
        this.initUser();
      });
      this.subscriptions.push(guser);
    } else {
      const routeSubscription = this.activatedRoute.params.subscribe(
        (params) => {
          const id = params.id;
          if (id && id > 0) {
            this.store.dispatch(new FetchUser({ id: id }));
            const gus = this.store
              .pipe(select(selectUserById(id)))
              .subscribe((res) => {
                if (res) {
                  this.user = res;
                  const newAddress = new Address();
                  newAddress.clear();
                  newAddress.addressLine = this.user.user_address;
                  newAddress.city = this.user.city;
                  newAddress.postCode = this.user.post_code;
                  newAddress.state = this.user.state;
                  this.addressSubject.next(newAddress);
                  this.companySubject.next({
                    companyName: this.user.companyName,
                    companyVat: this.user.companyVat,
                    companyFiscalService: this.user.companyFiscalService,
                    companyAddress: this.user.companyAddress,
                    companyCity: this.user.companyCity,
                    companyPostCode: this.user.companyPostCode,
                  });
                  this.oldUser = Object.assign({}, this.user);
                  this.chargeSettingsSubject.next({
                    return_ammount: this.user.return_ammount,
                    allow_prereturns: this.user.allow_prereturns,
                    prereturn_charge: this.user.prereturn_charge,
                    prereturn_max: this.user.prereturn_max,
                    prereturn_perc: this.user.prereturn_perc,
                  });
                  this.initUser();
                }
              });
            this.subscriptions.push(gus);
          } else {
            this.user = new User();
            this.user.clear();
            this.addressSubject.next(this.user.address);
            this.oldUser = Object.assign({}, this.user);
            this.chargeSettingsSubject.next({
              return_ammount: this.user.return_ammount,
              allow_prereturns: this.user.allow_prereturns,
              prereturn_charge: this.user.prereturn_charge,
              prereturn_max: this.user.prereturn_max,
              prereturn_perc: this.user.prereturn_perc,
            });
            this.initUser();
          }
        },
      );
      this.subscriptions.push(routeSubscription);
    }
    const sf = this.actions$
      .pipe(
        ofType<AddUserError>(UserActionTypes.UserErrored),
        tap(({ payload }) => {
          // eslint-disable-next-line no-prototype-builtins
          if (payload.hasOwnProperty('error')) {
            // eslint-disable-next-line no-prototype-builtins
            if (payload.error.hasOwnProperty('email')) {
              this.dupEmail.next(
                'Υπάρχει ήδη ένας χρήστης με αυτήν την διεύθυνση email',
              );
              // eslint-disable-next-line no-prototype-builtins
            } else if (payload.error.hasOwnProperty('detail')) {
              this.dupEmail.next(payload.error.detail);
            } else {
              this.dupEmail.next(
                'Προέκυψε ένα σφάλμα κατα την αποθήκευση των δεδομένων',
              );
            }
          } else {
            this.dupEmail.next(
              'Προέκυψε ένα σφάλμα κατα την αποθήκευση των δεδομένων',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    const ss = this.actions$
      .pipe(
        ofType<SaveSuccess>(UserActionTypes.SaveSuccess),
        tap(({ payload }) => {
          if (this.profile) {
            this.layoutUtilsService.showActionNotification(
              'Το προφίλ σας ενημερώθηκε',
              MessageType.Update,
              5000,
              true,
              true,
            );
            this.store.dispatch(new UserRefreshed());
          } else {
            this.layoutUtilsService.showActionNotification(
              'Ο χρήστης ενημερώθηκε επιτυχώς',
              MessageType.Update,
              5000,
              true,
              true,
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  /**
   * Init user
   */
  initUser() {
    this.createForm();
    if (this.profile) {
      this.subheaderService.setTitle('Προφίλ');
      this.subheaderService.setBreadcrumbs([
        { title: 'Διαχείριση Χρηστών', page: `user-management` },
        { title: 'Χρήστες', page: `user-management/users` },
        {
          title: 'Προφίλ',
          page: `user-management/users/edit`,
          queryParams: { id: this.user.id },
        },
      ]);
    } else {
      if (!this.user.id) {
        this.subheaderService.setTitle('Create user');
        this.subheaderService.setBreadcrumbs([
          { title: 'Διαχείριση Χρηστών', page: `user-management` },
          { title: 'Χρήστες', page: `user-management/users` },
          { title: 'Δημιουργία Χρήστη', page: `user-management/users/add` },
        ]);
        return;
      }
      this.subheaderService.setTitle('Επεξεργασία Χρήστη');
      this.subheaderService.setBreadcrumbs([
        { title: 'Διαχείριση Χρηστών', page: `user-management` },
        { title: 'Χρήστες', page: `user-management/users` },
        {
          title: 'Επεξεργασία Χρήστη',
          page: `user-management/users/edit`,
          queryParams: { id: this.user.id },
        },
      ]);
    }
  }

  /**
   * Create form
   */
  createForm() {
    this.userForm = this.userFB.group({
      firstname: [this.user.firstname, Validators.required],
      lastname: [this.user.lastname, Validators.required],
      email: [this.user.email, Validators.email],
      phone: [this.user.phone],
      mobile: [this.user.mobile],
      vat: [this.user.vat],
      adt: [this.user.adt],
      is_active: [this.user.is_active],
      is_company: [this.user.is_company],
      bank_payments: [this.user.bank_payments],
      iban: [this.user.iban],
      bank: [this.user.bank],
      can_edit_profile: [this.user.can_edit_profile],
      vat_val: [this.user.vat_val, [Validators.min(0), Validators.max(100)]],
      kap: [this.user.kap],
      has_es: [this.user.has_es],
      print_comments: [this.user.print_comments],
      courier: [this.user.courier],
      pickup_rights: [this.user.pickup_rights],
      ignore_area_groups: [this.user.ignore_area_groups],
      allow_add_by_xls: [this.user.allow_add_by_xls],
      return_cod_charge: [this.user.return_cod_charge],
      modify_voucher: [this.user.modify_voucher],
    });

    if (this.user.bank_payments) {
      this.userForm.get('iban').setValidators(null);
      this.userForm.get('iban').setValidators([Validators.required]);
      this.userForm.get('bank').setValidators(null);
      this.userForm.get('bank').setValidators([Validators.required]);
    }

    const vsub = this.userForm
      .get('bank_payments')
      .valueChanges.subscribe((val) => {
        if (val) {
          this.userForm.get('iban').setValidators(null);
          this.userForm.get('bank').setValidators(null);
          this.userForm.get('iban').setValidators([Validators.required]);
          this.userForm.get('bank').setValidators([Validators.required]);
        } else {
          this.userForm.get('iban').setValidators(null);
          this.userForm.get('bank').setValidators(null);
        }
        this.userForm.get('bank').updateValueAndValidity();
        this.userForm.get('iban').updateValueAndValidity();
      });
    this.subscriptions.push(vsub);
  }

  /**
   * Redirect to list
   *
   */
  goBackWithId() {
    const url = `/user-management/users`;
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  /**
   * Refresh user
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshUser(isNew = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }
    if (this.profile) {
      url = `/user-management/profile`;
      this.router.navigate([url], { relativeTo: this.activatedRoute });
    }
    url = `/user-management/users/edit/${id}`;
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  /**
   * Reset
   */
  reset() {
    this.user = Object.assign({}, this.oldUser);
    this.createForm();
    this.hasFormErrors = false;
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.userForm.updateValueAndValidity();
    this.dupEmail.next(false);
  }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack = false) {
    this.hasFormErrors = false;
    this.dupEmail.next(false);
    const controls = this.userForm.controls;
    /** check form */
    if (this.userForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    const editedUser = this.prepareUser();
    //console.log(editedUser);
    if (editedUser.id > 0) {
      this.updateUser(editedUser, withBack);
      return;
    }

    this.addUser(editedUser, withBack);
  }

  setCourier(courier: string) {
    this.userForm.get('courier').setValue(courier);
  }

  /**
   * Returns prepared data for save
   */
  prepareUser(): User {
    const controls = this.userForm.controls;
    const _user = new User();
    //_user.clear();
    _user.user_address = this.addressSubject.value.addressLine;
    _user.post_code = this.addressSubject.value.postCode;
    _user.city = this.addressSubject.value.city;
    _user.state = this.addressSubject.value.state;
    _user.accessToken = this.user.accessToken;
    _user.refreshToken = this.user.refreshToken;
    _user.id = this.user.id;
    _user.email = controls.email.value;
    _user.mobile = controls.mobile.value;
    _user.phone = controls.phone.value;
    _user.adt = controls.adt.value;
    _user.vat = controls.vat.value;
    _user.bank_payments = controls.bank_payments.value;
    _user.iban = controls.iban.value;
    _user.bank = controls.bank.value;
    _user.can_edit_profile = controls.can_edit_profile.value;
    _user.has_es = controls.has_es.value;
    _user.print_comments = controls.print_comments.value;

    if (!this.profile) {
      _user.pickup_rights = controls.pickup_rights.value;
      _user.ignore_area_groups = controls.ignore_area_groups.value;
      _user.allow_add_by_xls = controls.allow_add_by_xls.value;
      _user.return_cod_charge = controls.return_cod_charge.value;
      _user.modify_voucher = controls.modify_voucher.value;
      _user.courier = controls.courier.value;
      _user.kap = controls.kap.value;

      if (controls.vat_val.value) {
        _user.vat_val = parseInt(
          ('' + controls.vat_val.value).replace(' %', '').replace(',', ''),
        );
      }

      if (this.chargeSettingsSubject.value.return_ammount) {
        _user.return_ammount = parseFloat(
          this.chargeSettingsSubject.value.return_ammount
            .replace('€ ', '')
            .replace(',', ''),
        );
        //console.log(_user.return_ammount);
      } else {
        _user.return_ammount = null;
      }

      _user.allow_prereturns =
        this.chargeSettingsSubject.value.allow_prereturns;

      if (this.chargeSettingsSubject.value.prereturn_charge) {
        _user.prereturn_charge = parseFloat(
          this.chargeSettingsSubject.value.prereturn_charge
            .replace('€ ', '')
            .replace(',', ''),
        );
      } else {
        _user.prereturn_charge = null;
      }
      if (this.chargeSettingsSubject.value.prereturn_max) {
        _user.prereturn_max = parseFloat(
          this.chargeSettingsSubject.value.prereturn_max
            .replace('€ ', '')
            .replace(',', ''),
        );
      } else {
        _user.prereturn_max = null;
      }
      if (this.chargeSettingsSubject.value.prereturn_perc) {
        _user.prereturn_perc = parseFloat(
          this.chargeSettingsSubject.value.prereturn_perc
            .replace(' %', '')
            .replace(',', ''),
        );
      } else {
        _user.prereturn_perc = null;
      }
    }

    if (this.companySubject.value) {
      _user.companyName = this.companySubject.value.companyName;
      _user.companyVat = this.companySubject.value.companyVat;
      _user.companyFiscalService =
        this.companySubject.value.companyFiscalService;
      _user.companyAddress = this.companySubject.value.companyAddress;
      _user.companyPostCode = this.companySubject.value.companyPostCode;
      _user.companyCity = this.companySubject.value.companyCity;
    }
    if (!this.profile) {
      _user.is_active = controls.is_active.value;
      _user.is_company = controls.is_company.value;
    } else {
      delete _user.is_active;
      delete _user.is_company;
    }
    _user.firstname = controls.firstname.value;
    _user.lastname = controls.lastname.value;
    return _user;
  }

  /**
   * Add User
   *
   * @param _user: User
   * @param withBack: boolean
   */
  addUser(_user: User, withBack = false) {
    this.store.dispatch(new UserOnServerCreated({ user: _user }));
    const addSubscription = this.store
      .pipe(select(selectLastCreatedUserId))
      .subscribe((newId) => {
        if (newId) {
          if (withBack) {
            this.goBackWithId();
          } else {
            this.refreshUser(true, newId);
          }
        }
      });
    this.subscriptions.push(addSubscription);
  }

  /**
   * Update user
   *
   * @param _user: User
   * @param withBack: boolean
   */
  updateUser(_user: User, withBack = false) {
    // Update User
    // eslint-disable-next-line prefer-const

    const updatedUser: Update<User> = {
      id: _user.id,
      changes: _user,
    };

    this.store.dispatch(
      new UserUpdated({ partialUser: updatedUser, user: _user }),
    );

    //const message = `User successfully has been saved.`;
    //this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
    if (withBack) {
      this.goBackWithId();
    } else {
      this.refreshUser(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Δημιουργία Χρήστη';
    if (!this.user || !this.user.id) {
      return result;
    }

    result = `${this.user.firstname} ${this.user.lastname}`;
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
    this.dupEmail.next(false);
  }
}
