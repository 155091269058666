import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mergeMap, takeLast, last, map, tap, delay } from 'rxjs/operators';
import { mapValues, groupBy, omit, sortBy } from 'lodash';

@Component({
  selector: 'kt-charge-analysis',
  templateUrl: './charge-analysis.component.html',
  styleUrls: ['./charge-analysis.component.scss'],
})
export class ChargeAnalysisComponent implements OnInit {
  @Input() data: any;

  constructor() {}

  ngOnInit() {
    // console.log(this.data);
  }
}
