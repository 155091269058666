// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Area } from '../_models/area.model';
// Models
import { QueryParamsModel } from '../../_base/crud';
import { HttpErrorResponse } from '@angular/common/http';

export enum AreaActionTypes {
  AreaPageRequested = '[Area List Page] Area Page Requested',
  AreaPageLoaded = '[Area API] Area Page Loaded',
  AreaPageCancelled = '[Area API] Area Page Cancelled',
  AreaPageToggleLoading = '[Area] Area Page Toggle Loading',
  AreaActionToggleLoading = '[Area] Area Action Toggle Loading',
  AreaActionSuccess = '[Area API] Last action succeded',
  AreaActionFail = '[Area API] Last action failed',
  AreaActionRefresh = '[Area API] Emit to all interested parties to refresh all areas',
  AreaDelete = '[Areas API] Area Delete',
  AreaUpsert = '[Areas] Update or Insert',
  AreaCreate = '[Areas] Create',
  PopulateForCourier = '[Areas API] Repopulate areas for courier',
}

export class PopulateForCourier implements Action {
  readonly type = AreaActionTypes.PopulateForCourier;
  constructor(public payload: { items: Array<any>; courier: string }) {}
}

export class AreaCreate implements Action {
  readonly type = AreaActionTypes.AreaCreate;
  constructor(public payload: { area: Area }) {}
}

export class AreaDelete implements Action {
  readonly type = AreaActionTypes.AreaDelete;
  constructor(public payload: { area: Area }) {}
}

export class AreaUpsert implements Action {
  readonly type = AreaActionTypes.AreaUpsert;
  constructor(public payload: { area: Area }) {}
}

export class AreaPageRequested implements Action {
  readonly type = AreaActionTypes.AreaPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class AreaPageLoaded implements Action {
  readonly type = AreaActionTypes.AreaPageLoaded;
  constructor(
    public payload: {
      areas: Area[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class AreaPageCancelled implements Action {
  readonly type = AreaActionTypes.AreaPageCancelled;
}

export class AreaPageToggleLoading implements Action {
  readonly type = AreaActionTypes.AreaPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class AreaActionToggleLoading implements Action {
  readonly type = AreaActionTypes.AreaActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class AreaActionRefresh implements Action {
  readonly type = AreaActionTypes.AreaActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class AreaActionSuccess implements Action {
  readonly type = AreaActionTypes.AreaActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class AreaActionFail implements Action {
  readonly type = AreaActionTypes.AreaActionFail;
  constructor(public payload: { result: any }) {}
}

export type AreaActions =
  | AreaPageRequested
  | AreaPageLoaded
  | AreaPageCancelled
  | AreaPageToggleLoading
  | AreaActionToggleLoading
  | AreaActionRefresh
  | AreaActionSuccess
  | AreaActionFail
  | AreaDelete
  | AreaUpsert
  | AreaCreate
  | PopulateForCourier;
