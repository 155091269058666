import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-user-interface',
  templateUrl: './user-interface.component.html',
  styleUrls: ['./user-interface.component.scss'],
})
export class UserInterfaceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
