// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { AreaActions, AreaActionTypes } from '../_actions/area.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Area } from '../_models/area.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AreaState extends EntityState<Area> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Area> = createEntityAdapter<Area>();

export const initialAreaState: AreaState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
});

export function areaReducer(
  state = initialAreaState,
  action: AreaActions,
): AreaState {
  switch (action.type) {
    case AreaActionTypes.AreaPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
      };

    case AreaActionTypes.AreaActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };

    case AreaActionTypes.AreaPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };

    case AreaActionTypes.AreaUpsert:
      return adapter.upsertOne(action.payload.area, {
        ...state,
      });

    case AreaActionTypes.AreaPageLoaded: {
      return adapter.addMany(action.payload.areas, {
        ...initialAreaState,
        totalCount: action.payload.totalCount,
        lastQuery: action.payload.page,
        listLoading: false,
        showInitWaitingMessage: false,
      });
    }

    default:
      return state;
  }
}

export const getAreaState = createFeatureSelector<AreaState>('areas');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
