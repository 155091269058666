import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Notification } from '../_models/notification.model';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotifications(): Observable<Notification[]> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('no_token_refresh', 'true');
    return this.http.get<Notification[]>('api/notifications/', {
      headers: httpHeaders,
    });
  }

  clearNotifications() {
    return this.http.get<any>('api/notifications/clear/');
  }

  deleteNotification(notId: number) {
    const url = `${'api/notifications'}/${notId}`;
    return this.http.delete(url);
  }

  addNotification(an: Notification): Observable<Notification> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Notification>('api/notifications/', an, {
      headers: httpHeaders,
    });
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
