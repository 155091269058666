<!--begin::Form-->
<form [formGroup]="companyForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Oh snap! Change a few things up and try submitting again.
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group row">
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Όνομα εταιρίας" formControlName="companyName" />
          <mat-hint align="start"
            >Εισάγετε το όνομα της
            <strong>Εταιρίας</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="ΑΦΜ" formControlName="companyVat" />
          <mat-hint align="start"
            >Εισάγετε το εταιρικό
            <strong>ΑΦΜ</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="ΔΟΥ" formControlName="companyFiscalService" />
          <mat-hint align="start">Εισάγετε την <strong>ΔΟΥ</strong> που ανήκει η εταιρία </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group kt-form__group row">
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Διεύθυνση" formControlName="companyAddress" />
          <mat-hint align="start"
            >Εισάγετε την εταιρική
            <strong>Διεύθυνση</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Πόλη" formControlName="companyCity" />
          <mat-hint align="start"
            >Εισάγετε την <strong>Πόλη</strong> που έχει έδρα η εταιρία
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Ταχυδρομικό Κώδικα" formControlName="companyPostCode" />
          <mat-hint align="start">Εισάγετε τον <strong>ΤΚ</strong> της εταιρίας. </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<!--end::Form-->
