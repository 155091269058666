// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { ExternalShipmentService } from '../_services/external_shipment.service';
// State
import { AppState } from '../../reducers';
import {
  ESActionFail,
  ESActionRefresh,
  ESActionSuccess,
  ExternalShipmentActionToggleLoading,
  ExternalShipmentActionTypes,
  ExternalShipmentCheck,
  ExternalShipmentCreate,
  ExternalShipmentDelete,
  ExternalShipmentMassDelete,
  ExternalShipmentMassRemove,
  ExternalShipmentPageLoaded,
  ExternalShipmentPageRequested,
  ExternalShipmentPageToggleLoading,
  ExternalShipmentRemove,
  ExternalShipmentUpsert,
} from '../_actions/external_shipment.actions';

@Injectable()
export class ExternalShipmentEffects {
  showPageLoadingDistpatcher = new ExternalShipmentPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new ExternalShipmentPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new ExternalShipmentActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new ExternalShipmentActionToggleLoading({
    isLoading: false,
  });

  loadExternalShipmentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExternalShipmentPageRequested>(
        ExternalShipmentActionTypes.ExternalShipmentPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ess.findExternalShipments(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new ExternalShipmentPageLoaded({
          external_shipments: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  deleteShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExternalShipmentDelete>(
        ExternalShipmentActionTypes.ExternalShipmentDelete,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.deleteShipment(payload.shipment).pipe(
          switchMap(() => [
            new ExternalShipmentRemove({ shipment: payload.shipment }),
            new ESActionSuccess({
              result: `Η αποστολή προς διαγράφηκε επιτυχώς`,
            }),
            new ESActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ESActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkShipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExternalShipmentCheck>(
        ExternalShipmentActionTypes.ExternalShipmentCheck,
      ),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap(() =>
        this.ess.checkExternalShipments().pipe(
          switchMap((res) => [
            new ESActionSuccess({ result: `Ο έλεγχος ολοκληρώθηκε επιτυχώς` }),
            new ESActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ESActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  deleteShipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExternalShipmentMassDelete>(
        ExternalShipmentActionTypes.ExternalShipmentMassDelete,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.deleteShipments(payload.ids).pipe(
          switchMap((res) => [
            new ExternalShipmentMassRemove({ ids: res }),
            new ESActionSuccess({
              result: `Οι αποστολές διαγράφηκαν επιτυχώς`,
            }),
            new ESActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ESActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  createShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ExternalShipmentCreate>(
        ExternalShipmentActionTypes.ExternalShipmentCreate,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ess.createShipment(payload.shipment).pipe(
          switchMap((res) => [
            new ExternalShipmentUpsert({ shipment: res }),
            new ESActionSuccess({ result: `Η αποστολή προστέθηκε επιτυχώς` }),
          ]),
          catchError((err) => [new ESActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  constructor(
    private actions$: Actions,
    private ess: ExternalShipmentService,
    private store: Store<AppState>,
  ) {}
}
