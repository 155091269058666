<form [formGroup]="csForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
  </kt-alert>
  <kt-alert
    *ngIf="serverError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group">
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="API Endpoint" formControlName="acs1_api_endpoint" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>API Endpoint</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="API Key" formControlName="acs1_api_key" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>API Key</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Company ID" formControlName="acs1_company_id" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>Company ID</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Company Password"
              formControlName="acs1_company_password"
            />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>Company Password.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="User ID" formControlName="acs1_user_id" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>User ID.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="User Password" formControlName="acs1_user_password" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>User Password.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Billing Code" formControlName="acs1_billing_code" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ εισάγετε το
              <strong>Billing Code</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
