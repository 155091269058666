<!--begin::Form-->
<form *ngIf="user" [formGroup]="changePasswordForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Οι κωδικοί που εισάγατε δεν ταιριάζουν. Παρακαλώ ξαναπροσπαθήστε
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group row mb-0">
      <div class="col-lg-8 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            type="password"
            id="password"
            autocomplete="off"
            placeholder="Κωδικός"
            formControlName="password"
          />
          <mat-error
            >Ο κωδικός είναι
            <strong>απαραίτητος</strong>
          </mat-error>
          <mat-hint align="start"
            >Εισάγετε τον νέο
            <strong>Κωδικό</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group kt-form__group row">
      <div class="col-lg-8 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            type="password"
            id="confirmPassword"
            autocomplete="off"
            placeholder="Επαλήθευση Κωδικού"
            formControlName="confirmPassword"
          />
          <mat-error
            >Η επαλήθευση είναι
            <strong>απαραίτητη</strong>
          </mat-error>
          <mat-hint align="start"
            >Ξαναεισάγετε τον νέο
            <strong>Κωδικό</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="kt-separator kt-separator--dashed"></div>
  <div class="kt-form__actions kt-form__actions--solid">
    <button
      type="button"
      [disabled]="changePasswordForm.invalid"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="viewLoading"
      matTooltip="Save changes"
    >
      Αλλαγή Κωδικού
    </button>
  </div>
</form>
<!--end::Form-->
