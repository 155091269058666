// RxJS
import { of } from 'rxjs';
import {
  catchError,
  finalize,
  tap,
  debounceTime,
  delay,
  distinctUntilChanged,
} from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../../core/reducers';
import {
  selectAreasInStore,
  selectAreaPageLoading,
  selectAreaShowInitWaitingMessage,
} from '../_selectors/area.selectors';

export class AreaDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectAreaPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectAreaShowInitWaitingMessage),
    );

    this.store
      .pipe(select(selectAreasInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
