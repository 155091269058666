// Angular
import { Injectable } from '@angular/core';
// RxJS
import { catchError, map, switchMap } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { CSChargeService } from '../_services/cscharge.service';
// State
import { AppState } from '../../reducers';
import {
  AllCSChargesLoaded,
  AllCSChargesLoadFailed,
  CSCActionFail,
  CSCActionSuccess,
  CSChargeActionTypes,
  CSChargesAdd,
  CSChargesUpsert,
  LoadAllCSCharges,
} from '../_actions/cscharge.actions';

@Injectable()
export class CSChargeEffects {
  loadAllCSCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAllCSCharges>(CSChargeActionTypes.LoadAllCSCharges),
      switchMap(() =>
        this.cs.getAllCSCharges().pipe(
          switchMap((res) => [new AllCSChargesLoaded({ cscharges: res })]),
          catchError((err) => [new AllCSChargesLoadFailed({ result: err })]),
        ),
      ),
    ),
  );

  createCSCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CSChargesAdd>(CSChargeActionTypes.CSChargesAdd),
      map((action) => action.payload),
      switchMap((payload) =>
        this.cs.createCSCharges(payload.cscharges).pipe(
          switchMap((res) => [
            new CSChargesUpsert({ cscharges: res }),
            new CSCActionSuccess({
              result: `Οι χρεώσεις προστέθηκαν επιτυχώς`,
            }),
          ]),
          catchError((err) => [new CSCActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  constructor(
    private actions$: Actions,
    private cs: CSChargeService,
    private store: Store<AppState>,
  ) {}
}
