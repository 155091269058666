// Angular
import { Injectable } from '@angular/core';
// RxJS
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { KgCheckReportService } from '../_services/kg_check_report.service';
// State
import { AppState } from '../../reducers';
import {
  KgCheckReportActionToggleLoading,
  KgCheckReportActionTypes,
  KgCheckReportPageLoaded,
  KgCheckReportPageRequested,
  KgCheckReportPageToggleLoading,
} from '../_actions/kg_check_report.actions';

@Injectable()
export class KgCheckReportEffects {
  showPageLoadingDistpatcher = new KgCheckReportPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new KgCheckReportPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new KgCheckReportActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new KgCheckReportActionToggleLoading({
    isLoading: false,
  });

  loadKgCheckReportPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<KgCheckReportPageRequested>(
        KgCheckReportActionTypes.KgCheckReportPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.kcrs.findKgCheckReports(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new KgCheckReportPageLoaded({
          KgCheckReports: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private kcrs: KgCheckReportService,
    private store: Store<AppState>,
  ) {}
}
