export class QueryParamsModel {
  // fields
  filter: any;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;
  for_staff: boolean;

  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder = 'asc',
    _sortField = '',
    _pageNumber = 0,
    _pageSize = 10,
    _for_staff = false,
  ) {
    this.filter = _filter;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
    this.for_staff = _for_staff;
  }
}
