import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GConfig } from '../_models/gconfig.model';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GConfigService {
  constructor(private http: HttpClient) {}

  getGConfig(): Observable<GConfig[]> {
    return this.http.get<GConfig[]>('api/config/global/');
  }

  updateGConfig(gc: GConfig): Observable<GConfig> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `api/config/global/${gc.identifier}/`;
    return this.http.patch<GConfig>(url, gc, { headers: httpHeaders });
  }

  updateBulk(gcb: any): Observable<GConfig[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `api/config/global/bulk/`;
    return this.http.post<GConfig[]>(url, gcb, { headers: httpHeaders });
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
